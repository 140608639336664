import React from 'react'
import {
  AcceptIcon,
  AcceptThinIcon,
  AccountGroupIcon,
  AccountMarkIcon,
  AccountPlusIcon,
  AccountRightArrowIcon,
  ArrowDownBoldIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightBoldIcon,
  ArrowRightIcon,
  BadgeIcon,
  BellIcon,
  BookIcon,
  BurgerIcon,
  CartFilledIcon,
  ChatIcon,
  ClocksIcon,
  CommissionIcon,
  CompanyAccountIcon,
  CopyIcon,
  CrossIcon,
  DisplayGridFilterIcon,
  DisplayHorizontalFilterIcon,
  DisplayVerticalFilterIcon,
  DotsIcon,
  EmailBlackIcon,
  FacebookBlackIcon,
  FacebookIcon,
  GoogleIcon,
  HeartIcon,
  InfoIcon,
  LikeIcon,
  LocationIcon,
  LogoIcon,
  LogoSlicedIcon,
  MoreIcon,
  PercentIcon,
  PhoneBlackIcon,
  PlayIcon,
  PlusIcon,
  PriceTagIcon,
  SearchIcon,
  SearchInputIcon,
  SettingsIcon,
  ShareArrowIcon,
  ShopIcon,
  SortIcon,
  StatsIcon,
  TwitterIcon,
  UploadIcon,
  UserAccountIcon,
  WecobiIcon,
} from '../../assets/icons'
import {
  BabysitterIcon,
  BikeIcon,
  BrushIcon,
  CellphoneIcon,
  EarthIcon,
  HomeIcon,
  PuzzleIcon,
  StarIcon,
  TShirtIcon,
} from '../../assets/icons/categories'
import { CorrectTickIcon } from '../../assets/icons/CorrectTickIcon'
import { ICategoryNames } from '../Categories/CategoryItem/CategoryItem.types'

export type CategoryIconNames =
  | 'babysitter'
  | 'bike'
  | 'brush'
  | 'cellphone'
  | 'earth'
  | 'home'
  | 'puzzle'
  | 'star'
  | 't-shirt'

export const CategoryIcons: {
  [key in ICategoryNames]: IconNames
} = {
  baby: 'babysitter',
  hobbies: 'bike',
  beauty: 'brush',
  electronics: 'cellphone',
  travel: 'earth',
  home: 'home',
  games: 'puzzle',
  events: 'star',
  clothing: 't-shirt',
}

export type IconNames =
  | 'accept'
  | 'accept-thin'
  | 'account-group'
  | 'account-mark'
  | 'account-plus'
  | 'account-right-arrow'
  | 'arrow-down-bold'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right-bold'
  | 'arrow-right'
  | 'badge'
  | 'bell'
  | 'book'
  | 'burger'
  | 'cart-filled'
  | 'chat'
  | 'clocks'
  | 'commission'
  | 'company-account'
  | 'copy'
  | 'cross'
  | 'display-grid-filter'
  | 'display-horizontal-filter'
  | 'display-vertical-filter'
  | 'dots'
  | 'email-black'
  | 'facebook-black'
  | 'facebook'
  | 'google'
  | 'heart'
  | 'info'
  | 'like'
  | 'location'
  | 'logo'
  | 'logo-sliced'
  | 'more'
  | 'percent'
  | 'phone-black'
  | 'play'
  | 'plus'
  | 'price-tag'
  | 'search'
  | 'search-input'
  | 'settings'
  | 'share-arrow'
  | 'shop'
  | 'sort'
  | 'stats'
  | 'twitter-border'
  | 'upload'
  | 'user-account'
  | 'wecobi'
  | 'correct-tick'
  | CategoryIconNames

export const Icons: {
  [key in IconNames]: React.MemoExoticComponent<(props: React.SVGProps<SVGSVGElement>) => JSX.Element>
} = {
  accept: AcceptIcon,
  'accept-thin': AcceptThinIcon,
  'account-group': AccountGroupIcon,
  'account-mark': AccountMarkIcon,
  'account-plus': AccountPlusIcon,
  'account-right-arrow': AccountRightArrowIcon,
  'arrow-down-bold': ArrowDownBoldIcon,
  'arrow-down': ArrowDownIcon,
  'arrow-left': ArrowLeftIcon,
  'arrow-right-bold': ArrowRightBoldIcon,
  'arrow-right': ArrowRightIcon,
  badge: BadgeIcon,
  bell: BellIcon,
  book: BookIcon,
  burger: BurgerIcon,
  'cart-filled': CartFilledIcon,
  chat: ChatIcon,
  clocks: ClocksIcon,
  commission: CommissionIcon,
  'company-account': CompanyAccountIcon,
  copy: CopyIcon,
  cross: CrossIcon,
  'display-grid-filter': DisplayGridFilterIcon,
  'display-horizontal-filter': DisplayHorizontalFilterIcon,
  'display-vertical-filter': DisplayVerticalFilterIcon,
  dots: DotsIcon,
  'email-black': EmailBlackIcon,
  'facebook-black': FacebookBlackIcon,
  facebook: FacebookIcon,
  google: GoogleIcon,
  heart: HeartIcon,
  info: InfoIcon,
  like: LikeIcon,
  location: LocationIcon,
  logo: LogoIcon,
  'logo-sliced': LogoSlicedIcon,
  more: MoreIcon,
  percent: PercentIcon,
  'phone-black': PhoneBlackIcon,
  play: PlayIcon,
  plus: PlusIcon,
  'price-tag': PriceTagIcon,
  search: SearchIcon,
  'search-input': SearchInputIcon,
  settings: SettingsIcon,
  'share-arrow': ShareArrowIcon,
  shop: ShopIcon,
  sort: SortIcon,
  stats: StatsIcon,
  'twitter-border': TwitterIcon,
  upload: UploadIcon,
  'user-account': UserAccountIcon,
  wecobi: WecobiIcon,
  babysitter: BabysitterIcon,
  bike: BikeIcon,
  brush: BrushIcon,
  cellphone: CellphoneIcon,
  earth: EarthIcon,
  home: HomeIcon,
  puzzle: PuzzleIcon,
  star: StarIcon,
  't-shirt': TShirtIcon,
  'correct-tick': CorrectTickIcon,
}
