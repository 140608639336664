import { action, computed, makeObservable, observable } from 'mobx'
import { FirestoreService } from '../../../../services/core/firestore.service'
import { CompanyService } from '../../../../services/entities/company.service'
import { Campaign, Company } from '../../../../types/company.types'

export class BrandAccountVm {
  brandId: string
  userId?: string
  brandData: Company | undefined = undefined
  campaigns: Campaign[] = []
  isFollowing = false
  logo: string | undefined = undefined

  constructor(brandId: string, brandData?: Company, userId?: string) {
    this.brandId = brandId
    this.brandData = brandData
    this.userId = userId

    makeObservable(this, {
      campaigns: observable,
      isFollowing: observable,
      brandData: observable,
      logo: observable,
      setCampaigns: action,
      setBrandData: action,
      setIsFollowing: action,
      setLogo: action,
      shared: computed,
      followed: computed,
    })
  }

  public get shared() {
    return this.campaigns.map((campaign) => campaign.shared.length).reduce((prev, shared) => (prev += shared), 0)
  }

  public get followed() {
    return this.brandData?.followed.length || 0
  }

  public setCampaigns = (campaigns: Campaign[]) => {
    this.campaigns = campaigns
  }

  public setBrandData = (brandData: Company) => {
    this.brandData = brandData
  }

  public setIsFollowing = (value: boolean) => {
    this.isFollowing = value
  }

  public setLogo = (value: string) => {
    this.logo = value
  }

  public fetchData = async () => {
    if (this.brandId) {
      if (!this.brandData) {
        const { companyData } = await FirestoreService.getCompanyById(this.brandId)
        if (!companyData) {
          throw new Error('company was not found')
        }
        this.setBrandData(companyData)
      }
      const companyLogo = await CompanyService.getLogo(this.brandId)
      const campaigns = await FirestoreService.getCampaignsByCompanyId(this.brandId)
      const isFollowing = await this.checkIfFollowing()
      this.setIsFollowing(isFollowing)
      this.setCampaigns(campaigns)
      this.setLogo(companyLogo)
    }
  }

  public follow = async () => {
    if (this.userId) {
      await CompanyService.followCompany(this.brandId, this.userId)
      this.setIsFollowing(true)
    }
  }

  public unfollow = async () => {
    if (this.userId) {
      await CompanyService.unfollowCompany(this.brandId, this.userId)
      this.setIsFollowing(false)
    }
  }

  public checkIfFollowing = async () => {
    if (this.userId) {
      const followings = await CompanyService.getFollowings(this.brandId)
      return followings.findIndex((follower) => follower === this.userId) !== -1
    } else {
      return false
    }
  }
}
