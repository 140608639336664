import React from 'react'
import '../ListItem/ListItem.scss'
import { Wrapper } from '../Wrapper/Wrapper'
import { Text } from '../../Text'
import { Icon } from '../../Icon/Icon'
import { COLORS } from '../../../config/constants'
import { Spacings } from '../../../styles/spacings/Spacings'

interface ListItemProps extends Spacings {
  value?: string
  onDelete?: () => void
}

export const ListItem = (props: ListItemProps) => {
  const { value, onDelete, ...otherProps } = props
  return (
    <Wrapper className="list__item" {...otherProps}>
      <Text size={16} weight={300}>
        {value}
      </Text>

      <Icon onClick={onDelete} color={COLORS.COLOR_DARK_GRAY} height={14} width={14} name="cross" />
    </Wrapper>
  )
}
