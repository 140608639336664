import { functions } from '../../firebase'
import { httpsCallable } from 'firebase/functions'

export interface StripeProductParams {
  name: string
  description: string
  images: string[]
  price: number
}

export interface CreateStripeCheckoutParams {
  successUrl: string
  cancelUrl: string
  customerId: string
}

interface CreateCustomerParams {
  name: string
  email: string
}

interface CreatePaymentIntentFromCheckoutSessionParams {
  sessionId: string
  customerId: string
}

interface CustomerFunctionResponse {
  id: string
}

interface CreateProductFunctionResponse {
  productId: string
  priceId: string
}

interface CreateCheckoutFunctionResponse {
  id: string
  url: string
}

interface PaymentIntentFunctionResponse {
  id: string
}

class StripeManager {
  public createCustomer = async (data: CreateCustomerParams) => {
    const createCustomerFunction = httpsCallable<CreateCustomerParams, CustomerFunctionResponse>(
      functions,
      'createCustomer'
    )
    const customer = await createCustomerFunction(data)
    return customer.data.id
  }

  public createProduct = async (data: StripeProductParams) => {
    const createProductFunction = httpsCallable<StripeProductParams, CreateProductFunctionResponse>(
      functions,
      'createStripeProduct'
    )
    const product = await createProductFunction(data)
    return product.data
  }

  public createCheckout = async (data: CreateStripeCheckoutParams) => {
    const createCheckoutSetup = httpsCallable<CreateStripeCheckoutParams, CreateCheckoutFunctionResponse>(
      functions,
      'createCheckoutSetup'
    )
    try {
      const session = await createCheckoutSetup(data)
      return session.data
    } catch (error) {
      console.log(error)
    }
  }

  public createPaymentIntentFromCheckoutSession = async (data: CreatePaymentIntentFromCheckoutSessionParams) => {
    const createPaymentIntent = httpsCallable<
      CreatePaymentIntentFromCheckoutSessionParams,
      PaymentIntentFunctionResponse
    >(functions, 'createPaymentIntentFromCheckoutSession')
    try {
      const paymentIntent = await createPaymentIntent(data)
      if (paymentIntent.data) {
        return paymentIntent.data.id
      } else {
        throw new Error('Error')
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const StripeService = new StripeManager()
