import { FirestoreService } from '../../services/core/firestore.service'
import { LocalStorageService } from '../../services/core/localStorage.service'

export interface SharedId {
  campaignId: string
  userId: string
}

export const splitUrl = (uri: string) => {
  const uriElements = uri.split('/')
  const hasShareId = uriElements.length > 3
  const campaignId = uriElements[uriElements.length - (hasShareId ? 2 : 1)]
  const shareId = hasShareId ? uriElements[uriElements.length - 1] : null

  return { campaignId, shareId }
}

export const saveShareIdLocally = async (campaignId: string, userId: string) => {
  const { userData } = await FirestoreService.getUserById(userId)
  const { campaignData } = await FirestoreService.getCampaignById(campaignId)
  if (userData && campaignData) {
    const shareIds = LocalStorageService.getItem<SharedId[]>('shareIds')
    const shareIdObject = { campaignId, userId }

    if (shareIds) {
      if (shareIds.findIndex((value) => value.campaignId === campaignId) === -1) {
        LocalStorageService.setItem('shareIds', [...shareIds, shareIdObject])
      }
    } else {
      LocalStorageService.setItem('shareIds', [{ campaignId, userId }])
    }
  }
}

export const saveShared = async (campaignId: string, userFrom: string, userTo: string) => {
  await FirestoreService.addCampaignShared(campaignId, userFrom, userTo)
}
