import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { COLORS, WINDOW } from '../../config/constants'
import { Text } from '../Text'

interface FooterProps {
  height: number
}

const SmallFooterWrapper = styled.div`
  width: 100%;
  height: 62px;
  display: flex;
`

export const FooterWrapper = styled(SmallFooterWrapper)`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  max-width: ${WINDOW.MAX_WIDTH}px;
  background-color: black;
  height: ${(p: FooterProps) => p.height}px;
  bottom: 0;
  z-index: 1;
`

export const FooterPlug = styled.div`
  height: ${(p: FooterProps) => p.height}px;
`

export const FooterCTAWrapper = styled(SmallFooterWrapper)`
  position: fixed;
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 100;
  bottom: 0;
`

export const FooterCTABackground = styled(SmallFooterWrapper)`
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  transform: matrix(1, 0, 0, -1, 0, 0);
  opacity: 0.52;
  position: absolute;
  z-index: -1;
  width: ${WINDOW.MAX_WIDTH}px;
`

const FooterButton = styled(Link)`
  height: 44px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: ${COLORS.COLOR_WHITE};
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 157.2%;
  border-radius: 41px;
  text-decoration: none;
`

export const CreateButton = styled(FooterButton)`
  padding-left: 31.5px;
  padding-right: 31.5px;
  background: linear-gradient(270deg, #343434 0%, #000000 100%);
  margin-right: 10px;
`

export const LoginButton = styled(FooterButton)`
  padding-left: 36px;
  padding-right: 36px;
  background-color: ${COLORS.COLOR_ORANGE_1};
`

export const FooterPreviewWrapper = styled.div`
  position: relative;
  width: 100%;
  background: linear-gradient(180deg, #343434 0.13%, #000000 100.13%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
`

export const FooterPreviewLinksWrapper = styled.div`
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 57px;
  margin-bottom: 68px;
`

export const FooterPreviewLink = styled(Text)`
  margin-bottom: 20px;

  & a {
    color: ${COLORS.COLOR_LIGHT_GRAY_2};
    letter-spacing: -0.41px;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    text-decoration: none;
  }
`
