import React from 'react'

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '28'}
      height={props.height || '27'}
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M14 27L11.97 25.0578C4.76 18.1864 0 13.6398 0 8.09264C0 3.54605 3.388 0 7.7 0C10.136 0 12.474 1.19183 14 3.06049C15.526 1.19183 17.864 0 20.3 0C24.612 0 28 3.54605 28 8.09264C28 13.6398 23.24 18.1864 16.03 25.0578L14 27Z"
        fill={props.color || '#D5D5D5'}
      />
    </svg>
  )
}

export const LikeIcon = React.memo(SVGComponent)
