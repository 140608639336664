import React from 'react'

import { Text } from '../../../../components'
import { COLORS } from '../../../../config/constants'
import { CategoriesList } from './CategoriesList'

import { Wrapper, Header, CategoriesWrapper, CategoriesContainer } from './CategoriesPreview.styles'

export const CategoryPreview = () => {
  return (
    <Wrapper>
      <Header>
        <Text color={COLORS.COLOR_LIGHT_GRAY_2} size={32} weight={700} lineHeight="119.2%">
          Categories selection
        </Text>
      </Header>
      <CategoriesWrapper>
        <CategoriesContainer>
          <CategoriesList />
        </CategoriesContainer>
      </CategoriesWrapper>
    </Wrapper>
  )
}
