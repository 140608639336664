import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Icon, Text } from '../../../../components'
import { Wrapper, TextContainer } from './MainLayout.styles'
import { Search } from '../../components/Search/Search'

export const MainLayout = () => {
  const history = useHistory()
  const [searchValue, setSearchValue] = useState('')

  const onClick = () => {
    history.push({ pathname: '/search', state: { query: searchValue } })
  }

  return (
    <Wrapper>
      <TextContainer>
        <Icon name="logo" width={114} height={77} />
        <Text size={33} lineHeight="119.2%" align="center" style={{ marginTop: 13 }}>
          Co-buy <br /> and pay less
        </Text>
      </TextContainer>
      <Search placeholder="| Search products" value={searchValue} onChange={setSearchValue} onClick={onClick} />
    </Wrapper>
  )
}
