import { observer } from 'mobx-react-lite'
import React, { useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Loader } from '../../../../components/Loader'
import { OrderExpandable } from '../../../../components/Order/OrderExpandedable/OrderExpandable'
import { PageWrapper } from '../../../../containers/PageWrapper/PageWrapper'
import { UserStore } from '../../../../store/user.store'
import { Follower } from '../../../Following/shared/components/Follower'
import { splitUrl } from '../../campaignDetail.helpers'
import { CampaignBuyersVm } from './CampaignBuyers.vm'

export const CampaignBuyers = observer(() => {
  const location = useLocation()
  const { userData, followUser, unfollowUser } = useContext(UserStore)

  const { campaignId } = splitUrl(location.pathname)

  const [vm, setVm] = useState<CampaignBuyersVm | undefined>(undefined)

  useEffect(() => {
    if (vm) {
      vm.init()
    }
  }, [vm])

  useEffect(() => {
    setVm(new CampaignBuyersVm(campaignId))
  }, [campaignId])

  const onFollow = async (uid: string, type: 'follow' | 'unfollow') => {
    if (type === 'follow') {
      await followUser(uid)
    } else {
      await unfollowUser(uid)
    }
  }

  return (
    <>
      {vm && vm.campaign ? (
        <PageWrapper>
          <OrderExpandable {...vm.campaign} />
          {vm.buyers?.map(({ avatar, username, name, uid }, index) => {
            const isFollowed = userData?.following?.includes(uid)
            return (
              <Follower
                avatar={avatar}
                username={username}
                name={name}
                isFollowed={!!isFollowed}
                key={index}
                onFollow={() => onFollow(uid, isFollowed ? 'unfollow' : 'follow')}
              />
            )
          })}
        </PageWrapper>
      ) : (
        <Loader absoluteCenter />
      )}
    </>
  )
})
