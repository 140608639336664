import styled, { css } from 'styled-components'

import { COLORS } from '../../config/constants'
import { ProductComponentTypes, ProductSizes, PRODUCT_SIZE } from './Product.interfaces'

export const ProductsSlider = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
  margin-top: 12px;

  &::-webkit-scrollbar {
    display: none;
  }
`
export const ProductWrapper = styled.div<{ width?: number }>`
  min-width: ${(p) => p.width || 224}px;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 13px;
`

interface ProductProps {
  type: ProductComponentTypes
  progressBarHeight: number
  size: ProductSizes
}

export const ProductContainer = styled.div<ProductProps>`
  width: 100%;
  ${(props) =>
    props.type === 'filled' &&
    css`
      background-color: white;
      padding: 20px;
      border-radius: 7px;
      box-shadow: -2px 2px 12px rgba(0, 0, 0, 0.13);
    `}

  ${(props) =>
    props.type === 'outlined' &&
    css<ProductProps>`
      & .product_image_wrapper {
        display: flex;
        position: relative;
        width: 100%;
        background-color: white;
        border-radius: 7px;
        padding: ${(props) => (props.size === 'small' ? 10 : 20)}px;
        justify-content: center;
        box-shadow: -2px 2px 12px rgba(0, 0, 0, 0.13);
      }

      & .product_status_bar {
        position: absolute;
        height: ${props.progressBarHeight};
        width: 90%;
        bottom: ${-props.progressBarHeight / 2}px;
      }
    `}

  .product_status_bar {
    margin-top: 5px;
    height: ${(props) => props.progressBarHeight}px;
    width: ${(props) => (props.type === 'outlined' ? 90 : 100)}%;
  }

  .product_image_wrapper {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .like {
      margin-bottom: 10px;
      align-self: flex-end;
    }
  }

  .product_image_container {
    width: ${(props) => (props.size === 'small' ? PRODUCT_SIZE.SMALL : PRODUCT_SIZE.LARGE)}px;
    height: ${(props) => (props.size === 'small' ? PRODUCT_SIZE.SMALL : PRODUCT_SIZE.LARGE)}px;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .details {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    & div {
      display: flex;
    }
  }

  .price {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: ${(props) => (props.size === 'small' ? 25 : 33)}px;
    line-height: ${(props) => (props.size === 'small' ? 157.2 : 125.7)}%;
  }

  .price_old {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: ${(props) => (props.size === 'small' ? 13 : 16)}px;
    line-height: ${(props) => (props.size === 'small' ? 130.2 : 126.2)}%;
    display: flex;
    align-items: flex-end;
    text-decoration-line: line-through;
  }

  .buyers {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: ${(props) => (props.size === 'small' ? 17 : 28)}px;
    line-height: ${(props) => (props.size === 'small' ? 157.2 : 125.7)}%;
    color: #e4560a;
  }

  .goal {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: ${(props) => (props.size === 'small' ? 12 : 16)}px;
    line-height: ${(props) => (props.size === 'small' ? 157.2 : 126.2)}%;
    color: #e4560a;
    display: flex;
    align-items: center;
  }

  .description {
    display: flex;
    flex-direction: column;

    .title {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: ${(props) => (props.size === 'small' ? 13 : 21)}px;
      line-height: 119.2%;
      color: ${COLORS.COLOR_BLACK};
      margin-top: 7px;
      margin-bottom: 2px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      width: ${(props) => (props.size === 'small' ? PRODUCT_SIZE.SMALL : PRODUCT_SIZE.LARGE)}px;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .description_details {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: ${(props) => (props.size === 'small' ? 12 : 16)}px;
      line-height: 126.2%;
      color: #434343;
      display: flex;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow-x: scroll;
      overflow-y: hidden;
    }

    & div {
      display: flex;
      justify-content: space-between;
    }
  }
`
