import React, { ChangeEvent } from 'react'

import './ProductImages.scss'
import { ImageBlock } from './ImageBlock/ImageBlock'
import { ImageBlockUpload } from './ImageBlock/ImageBlockUpload'
import { UploadIcon } from '../../../../../../assets/icons'

interface ProductImagesProps {
  images: File[]
  onUpload: (e: ChangeEvent<HTMLInputElement>) => void
  onImageRemove: (name: string) => void
}

export const ProductImages = ({ images, onUpload, onImageRemove }: ProductImagesProps) => {
  return (
    <div className="productDetails__productImages">
      <div className="title">Product images</div>
      <div className="images">
        <ImageBlockUpload Image={UploadIcon} onUpload={onUpload} />
        {images.map((image) => (
          <ImageBlock
            image={URL.createObjectURL(image)}
            key={image.name}
            onImageRemove={() => onImageRemove(image.name)}
          />
        ))}
      </div>
      <div className="description">
        If you focus on achieving more public engagements, we advice to set NO. If you focus on sales, we advice to set
        YES.
      </div>
    </div>
  )
}
