import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={28} height={16} viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.625 9.5c-2.25 0-6.75 1.125-6.75 3.375a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25c0-2.25-4.5-3.375-6.75-3.375zm7.448.18c.933.81 1.552 1.867 1.552 3.195v2.25h1.125a2.25 2.25 0 002.25-2.25c0-1.732-2.666-2.813-4.927-3.195zm-7.448-2.43a3.375 3.375 0 100-6.75 3.375 3.375 0 000 6.75zm5.625 0a3.375 3.375 0 100-6.75c-.36 0-.709.056-1.035.157a5.596 5.596 0 011.035 3.218 5.561 5.561 0 01-1.035 3.206 3.17 3.17 0 001.035.169zM4.243 3.582a1 1 0 011.414 0l4.242 4.243-4.242 4.242a1 1 0 01-1.414-1.414L6.02 8.875H1a1 1 0 010-2h5.121L4.243 4.996a1 1 0 010-1.414z"
        fill="#FF6D0A"
      />
    </svg>
  )
}

export const AccountRightArrowIcon = React.memo(SvgComponent)
