import { action, makeObservable, observable } from 'mobx'
import { ProductProps } from '../../components/Product/Product.interfaces'
import { FirestoreService } from '../../services/core/firestore.service'
import { Campaign } from '../../types/company.types'
import { User } from '../../types/user.types'

export class HomeVm {
  campaigns: ProductProps[] = []
  userData: User | null

  constructor(userData: User | null) {
    this.userData = userData

    makeObservable(this, {
      campaigns: observable,
      setCampaigns: action,
    })
  }

  public setCampaigns = (campaigns: Campaign[]) => {
    this.campaigns = campaigns
  }

  public fetchCampaigns = async () => {
    const campaigns = await FirestoreService.fetchCampaigns()
    const products: ProductProps[] = campaigns.map((campaign) => ({
      ...campaign,
      isLiked: this.userData?.liked.includes(campaign.id) || false,
    }))
    this.setCampaigns(products)
  }
}
