import React from 'react'

import {
  Content,
  Image,
  OrderWrapper,
  DescriptionWrapper,
  TitlesAndButtonWrapper,
  Titles,
  InfoWrapper,
} from './OrderCollapsed.style'
import { ProgressBar } from '../../reusable/ProgressBar/ProgressBar'
import { OrderButton } from '../OrderButton/OrderButton'
import { ParticipantsList } from '../../ParticipantsList/ParticipantsList'
import { useHistory } from 'react-router-dom'
import { Campaign } from '../../../types/company.types'
import TestBgImage from '../../../assets/images/test_bg.png'

export const OrderCollapsed = (props: Campaign) => {
  const history = useHistory()
  const { id, images, title, description, status, price, buyers, goal, participants } = props

  const onClick = () => history.push({ pathname: `/campaign/${id}`, state: JSON.stringify(props) })

  return (
    <OrderWrapper>
      <Content>
        <Image onClick={onClick}>
          <img src={(images && images[0]) || TestBgImage} alt="campaign-image" />
        </Image>
        <DescriptionWrapper>
          <TitlesAndButtonWrapper>
            <Titles>
              <div className="title">{title}</div>
              <div className="subtitle">{description}</div>
            </Titles>
            <OrderButton status={status} />
          </TitlesAndButtonWrapper>
          <InfoWrapper>
            <p className="price">${price}</p>
            <p className="pcs">
              {buyers.length} / {goal}
            </p>
            {participants && participants.length > 0 && (
              <div className="participants">
                <ParticipantsList width={53} imageWidth={20} images={participants} />
              </div>
            )}
          </InfoWrapper>
        </DescriptionWrapper>
      </Content>
      <ProgressBar height={6} pcs={buyers.length} pcsMax={goal} />
    </OrderWrapper>
  )
}
