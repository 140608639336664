import styled from 'styled-components'

export const DrawerWrapper = styled.div`
  display: flex;
  width: 300px;
  background-color: white;
  flex: 1;
  flex-direction: column;
`

export const ItemsWrapper = styled.div`
  width: 100%;
  flex: 1;
  padding: 0px 11px 0px 11px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const MainItems = styled.div``

export const SecondaryItems = styled.div``
