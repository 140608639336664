import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { PageWrapper } from '../../../../containers/PageWrapper/PageWrapper'
import { CompanyStore } from '../../../../store/company.store'
import { Header } from '../shared/Header'
import { splitUrl } from './BrandAccount.helper'
import { BrandAccountVm } from '../shared/BrandAccount.vm'
import { DisplayGrid } from '../../../../components/DisplaySettings/displays/Displays'
import { Loader } from '../../../../components/Loader'
import { ActionBar } from './components/ActionBar'
import { UserStore } from '../../../../store/user.store'

export const BrandAccount = observer(() => {
  const location = useLocation()
  const history = useHistory()
  const brandId = splitUrl(location.pathname)

  const { userData } = useContext(UserStore)
  const { companyData } = useContext(CompanyStore)
  const [vm, setVm] = useState<BrandAccountVm | undefined>(undefined)

  useEffect(() => {
    if (brandId && companyData?.id === brandId) {
      history.push('/account/brand')
    }
  }, [companyData, brandId, history])

  useEffect(() => {
    ;(async () => {
      if (brandId && vm) {
        try {
          await vm.fetchData()
        } catch (error: any) {
          alert(error.message)
          history.push('/')
        }
      }
    })()
  }, [vm, brandId, history])

  useEffect(() => {
    if (userData?.uid) {
      setVm(new BrandAccountVm(brandId, undefined, userData.uid))
    }
  }, [userData, brandId])

  return (
    <PageWrapper>
      {vm && vm?.brandData ? (
        <>
          <Header
            name={vm.brandData.companyName}
            username={vm.brandData.companyUsername}
            shared={vm.shared}
            followed={vm.followed}
            modalData={{
              description: vm.brandData.description,
              email: vm.brandData.email,
              contactNumber: vm.brandData.contactNumber,
              socials: vm.brandData.socials,
            }}
            image={vm.logo}
          />
          <ActionBar isFollowing={vm.isFollowing} onFollow={vm.follow} onUnfollow={vm.unfollow} />
          {vm.campaigns && <DisplayGrid products={vm?.campaigns} />}
        </>
      ) : (
        <Loader absoluteCenter />
      )}
    </PageWrapper>
  )
})
