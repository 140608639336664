import React from 'react'

import { AccountSlider } from '../../../../components/AccountSlider/AccountSlider'
import { DisplaySettings } from '../../../../components/DisplaySettings/DisplaySettings'
import { ProductList } from '../../../../components/DisplaySettings/ProductList'
import { AccountProductsData, AccountProductsProps } from './AccountProducts.interface'
import { AccountProductsWrapper } from './AccountProducts.style'

export const AccountProducts = ({
  index,
  setIndex,
  display,
  setDisplay,
  country,
  wishlist,
  orders,
}: AccountProductsProps) => {
  const data: AccountProductsData[] = [
    {
      title: 'Co-orders',
      icon: 'cart-filled',
      onRightArrow: () => setIndex(1),
      campaigns: orders,
    },
    {
      title: 'Wishlist',
      icon: 'like',
      onLeftArrow: () => setIndex(0),
      campaigns: wishlist,
    },
  ]

  return (
    <AccountProductsWrapper>
      <AccountSlider
        title={data[index].title}
        icon={data[index].icon}
        onLeftArrow={data[index].onLeftArrow}
        onRightArrow={data[index].onRightArrow}
      />
      <DisplaySettings display={display} setDisplay={setDisplay} country={country} disableCountry />
      <ProductList display={display} products={data[index].campaigns} />
    </AccountProductsWrapper>
  )
}
