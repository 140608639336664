export interface ProgressBarStateProps {
  pcs: number
  pcsMax: number
  height?: number
}

export enum PROGRESS_BAR_HEIGHTS {
  SMALL = 10,
  LARGE = 13,
}
