import React from 'react'

import { Text } from '../../../../../components'
import { ActivityBlockWrapper } from './Activity.style'
import { COLORS } from '../../../../../config/constants'
import { BLOCK_WIDTH } from './Activity.config'
import { ActivityBlockProps } from './Activity.interface'

export const ActivityBlock = ({ count, title, onClick }: ActivityBlockProps) => {
  return (
    <ActivityBlockWrapper width={BLOCK_WIDTH} onClick={onClick}>
      <div className="count">
        <Text color={COLORS.COLOR_DARK_GRAY} weight={400} letterSpacing={0.38} size={20} lineHeight="25px">
          {count}
        </Text>
      </div>
      <div className="title">
        <Text weight={400} letterSpacing={-0.08} color={COLORS.COLOR_DARK_GRAY}>
          {title}
        </Text>
      </div>
    </ActivityBlockWrapper>
  )
}
