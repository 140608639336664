import styled from 'styled-components'

import { COLORS } from '../../config/constants'

export const RadioButtonItemWrapper = styled.label`
  display: flex;
`

export const CircleWrapper = styled.input`
  position: absolute;
  opacity: 0;
  width: 30px;
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 157.2%;
  display: flex;
  align-items: center;

  color: ${COLORS.COLOR_DARK_GRAY};
`
