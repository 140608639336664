import React from 'react'

import { OrderRow } from '../../components/OrderRow'
import { Campaign } from '../../../../types/company.types'

export const OrderExpandedFinished = ({ startDate, endDate }: Omit<Campaign, 'status'>) => (
  <>
    <OrderRow title="Campaign started:" date={startDate} />
    {endDate && <OrderRow title="Campaign finished:" date={endDate} />}
  </>
)

export const CampaignExpandedFinished = ({ startDate, endDate }: Omit<Campaign, 'status'>) => (
  <>
    <OrderRow title="Campaign started:" date={startDate} />
    {endDate && <OrderRow title="Campaign finished:" date={endDate} />}
  </>
)
