import styled from 'styled-components'

interface AccountSliderWrapperProps {
  height: number
}

const defaultContent = styled.div`
  display: flex;
  align-items: center;
`

export const AccountSliderWrapper = styled(defaultContent)`
  height: ${(p: AccountSliderWrapperProps) => p.height}px;
  padding: 0 27px;
`
export const AccountSliderInfo = styled(defaultContent)`
  justify-content: center;
  width: 100%;

  .icon {
    margin-right: 10px;
  }

  .title {
    margin-left: 10px;
  }
`
export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
