import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button, Input, TextareaInput } from '../../components'
import { Text } from '../../../../components'
import { COLORS } from '../../../../config/constants'
import { FirestoreService } from '../../../../services/core/firestore.service'
import { UserStore } from '../../../../store/user.store'
import { Company } from '../../../../types/company.types'

interface CompanyProps {
  companyName: string
  companyUsername: string
  description: string
  website: string
  facebook: string
  country: string
  category: string
  employeeCount: string
  contactPerson: string
  contactNumber: string
  email: string
}

export interface CompanyData extends Omit<CompanyProps, 'website' | 'facebook'>, Omit<Company, 'id'> {}

export const Form = () => {
  const [loginError, setLoginError] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { userData } = useContext(UserStore)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CompanyProps>()

  const onSubmit = async ({
    companyName,
    companyUsername,
    description,
    website,
    facebook,
    country,
    category,
    employeeCount,
    contactPerson,
    contactNumber,
    email,
  }: CompanyProps) => {
    try {
      setLoading(true)
      setLoginError('')
      if (userData?.uid) {
        const companyData: CompanyData = {
          companyName,
          companyUsername,
          description,
          country,
          category,
          employeeCount,
          contactPerson,
          contactNumber,
          email,
          socials: {
            website,
            facebook,
          },
          userId: userData?.uid,
          followed: [],
        }
        await FirestoreService.addCompany(companyData)
      } else {
        throw new Error()
      }
      history.push('/')
    } catch (e) {
      setLoginError('Failed to create a company')
    }
    setLoading(false)
  }

  return (
    <form className="signup-company-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="inputs">
        <Input
          label="Company name"
          register={register('companyName', { required: 'You must specify a company name' })}
          error={errors.companyName?.message}
        />
        <Input
          label="Company username"
          register={register('companyUsername', {
            required: 'You must specify a company username',
          })}
          error={errors.companyUsername?.message}
        />
        <TextareaInput
          label="Company description"
          register={register('description', {
            required: 'You must specify a company description',
          })}
          error={errors.description?.message}
        />
        <Input
          label="Country"
          register={register('country', {
            required: 'You must specify a country',
          })}
          error={errors.country?.message}
        />
        <Input
          label="Category of goods"
          register={register('category', {
            required: 'You must specify a category',
          })}
          error={errors.category?.message}
        />
        <Input
          type="number"
          label="Number of employees"
          register={register('employeeCount', {
            required: 'You must specify a number of employees',
          })}
          error={errors.employeeCount?.message}
        />
        <Input
          label="Contact person"
          register={register('contactPerson', {
            required: 'You must specify a contact person',
          })}
          error={errors.contactPerson?.message}
        />
        <Input
          label="Contact number"
          register={register('contactNumber', {
            required: 'You must specify a contact number',
          })}
          error={errors.contactNumber?.message}
        />
        <Input
          label="Email"
          register={register('email', { required: true, pattern: /^\S+@\S+$/i })}
          error={errors.email?.message}
        />
        <Input label="Website" register={register('website')} error={errors.website?.message} />
        <Input label="Facebook" register={register('facebook')} error={errors.facebook?.message} />
      </div>
      <div className="buttons">
        <Button type="submit" className="black" disabled={loading}>
          Send a request
        </Button>
        {loginError && <p>{loginError}</p>}
      </div>
      <div className="form_description_container">
        <Text size={14} color={COLORS.COLOR_MIDDLE_GRAY} weight={300}>
          We will contact you by email or phone number as soon as we can to proceed with registration of your company.
        </Text>
      </div>
    </form>
  )
}
