import { ChangeEvent, createContext } from 'react'
import { observable, makeObservable, action } from 'mobx'
import { remove } from 'lodash'

import { User } from '../types/user.types'
import { UserService } from '../services/entities/user.service'
import { FirestoreService } from '../services/core/firestore.service'

export class UserStoreVm {
  userData: User | null = null

  constructor() {
    makeObservable(this, {
      userData: observable,
      setUser: action,
    })
  }

  public setUser = (data: User | null) => {
    this.userData = data
  }

  public getUsername = () => (this.userData?.username ? UserService.getUsername(this.userData?.username) : undefined)

  public updateAvatar = async (value: File) => {
    if (this.userData?.username) {
      const avatar = await UserService.updateAvatar(value, this.userData.uid)
      this.userData.avatar = avatar
      return avatar
    } else {
      return null
    }
  }

  public removeAvatar = async () => {
    if (this.userData?.username) {
      await UserService.removeAvatar(this.userData.uid)
      this.userData.avatar = undefined
    }
  }

  public updateName = async (userName: string) => {
    if (this.userData?.username) {
      const name = await UserService.updateName(userName, this.userData?.uid)
      if (name) {
        this.userData.name = name
      }
    }
  }

  public updateUsername = async (username: string) => {
    if (this.userData?.username) {
      const name = await UserService.updateUsername(username, this.userData?.uid)
      if (name) {
        this.userData.username = name
        return null
      } else {
        throw new Error('Account with this username already exists. Try another.')
      }
    }
  }

  public addDeliveryLocations = async (locations: string[]) => {
    if (this.userData?.uid) {
      await UserService.addDeliveryLocations(locations, this.userData?.uid)
    }
  }

  public onAddAvatar = async (e: ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files?.item(0)
    if (image) {
      try {
        return await this.updateAvatar(image)
      } catch (error) {
        throw new Error('Error with the file upload, try later.')
      }
    }
  }

  public followUser = async (followingId: string) => {
    if (this.userData?.uid) {
      await UserService.followUser(this.userData.uid, followingId)
      if (!this.userData.following) {
        this.userData.following = [followingId]
      } else {
        this.userData.following.push(followingId)
      }
    }
  }

  public unfollowUser = async (followingId: string) => {
    if (this.userData?.uid) {
      await UserService.unfollowUser(this.userData.uid, followingId)
      if (this.userData.following && this.userData.following.length > 0) {
        this.userData.following = remove([...this.userData.following], (following) => following !== followingId)
      }
    }
  }

  public updateLiked = async (liked: string[]) => {
    if (this.userData?.liked) {
      this.userData.liked = liked
    }
  }

  public subscribeToCampaign = async (campaignId: string) => {
    if (this.userData?.uid) {
      await FirestoreService.subscribeCampaign(this.userData.uid, campaignId)
      this.userData.subscribedCampaigns?.push(campaignId)
    }
  }

  public unsubscribeToCampaign = async (campaignId: string) => {
    if (this.userData?.uid) {
      await FirestoreService.unsubscribeCampaign(this.userData.uid, campaignId)
      const index = this.userData.subscribedCampaigns?.indexOf(campaignId)
      if (index) {
        this.userData.subscribedCampaigns?.splice(index, 1)
      }
    }
  }
}

export const UserStore = createContext(new UserStoreVm())
