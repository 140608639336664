import React, { useContext } from 'react'
import { ICategoryNames } from '../../../../components/Categories/CategoryItem/CategoryItem.types'
import { categoriesInRow } from './CategoryPreview.config'
import { CategoryItem } from '../../../../components'
import { useHistory } from 'react-router-dom'
import { SettingsStore } from '../../../../store/settings.store'
import { observer } from 'mobx-react-lite'

interface CategoriesListProps {
  onClick?: (category: ICategoryNames) => void
}

export const CategoriesList = observer(({ onClick }: CategoriesListProps) => {
  const { categories } = useContext(SettingsStore)
  const history = useHistory()

  const onCategoryClick = (category: ICategoryNames) => {
    if (onClick) {
      onClick(category)
    } else {
      history.push({ pathname: '/search', state: { category } })
    }
  }

  return (
    <>
      {Object.entries(categories).map((value, index) => {
        const firstRowStyles = index < categoriesInRow ? { marginBottom: 15 } : {}
        const rowStyles = (index + 1) % categoriesInRow !== 0 ? { marginRight: 8.5 } : {}
        const columnStyles = index - categoriesInRow < Object.values(categories).length ? { marginBottom: 31 } : {}

        return (
          <CategoryItem
            key={value[0]}
            name={value[0] as ICategoryNames}
            size="medium"
            style={{ ...rowStyles, ...columnStyles, ...firstRowStyles, padding: 0 }}
            isActive={value[1]}
            onClick={() => onCategoryClick(value[0] as ICategoryNames)}
          />
        )
      })}
    </>
  )
})
