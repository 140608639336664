import React from 'react'
import { useHistory } from 'react-router-dom'

import { COLORS } from '../../../../../config/constants'
import { Icon } from '../../../../../components'
import { IconNames } from '../../../../Icon/Icon.types'

import { Wrapper, ItemText, AccessoryText, LeftPartWrapper } from './DrawerItem.styles'

export interface DrawerItemProps {
  type?: 'main' | 'secondary'
  iconName: IconNames
  title: string
  accessoryText?: string | number
  navigatePath?: string
  borderTop?: boolean
  borderBottom?: boolean
}

export const DrawerItem = ({
  type = 'main',
  iconName,
  title,
  accessoryText,
  navigatePath,
  borderTop,
  borderBottom,
}: DrawerItemProps) => {
  const color = type === 'main' ? COLORS.COLOR_DARK_GRAY : COLORS.COLOR_MIDDLE_GRAY
  const history = useHistory()

  const navigateAction = () => navigatePath && history.push(navigatePath)

  return (
    <Wrapper onClick={navigateAction} borderTop={borderTop} borderBottom={borderBottom}>
      <LeftPartWrapper>
        <Icon name={iconName} width={20} height={20} color={color} />
        <ItemText color={color}>{title}</ItemText>
      </LeftPartWrapper>
      {accessoryText && <AccessoryText>{accessoryText}</AccessoryText>}
    </Wrapper>
  )
}
