import React from 'react'

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '24'}
      height={props.height || '30'}
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.85714 22.9412V12.3529H0L12 0L24 12.3529H17.1429V22.9412H6.85714ZM0 30V26.4706H24V30H0Z"
        fill={props.color || '#D5D5D5'}
      />
    </svg>
  )
}

export const UploadIcon = React.memo(SVGComponent)
