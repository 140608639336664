import React, { useState } from 'react'
import Modal from '@material-ui/core/Modal'

import { AboutCampaign } from './AboutCampaign/AboutCampaign'
// import { OrderCollapsed } from '../../components/Order/OrderCollapsed/OrderCollapsed'
import { DescriptionWrapper } from './MyCommissions.style'
import { Text } from '../../components/Text'
import { COLORS } from '../../config/constants'
import { Switcher } from './Switcher/Switcher'
import { AccountSlider } from '../../components/AccountSlider/AccountSlider'
import { PageWrapper } from '../../containers/PageWrapper/PageWrapper'
import { useHistory } from 'react-router-dom'

export const MyCommissions = () => {
  const history = useHistory()
  const [active, setActive] = useState<number>(0)
  const [isSwitcherHidden, toggleSwitcherHidden] = useState<boolean>(true)

  const toggleSwitcher = () => toggleSwitcherHidden(!isSwitcherHidden)

  const titles = ['Finished campaigns', 'Campaigns in progress']

  return (
    <PageWrapper>
      <AccountSlider
        icon="commission"
        title="My commissions"
        onLeftArrow={() => history.push('/account/orders')}
        onRightArrow={() => history.push('/account/messages')}
      />
      <AboutCampaign
        toggleSwitcherHidden={toggleSwitcher}
        type={active === 0 ? 'finished' : 'progress'}
        campaignInfo={{ sharingOrdered: 0, totallyOrdered: 0, earned: '0' }}
      />
      {/* {orders.length ? (
        orders.map((order, index) => <OrderCollapsed {...order} key={index} />)
      ) : (
        <DescriptionWrapper>
          <Text size={19} color={COLORS.COLOR_WHITE} align="center" lineHeight="119.2%" weight={700}>
            How to earn commissions?
          </Text>
          <Text size={14} color={COLORS.COLOR_WHITE} align="center" lineHeight="157.2%" weight={300}>
            Read a short description
          </Text>
        </DescriptionWrapper>
      )} */}
      <DescriptionWrapper>
        <Text size={19} color={COLORS.COLOR_WHITE} align="center" lineHeight="119.2%" weight={700}>
          How to earn commissions?
        </Text>
        <Text size={14} color={COLORS.COLOR_WHITE} align="center" lineHeight="157.2%" weight={300}>
          Read a short description
        </Text>
      </DescriptionWrapper>
      <Modal open={!isSwitcherHidden} onClose={toggleSwitcher}>
        <Switcher isHidden={isSwitcherHidden} titles={titles} active={active} setActive={setActive} name="type" />
      </Modal>
    </PageWrapper>
  )
}
