import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={26} height={27} viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.2 8.579h-2.6a1 1 0 01-1-1v-.79C16.6 5.796 15.799 5 14.8 5h-3.6c-.999 0-1.8.796-1.8 1.79v.789a1 1 0 01-1 1H5.8a1.78 1.78 0 00-1.791 1.79L4 18.21c0 .994.801 1.79 1.8 1.79h14.4c.999 0 1.8-.796 1.8-1.79v-7.842c0-.993-.801-1.789-1.8-1.789zm-5.4-.895c0 .494-.4.895-.895.895h-1.81a.895.895 0 010-1.79h1.81c.494 0 .895.401.895.895zM8 23a1 1 0 112 0 1 1 0 01-2 0zm4 0a1 1 0 112 0 1 1 0 01-2 0zm4 0a1 1 0 112 0 1 1 0 01-2 0z"
        fill="#6B717A"
      />
    </svg>
  )
}

export const CompanyAccountIcon = React.memo(SvgComponent)
