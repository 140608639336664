import React from 'react'
import { PageWrapper } from '../../containers/PageWrapper/PageWrapper'
import { GuiderHeader } from './components/GuideHeader'
import { GuideRow, GuideStandaloneText } from './components/GuideRow'

const POLICY_DATA = [
  {
    title: 'Data Controller',
    text: `Wecobi is the data controller for the processing of Personal Data about you as a User of the
    Service.
    Wecobi AB
    Stenungsund, Sweden
    Email: legal@Wecobi.com`,
  },
  {
    title: 'Definitions',
    text: `“Data controller” means the natural or legal person, public authority, agency or other body which,
    alone or jointly with others, determines the purposes and means of the processing of personal
    data; where the purposes and means of such processing are determined by Union or Member
    State law, the controller or the specific criteria for its nomination may be provided for by Union or
    Member State law;
    “GDPR” means the General Data Protection Regulation (EU) 2016/679;
    “Personal data” means any information relating to an identified or identifiable natural person (‘data
    subject’); an identifiable natural person is one who can be identified, directly or indirectly, in
    particular by reference to an identifier such as a name, an identification number, location data, an
    
    online identifier or to one or more factors specific to the physical, physiological, genetic, mental,
    economic, cultural or social identity of that natural person;
    “Personal data breach” means a breach of security leading to the accidental or unlawful
    destruction, loss, alteration, unauthorised disclosure of, or access to, personal data transmitted,
    stored or otherwise processed;
    “Processing” means any operation or set of operations which is performed on personal data or on
    sets of personal data, whether or not by automated means, such as collection, recording,
    organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure
    by transmission, dissemination or otherwise making available, alignment or combination,
    restriction, erasure or destruction;
    “Processor” means a natural or legal person, public authority, agency or other body which
    processes personal data on behalf of the controller;
    “Special categories of personal data” means personal data revealing racial or ethnic origin,
    political opinions, religious or philosophical beliefs, or trade union membership, and the
    processing of genetic data, biometric data for the purpose of uniquely identifying a natural person,
    data concerning health or data concerning a natural person's sex life or sexual orientation.`,
  },
  {
    title: 'Access to Personal Data',
    text: `Only those employees at Wecobi who need access to the Personal Data to perform their work
    tasks are processing the Personal Data. All those employees have entered into confidentiality
    agreements.`,
  },
  {
    title: 'Right to Access Personal Data',
    text: `You have the right to obtain from Wecobi confirmation as to whether or not Personal Data
    concerning you are being processed, and, where that is the case, access to the Personal Data
    and the following information:
    • the purposes of the processing;
    • the categories of Personal Data concerned;
    • the recipients or categories of recipient to whom the Personal Data have been or will be
    disclosed, in particular recipients in third countries, i.e. a country outside of the EU/EEA;
    
    • where possible, the envisaged period for which the Personal Data will be stored, or, if not
    possible, the criteria used to determine that period;
    • the existence of the right to request from Wecobi rectification or erasure of Personal Data or
    restriction of processing of Personal Data concerning you or to object to such processing;
    • the right to lodge a complaint with a supervisory authority;
    • where the Personal Data are not collected from you, any available information as to their
    source;
    • the existence of automated decision-making, including profiling, referred to in Article 22(1) and
    (4) of the GDPR and, at least in those cases, meaningful information about the logic involved,
    as well as the significance and the envisaged consequences of such processing for you.
    Where Personal Data are transferred to a third country, i.e. a country outside of the EU/EEA,
    or to an international organisation, you shall have the right to be informed of the appropriate
    safeguards pursuant to Article 46 of the GDPR relating to the transfer.`,
  },
  {
    title: 'Right to Rectification etc.',
    text: `Wecobi is aiming at processing only Personal Data which is correct and that it is updated.
    Rectification
    You have the right to obtain from Wecobi without undue delay the rectification of inaccurate
    Personal Data concerning you.
    The right to be forgotten
    You have the right to obtain from Wecobi the erasure of Personal Data concerning you without
    undue delay and Wecobi has the obligation to erase Personal Data without undue delay inter alia
    when the Personal Data is no longer necessary in relation to the purposes for which they were
    collected or otherwise processed or you have withdrawn consent on which the processing is
    based.
    Restriction of processing
    You have the right to obtain from Wecobi restriction of processing if you have a particular reason
    for wanting the restriction. This may be if you have issues with the content of the information we
    
    hold or how we have processed your personal data. It can also be the case when Wecobi no
    longer needs the Personal Data for the purposes of the processing, but they are required by you
    for the establishment, exercise or defence of legal claims.
    Data portability
    You have the right to receive the Personal Data concerning you, which you have provided to
    Wecobi, in a structured, commonly used and machine-readable format and have the right to
    transmit those data to another data controller without hindrance from Wecobi where the
    processing is based on consent or on a contract or the processing is carried out by automated
    means.
    Profiling
    You have the right to object to processing of Personal Data concerning you based on automated
    decisions and/or profiling. This shall not apply if the decision is necessary for entering into, or
    performance of, a contract between you and Wecobi or follows from a legal obligation.
    Right to lodge a complaint
    You have the right to lodge a complaint with the supervisory authority.
    Swedish Data Protection Authority
    Datainspektionen, Box 8114, 104 20 Stockholm, Sweden
    Phone: +46 8 657 61 00
    Fax: +46 8 652 86 52
    Email: datainspektionen@datainspektionen.se`,
  },
  {
    title: 'Data Processing Agreements',
    text: `Wecobi cooperates with other companies which process Personal Data on behalf of Wecobi.
    Wecobi uses only data processors that provide sufficient guarantees that they implement
    appropriate technical and organisational measures in such a manner that processing will meet
    the requirements of the GDPR and ensure the protection of the rights of all data subjects
    concerned.
    Processing by a data processor is governed by a contract that is binding on the data processor
    with regard to Wecobi and that sets out the subject-matter and duration of the processing, the
    
    nature and purpose of the processing, the type of Personal Data and categories of data subjects
    and the obligations and rights of Wecobi and the data processor.`,
  },
  {
    title: 'The Personal Data We Collect and/or Receive',
    text: `When you sign up to become a User or when you purchase a Product, you may be required to
    provide us with Personal Data about yourself, including, without limitation, your name, phone
    number, e-mail address, and profile picture. The legal basis for such processing is the
    performance of the contract with you. And, if you contact us via the Contact Us page, you’ll need
    to provide your name and other contact information. We don’t collect any Personal Data from
    Visitors or Users when they use the Service, unless they provide such information voluntarily.
    Some of this Personal Data (User name/name, products and/or shops which you have chosen to
    save to your profile, the Users that you follow and the Users that follow you), will be publically
    accessible through the Service, and we may display this publically accessible Personal Data to
    other Users or elsewhere on the Service, by itself or in connection with other Information relating
    to your activities on the Service or with any User Content you provide.
    In addition we may collect or receive additional information. Such other information may be
    collected:
    1. From Your Activity. In an ongoing effort to improve the Service and the user
    experience, we automatically collect certain information when Visitors and Users
    access and use the Service. Such information includes, without limitation, IP
    addresses, browser type and language, referring and exit pages and URLs, date and
    time, amount of time spent on particular pages, Stores followed or reviewed, Products
    bought, what sections of the Website are accessed, what features of the Service are
    used, log files, and similar information and data. The legal basis for the processing of
    personal data is legitimate interest.
    2. From Cookies. We collect information using “cookie” technology. Cookies are small
    packets of data that a website stores on your computer’s hard drive so that your
    computer will “remember” information about your visit. We may use session cookies
    (which expire once you close your web browser), persistent cookies (which stay on
    your computer until you delete them) or equivalent technologies (including but not
    limited to “Local Storage”) to help us collect information and to enhance your
    experience using the Service. If you do not want us to place a cookie on your hard
    drive, you may be able to turn that feature off on your computer. Please consult your
    Internet browser’s documentation for information on how to do this and how to delete
    
    persistent cookies. However, if you decide not to accept cookies from us, the Website
    or the Service may not function properly.
    3. From You. You may voluntarily provide us additional information about yourself that
    does not identify you personally, including, without limitation, your gender, country,
    product and service preferences, and other information.
    
    In order to process any transactions through the Service, such as for a Product or Application,
    you may be required to provide certain billing information, such as information related to your
    PayPal account, debit card number, credit card number, expiration date, bank account
    information, billing address, activation code, and similar information (collectively, the “Billing
    Information”). This Billing Information may be collected and processed by our third-party payment
    vendors (“Payment Platform”) pursuant to the terms and conditions of their privacy policies and
    terms of use. If we obtain access to your Billing Information in connection with those transactions,
    we promise not to share it with any third parties unless it is necessary to process your transaction
    or administer the Service.`,
  },
  {
    title: 'The Personal Data Collected by or Through Third-Party Advertising Companies',
    text: `We may share information mentioned under 1, 2 and 3 in section 6 above about your activity on
    the Website with third parties for the purpose of tailoring, analyzing, managing, reporting, and
    optimizing advertising you see on the Website and elsewhere. These third parties may use
    cookies, pixel tags (also called web beacons or clear gifs), and/or other technologies to collect
    such information for such purposes. Pixel tags enable us, and these third-party advertisers, to
    recognize a browser’s cookie when a browser visits the site on which the pixel tag is located in
    order to learn which advertisement brings a user to a given site.`,
  },
  {
    title: 'How We Use and Share the Personal Data',
    text: `We may use the Personal Data to provide and improve our Website, Service, and other products
    and services; to process transactions you request; to inform you about our products and services
    and those of our promotional partners; and to bring you customized content (such as newsletters)
    based on your activity in connection with the Service.
    We may use and/or share Personal Data as described below.
    
    • We may share the Personal Data concerning our Users with our resellers, distributors, and
    sales representatives, which may use the Information to help inform Users about our products
    and services.
    • We may, from time to time, share Personal Data with other companies, which may provide
    you information about the products and services they or their partners offer. However, to the
    extent required by law, you will be given the opportunity to opt-out of such sharing.
    • We will access, use, and share the Personal Data as required to fulfill our contractual
    obligations to you (legal basis according to the GDPR: performance of a contract) and to
    address your questions or requests regarding our products, services, and/or support. We have
    legitimate interests to process Personal Data in connection with customer service and support
    (legal basis according to the GDPR: legitimate interest).
    • We may employ other companies and individuals to perform functions on our behalf, i.e. data
    processors. Examples may include providing technical assistance, recruiting services, order
    fulfillment, customer service, and marketing assistance. These other companies will have
    access to the Personal Data only as necessary to perform their functions and to the extent
    permitted by law.
    • In an ongoing effort to better understand our Visitors, Users, Website, Service, and other
    products and services and those of our promotional partners, we may analyze certain
    information in anonymized and aggregate form. This aggregate information does not identify
    you personally. We may share and/or license this aggregate data to our affiliates, agents,
    business and promotional partners, and other third parties. We may also disclose aggregated
    user statistics in order to describe the Website and these products and services to current and
    prospective business partners and to other third parties for other lawful purposes.
    • We may share some or all of your Personal Data with any of our parent companies,
    subsidiaries, joint ventures, or other companies under common control with us.
    • As we develop our businesses, we might sell or buy businesses or assets. In the event of a
    corporate sale, merger, reorganization, sale of assets, dissolution, or similar event, the
    Personal Data may be part of the transferred assets.
    • To the extent permitted by law, we may also disclose the Personal Data: (i) when required by
    law, court order, or other government or law enforcement authority or regulatory agency; or (ii)
    whenever we believe that disclosing the Information is necessary or advisable, for example, to
    protect the rights, property, or safety of Wecobi or others.`,
  },
  {
    title: 'Accessing and Modifying Personal Data and Communication Preferences',
    text: `Users may access, remove, review, and/or make changes to their Personal Data by following the
    instructions found on the Website. In addition, you may manage your receipt of marketing and
    non-transactional communications by clicking on the “unsubscribe” link located on the bottom of
    any Wecobi marketing e-mail. We will use commercially reasonable efforts to process such
    requests in a timely manner. You should be aware, however, that it is not always possible to
    completely remove or modify information in our subscription databases. Users and job applicants
    cannot opt out of receiving transactional e-mails related to their account with Wecobi.`,
  },
  {
    title: 'How We Protect the Personal Data',
    text: `We take reasonable steps to protect the Personal Data from loss, misuse, and unauthorized
    access, disclosure, alteration, or destruction. Please understand, however, that no security
    system is impenetrable. We cannot guarantee the security of our databases, nor can we
    guarantee that the Personal Data that you supply will not be intercepted while being transmitted
    to and from us over the Internet. In particular, e-mail sent to or from the Website may not be
    secure, and you should therefore take special care in deciding what information you send to us
    via e-mail.`,
  },
  {
    title: 'Transfer of Personal Data to other countries',
    text: `The Service, the Website and its servers may be operated in the European Union (EU), the
    United States, and elsewhere. Please be aware that your Personal Data, may be transferred to,
    processed, maintained, and used on computers, servers, and systems located outside of your
    state, province, country, or other governmental jurisdiction where the privacy laws may not be as
    protective as those in your jurisdiction.. When Information is transferred from the EU/EEA to a
    third country, i.e. a country outside of the EU/EEA, we take all appropriate legal, technical and
    organisational measures to secure that the level of protection is the same as within the EU/EEA.
    In case Personal Data is processed outside of the EU/EEA the level of protection is guaranteed
    either via a decision of the European Commission that the country in question has an adequate
    level of protection for Personal Data or by using appropriate safety measures such as binding
    corporate rules or Privacy Shield.`,
  },
  {
    title: 'External Websites',
    text: `The Website may contain links to External Sites. Wecobi has no control over the privacy practices
    of External Sites, and we are not responsible for the content or the privacy policies of those
    External Sites. You should check the applicable third-party privacy policy and terms of use when
    visiting any External Sites.`,
  },
  {
    title: 'Children',
    text: `We do not knowingly collect Personal Data from children under the age of 13 through the
    Website. If you are under 13, please do not give us any Personal Data. We encourage parents
    and legal guardians to monitor their children’s Internet usage and to help enforce our Privacy
    Policy by instructing their children never to provide Personal Data through the Website without
    their permission. If you have reason to believe that a child under the age of 13 has provided
    Personal Data to us, please contact us at legal@Wecobi.com, and we will endeavor to delete
    that information from our databases.`,
  },
  {
    title: 'Storage of Personal Data',
    text: `We only store your Personal Data as long as it is necessary for the purposes for which the
    Personal Data are processed.`,
  },
  {
    title: 'Privacy Policy Changes',
    text: `Wecobi may change its Privacy Policy from time to time in Wecobi’s sole discretion. Any such
    changes will be posted on the Website, and Wecobi encourages Visitors to frequently check this
    page. If you have a Store, you should also check your Store’s dashboard for alerts to these
    changes. By accessing the Website or using the Service after we make any changes to this
    Privacy Policy, you accept those changes. Please be aware that, to the extent permitted by
    applicable law, our use of the Information is governed by the Privacy Policy in effect at the time
    we collect the Information.`,
  },
]

const TEXT_PREVIEW = `Your privacy is important to us at Wecobi. (“Wecobi,” “we,” “us,” or “our”), so we’ve created this
  privacy policy (this “Privacy Policy”) to explain Wecobi’s information collection and use practices
  in connection with the Service. You’re bound to the Privacy Policy, too: it’s part of the Agreement,
  and by using the Service you agree that you have read it, that you understand it, and that you will
  comply with it.
  If you see any capitalized terms that aren’t defined in this Privacy Policy, they’ll have the meaning
  we give them elsewhere in the Agreement.`

export const Policy = () => (
  <PageWrapper>
    <GuiderHeader>Privacy and Policy</GuiderHeader>
    <GuideStandaloneText>{TEXT_PREVIEW}</GuideStandaloneText>
    {POLICY_DATA.map(({ title, text }, index) => (
      <GuideRow key={title} index={index + 1} title={title} data={[{ text }]} />
    ))}
  </PageWrapper>
)
