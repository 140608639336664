import React from 'react'

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 0.999998C9.73032 0.999997 1 9.73031 1 20.5C1 31.2695 9.73031 40 20.5 40C31.2697 40 40 31.2695 40 20.5C40 9.73031 31.2697 0.999999 20.5 0.999998Z"
        stroke="#F98140"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.7323 21.6008L17.524 29.0085C16.5385 29.7235 15.1003 29.0706 15.1003 27.9084L15.1011 13.0916C15.1011 11.9289 16.5401 11.2765 17.5248 11.9915L27.7331 19.4012C28.5025 19.9598 28.5025 21.0422 27.7323 21.6008Z"
        stroke="#F98140"
        strokeWidth="2"
      />
    </svg>
  )
}

export const PlayIcon = React.memo(SVGComponent)
