import { COLORS } from '../../config/constants'
import { DisplayIconType, DisplayType } from './DisplaySettings.types'

export const COLOR_ACTIVE_DISPLAY = COLORS.COLOR_DARK_GRAY
export const COLOR_INACTIVE_DISPLAY = COLORS.COLOR_LIGHT_GRAY_2
export const DISPLAYS: Array<DisplayType> = ['vertical', 'grid', 'horizontal']
export const DISPLAY_ICON_NAMES: Array<DisplayIconType> = [
  'display-vertical-filter',
  'display-grid-filter',
  // 'display-horizontal-filter',
]
