import { createContext } from 'react'
import { observable, makeObservable, action } from 'mobx'
import { ICategoryNames } from '../components/Categories/CategoryItem/CategoryItem.types'
import { LocalStorageService } from '../services/core/localStorage.service'

export type CategoriesList = Record<ICategoryNames, boolean>

export const DEFAULT_SETTINGS_CATEGORIES = {
  clothing: false,
  beauty: false,
  games: false,
  electronics: false,
  hobbies: false,
  baby: false,
  home: false,
  travel: false,
  events: false,
}

export class SettingsStoreVm {
  categories: CategoriesList = DEFAULT_SETTINGS_CATEGORIES

  constructor() {
    makeObservable(this, {
      categories: observable,
      updateCategories: action,
    })

    const storedCategories = LocalStorageService.getItem<CategoriesList>('selectedCategories')
    if (storedCategories) {
      this.categories = storedCategories
    }
  }

  public updateCategories = (category: ICategoryNames) => {
    const newCategories = { ...this.categories }
    newCategories[category] = !this.categories[category]
    this.categories = newCategories
    LocalStorageService.setItem('selectedCategories', this.categories)
  }

  public clearCategories = () => {
    this.categories = DEFAULT_SETTINGS_CATEGORIES
    LocalStorageService.removeItem('selectedCategories')
  }
}

export const SettingsStore = createContext(new SettingsStoreVm())
