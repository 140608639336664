import React from 'react'

import CommissionPreviewImage from '../../../../assets/images/home_commission_preview.png'
import {
  Wrapper,
  Title,
  Description,
  ImageWrapper,
  ItemsWrapper,
  ItemWrapper,
  ItemTitle,
  ItemDescription,
} from './CommissionPreview.styles'

const ITEMS_DATA = [
  'Register on wecobi',
  'Share a campaign on your social media',
  'Get commissions for each new purchase thanks to your link',
]

export const CommissionPreview = () => (
  <Wrapper>
    <Title>Earn commissions with your network</Title>
    <Description>Help your network to find a quality product for a good price.</Description>
    <ImageWrapper>
      <img src={CommissionPreviewImage} alt="commission_preview_image" />
      <ItemsWrapper>
        {ITEMS_DATA.map((item, index) => (
          <ItemWrapper key={index}>
            <ItemTitle>{index + 1}.</ItemTitle>
            <ItemDescription>{item}</ItemDescription>
          </ItemWrapper>
        ))}
      </ItemsWrapper>
    </ImageWrapper>
  </Wrapper>
)
