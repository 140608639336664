import styled from 'styled-components'

import { COLORS } from '../../../config/constants'
import { Text } from '../../Text'

interface OrderButtonWrapperProps {
  width: number
  borderColor: string
  backgroundColor?: string
}

export const OrderButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  background-color: ${(p: OrderButtonWrapperProps) => p.backgroundColor || COLORS.COLOR_WHITE};
  width: ${(p: OrderButtonWrapperProps) => p.width}px;
  height: 26px;
  border: 2px solid ${(p: OrderButtonWrapperProps) => p.borderColor};
  border-radius: 20px;
`

export const Title = styled(Text)`
  margin-left: 10px;
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
`
