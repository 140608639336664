import React from 'react'
import { FilterWrapper } from './SearchFilter.style'
import { CategoryItem } from '../../../../components/Categories/CategoryItem/CategoryItem'
import { categories, ICategoryNames } from '../../../../components/Categories/CategoryItem/CategoryItem.types'
import { observer } from 'mobx-react-lite'

interface SearchFilterProps {
  onClick: (attribute: ICategoryNames) => void
  getCategoryStatus: (attribute: ICategoryNames) => boolean
}

export const SearchFilter = observer(({ onClick, getCategoryStatus }: SearchFilterProps) => (
  <FilterWrapper>
    {categories.map((category) => {
      const isActive = getCategoryStatus(category)
      return (
        <CategoryItem
          key={category}
          name={category}
          size="small"
          style={{ marginLeft: '8px' }}
          isActive={isActive}
          onClick={() => onClick(category)}
        />
      )
    })}
  </FilterWrapper>
))
