import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Loader } from '../components/Loader'
import { ProductProps } from '../components/Product/Product.interfaces'
import { UserService } from '../services/entities/user.service'
import { UserStore } from '../store/user.store'

import { OrderExpandable } from '../components/Order/OrderExpandedable/OrderExpandable'
import { AccountSlider } from '../components/AccountSlider/AccountSlider'
import { PageWrapper } from '../containers/PageWrapper/PageWrapper'
import { useHistory } from 'react-router-dom'

export const MyOrders = observer(() => {
  const history = useHistory()
  const { userData } = useContext(UserStore)

  const [campaigns, setCampaigns] = useState<ProductProps[]>([])

  useEffect(() => {
    const fetchOrders = async () => {
      if (userData?.uid) {
        const campaigns = await UserService.getOrders(userData.uid)
        if (campaigns) {
          setCampaigns(campaigns)
        }
      }
    }
    fetchOrders()
  }, [userData])

  return (
    <PageWrapper>
      <AccountSlider
        icon="shop"
        title="My orders"
        onLeftArrow={() => history.push('/account/wishlist')}
        onRightArrow={() => history.push('/account/commissions')}
      />
      {userData ? campaigns.map((order) => <OrderExpandable key={order.id} {...order} />) : <Loader absoluteCenter />}
    </PageWrapper>
  )
})
