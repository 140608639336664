import React from 'react'

import './Login.scss'
import { LogoIcon } from '../../../assets/icons'
import { Title } from '../components'
import { PageWrapper } from '../../../containers/PageWrapper/PageWrapper'
import { Form } from './components/form'
import { Footer } from '../components/Footer'

export const Login = () => {
  return (
    <PageWrapper className="login" navigationAbsolute noFooter>
      <div className="logo__wrapper">
        <div className="logo">
          <LogoIcon />
        </div>
      </div>
      <Title text="Login to" type="login-title" />
      <div className="form__wrapper">
        <Form />
      </div>
      <Footer />
    </PageWrapper>
  )
}
