import React from 'react'
import styled from 'styled-components'

import { COLORS } from '../../../../config/constants'
import { Button } from '../../../../components'

const FooterBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 150px;
  background-color: #f7f7fa;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
`

const UserButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 239px;
  height: 60px;
  background: ${COLORS.COLOR_WHITE};
  border: 1px solid #eeeeee;
  border-radius: 41px;
  color: ${COLORS.COLOR_DARK_GRAY};
  font-weight: 600;
  line-height: 157.2%;
  text-align: center;
  font-family: 'Poppins';
`

const CompanyButton = styled(UserButton)`
  background: 'linear-gradient(270deg, #FCBA69 0%, #F98140 100%)';
  color: ${COLORS.COLOR_BLACK};
`

interface UserFooterBlockProps {
  onCreateCompany: () => void
  onLogout: () => void
}

export const UserFooterBlock = ({ onLogout, onCreateCompany }: UserFooterBlockProps) => (
  <FooterBlock>
    <UserButton onClick={onCreateCompany}>Create company</UserButton>
    <UserButton onClick={onLogout}>Logout</UserButton>
  </FooterBlock>
)

interface CompanyFooterBlockProps {
  onCreateCampaign: () => void
}

export const CompanyFooterBlock = ({ onCreateCampaign }: CompanyFooterBlockProps) => (
  <FooterBlock>
    <CompanyButton onClick={onCreateCampaign}>Create campaign</CompanyButton>
  </FooterBlock>
)
