import React from 'react'
import { observer } from 'mobx-react-lite'
import { AccountSlider } from '../components/AccountSlider/AccountSlider'
import { PageWrapper } from '../containers/PageWrapper/PageWrapper'
import { useHistory } from 'react-router-dom'

export const MyNotifications = observer(() => {
  const history = useHistory()

  return (
    <PageWrapper>
      <AccountSlider icon="bell" title="My notifications" onLeftArrow={() => history.push('/account/messages')} />
    </PageWrapper>
  )
})
