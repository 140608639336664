import React from 'react'

import './Title.scss'
import { TitleStateProps } from './title.interface'

export const Title = ({ text, type }: TitleStateProps) => {
  return (
    <div className={`authentication__title ${type}`}>
      <p>
        {text}
        <span> wecobi</span>
      </p>
    </div>
  )
}
