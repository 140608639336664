import React from 'react'
import { ButtonStateProps } from './mainInfo.interface'
import styled from 'styled-components'
import { COLORS } from '../../../../config/constants'

export const Button = ({ type, children, onClick }: ButtonStateProps) => {
  return (
    <button className={`campaign-mainInfo-btn ${type}`} onClick={onClick}>
      {children}
    </button>
  )
}

export const BoughtButton = styled.div`
  display: flex;
  background-color: ${COLORS.COLOR_LIGHT_GRAY_1};
  margin-right: 5px;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 15px;
  color: ${COLORS.COLOR_BLACK};
  font-weight: bold;
  font-family: 'Poppins';
  border-radius: 4px;
`
