import React from 'react'

import './Arrow.scss'
import { ArrowLeftIcon, ArrowRightBoldIcon } from '../../../../../assets/icons'
import { ArrowBackStateProps, ArrowNextStateProps } from './arrow.interface'
import { FIRST_STAGE_INDEX, LAST_STAGE_INDEX } from '../../../campaignRegistration.config'

export const ArrowBack = ({ onClick, stage }: ArrowBackStateProps) => {
  return (
    <div onClick={onClick} className="footer__arrow-back pointer">
      <div className="icon">
        <ArrowLeftIcon />
      </div>
      <div className="text">{stage === FIRST_STAGE_INDEX ? 'Cancel' : 'Back'}</div>
    </div>
  )
}

export const ArrowNext = ({ onClick, stage }: ArrowNextStateProps) => {
  return (
    <div onClick={onClick} className="footer__arrow-next pointer">
      <div className="text">{stage === LAST_STAGE_INDEX ? 'Finish' : 'Next'}</div>
      <div className="icon">
        <ArrowRightBoldIcon width={12} height={21} />
      </div>
    </div>
  )
}
