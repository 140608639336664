import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={26} height={27} viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.825.25v10.6h10.6a10.6 10.6 0 00-10.6-10.6zm8.374 18.404a10.53 10.53 0 002.226-6.479H6.133l-1.245-2.65H.25v2.65h2.942S5.696 17.568 6 18.032a4.607 4.607 0 00-2.438 4.08A4.638 4.638 0 008.2 26.75a4.629 4.629 0 004.585-3.975h2.755a4.629 4.629 0 004.585 3.975 4.638 4.638 0 004.637-4.637c0-1.378-.609-2.61-1.563-3.459zM8.2 24.1a1.988 1.988 0 110-3.975 1.988 1.988 0 010 3.975zm11.925 0a1.988 1.988 0 110-3.976 1.988 1.988 0 010 3.976z"
        fill={props.color || '#FF6D0A'}
      />
    </svg>
  )
}

export const BabysitterIcon = React.memo(SvgComponent)
