import React from 'react'
import Collapsible from 'react-collapsible'
import styled from 'styled-components'
import { Text } from '../../../components'
import { COLORS } from '../../../config/constants'

interface GuideRowProps {
  index: number
  title: string
  data: {
    title?: string
    text?: string
  }[]
}

const Container = styled.div`
  background-color: ${COLORS.BACKGROUND_COLOR};
`

const GuideTitle = styled.div`
  display: flex;
  width: 100%;
  height: 97px;
  align-items: center;
  background-color: #fff;
  border: 1px solid #eee;
  border-top: 0;
  padding-left: 40px;
`

const GuideTitleIndex = styled(Text)`
  font-weight: 700;
  font-size: 24px;
  line-height: 125.7%;
`

const GuideTitleText = styled(Text)`
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.41px;
  margin-left: 12px;
  line-clamp: 1;
`

export const GuideStandaloneText = styled(GuideTitleText)`
  background-color: ${COLORS.BACKGROUND_COLOR};
  margin: 0;
  padding: 0 20px 20px 20px;
`

const GuideCollapsible = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 30px;
  border-bottom: 1px solid #d5d5d5;
`

const GuideCollapsibleTitle = styled(Text)`
  font-weight: 500;
  font-size: 16px;
  line-height: 157.2%;
`

const GuideCollapsibleText = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  line-height: 157.2%;
`

export const GuideRow = ({ index, title, data }: GuideRowProps) => (
  <Container>
    <Collapsible
      trigger={
        <GuideTitle>
          <GuideTitleIndex>{`${index}.`}</GuideTitleIndex>
          <GuideTitleText>{title}</GuideTitleText>
        </GuideTitle>
      }>
      {data.map(({ title, text }) => (
        <GuideCollapsible key={title}>
          <GuideCollapsibleTitle>{title}</GuideCollapsibleTitle>
          {text && <GuideCollapsibleText>{text}</GuideCollapsibleText>}
        </GuideCollapsible>
      ))}
    </Collapsible>
  </Container>
)
