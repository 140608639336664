import React, { useMemo } from 'react'
import { Wrapper, Location, Country, Display } from './DisplaySettings.style'
import { Icon } from '../Icon/Icon'
import { COLORS } from '../../config/constants'
import { COLOR_ACTIVE_DISPLAY, COLOR_INACTIVE_DISPLAY, DISPLAY_ICON_NAMES, DISPLAYS } from './DisplaySettings.config'
import { computeActiveDisplay } from './DisplaySettings.service'
import { DisplayType } from './DisplaySettings.types'

interface DisplaySettingsProps {
  country: string
  display: DisplayType
  setDisplay: (displayType: DisplayType) => void
  disableCountry?: boolean
}

export const DisplaySettings = ({ country, display, setDisplay, disableCountry }: DisplaySettingsProps) => {
  const isActive = useMemo(() => computeActiveDisplay(display), [display])

  return (
    <Wrapper disableCountry={disableCountry}>
      {!disableCountry && (
        <Location>
          <Icon name="location" color={COLORS.COLOR_DARK_GRAY} />
          <Country title={country}>{country}</Country>
        </Location>
      )}
      {/* <Filter>
        <Icon name="sort" pointer />
      </Filter> */}
      <Display>
        {DISPLAY_ICON_NAMES.map((disp, index) => {
          return (
            <Icon
              key={disp}
              name={disp}
              color={isActive[index] ? COLOR_ACTIVE_DISPLAY : COLOR_INACTIVE_DISPLAY}
              onClick={() => setDisplay(DISPLAYS[index])}
              pointer
            />
          )
        })}
      </Display>
    </Wrapper>
  )
}
