import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { PageWrapper } from '../../../containers/PageWrapper/PageWrapper'

import { FollowersHeader } from '../shared/components/Header'
import { Follower } from '../shared/components/Follower'
import { UserStore } from '../../../store/user.store'
import { FollowingVm } from '../Following.vm'

export const Followers = observer(() => {
  const history = useHistory()
  const { userData, followUser, unfollowUser } = useContext(UserStore)
  const [{ users, fetchFollowings }] = useState(new FollowingVm())

  useEffect(() => {
    const fetchFollowers = async () => {
      if (userData?.uid) {
        fetchFollowings(userData.followers)
      }
    }
    fetchFollowers()
  }, [userData?.uid, userData?.followers, userData?.followers?.length, fetchFollowings])

  const onAccessoryClick = () => history.push('/account/following')

  const onFollow = async (uid: string, type: 'follow' | 'unfollow') => {
    if (type === 'follow') {
      await followUser(uid)
    } else {
      await unfollowUser(uid)
    }
  }

  return (
    <PageWrapper noFooter>
      <FollowersHeader onAccessoryClick={onAccessoryClick} title="My followers" />
      {users.map(({ uid, avatar, name, username }) => {
        const isFollowing = userData?.following?.includes(uid) || false
        return (
          <Follower
            avatar={avatar}
            name={name}
            username={username}
            isFollowed={isFollowing}
            onFollow={() => onFollow(uid, isFollowing ? 'unfollow' : 'follow')}
            key={uid}
          />
        )
      })}
    </PageWrapper>
  )
})
