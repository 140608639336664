import React from 'react'

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.8 0C9.86869 0 11.8526 0.782651 13.3154 2.17578C14.7782 3.56891 15.6 5.45839 15.6 7.42857C15.6 9.26857 14.892 10.96 13.728 12.2629L14.052 12.5714H15L21 18.2857L19.2 20L13.2 14.2857V13.3829L12.876 13.0743C11.508 14.1829 9.732 14.8571 7.8 14.8571C5.73131 14.8571 3.74735 14.0745 2.28457 12.6814C0.821783 11.2882 0 9.39875 0 7.42857C0 5.45839 0.821783 3.56891 2.28457 2.17578C3.74735 0.782651 5.73131 0 7.8 0ZM7.8 2.28571C4.8 2.28571 2.4 4.57143 2.4 7.42857C2.4 10.2857 4.8 12.5714 7.8 12.5714C10.8 12.5714 13.2 10.2857 13.2 7.42857C13.2 4.57143 10.8 2.28571 7.8 2.28571Z"
        fill={props.color || '#FDFDFD'}
      />
    </svg>
  )
}

export const SearchIcon = React.memo(SVGComponent)
