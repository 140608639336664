import React from 'react'

import { Product } from '../../Product/Product'
import { ProductProps } from '../../Product/Product.interfaces'
import {
  DisplayGridWrapper,
  DisplayGridProductWrapper,
  DisplayVerticalProductWrapper,
  DisplayVerticalWrapper,
  // DisplayHorizontalWrapper,
} from './displays.style'
// import { CompanyProducts } from '../../../pages/Search/components/CompanyProduct/CompanyProducts'
// import logo from '../../../assets/images/test_company_logo_1.svg'

interface DisplayProductProps {
  products: ProductProps[]
}

export const DisplayGrid = ({ products }: DisplayProductProps) => (
  <DisplayGridWrapper>
    {products.map((product, index) => (
      <DisplayGridProductWrapper key={index}>
        <Product {...product} size="small" />
      </DisplayGridProductWrapper>
    ))}
  </DisplayGridWrapper>
)

export const DisplayVertical = ({ products }: DisplayProductProps) => (
  <DisplayVerticalWrapper>
    {products.map((product, index) => (
      <DisplayVerticalProductWrapper key={index}>
        <Product {...product} size="large" progressBarHeight={13} />
      </DisplayVerticalProductWrapper>
    ))}
  </DisplayVerticalWrapper>
)

// export const DisplayHorizontal = () => {
//   return (
//     <DisplayHorizontalWrapper>
//       {companyProducts.map((company, index) => (
//         <CompanyProducts key={index} logo={logo} products={company} title="Peak Performance" />
//       ))}
//     </DisplayHorizontalWrapper>
//   )
// }
