import React, { useState } from 'react'
import { useHistory } from 'react-router'

import './Footer.scss'
import { ButtonSocialNetwork } from '../Button'
import { GoogleIcon } from '../../../../assets/icons'
import { useAuthContext } from '../../../../contexts/authContext'

export const Footer = () => {
  const history = useHistory()
  const [loginError, setLoginError] = useState('')
  const { googleAuth } = useAuthContext()!

  async function onSubmit() {
    try {
      await googleAuth()
      history.push('/')
    } catch {
      setLoginError('Failed to Sign-in with Google account')
    }
  }

  return (
    <footer className="authentication_footer">
      <div className="subtitle">Or use your phone or social accounts</div>
      <div className="socials">
        <ButtonSocialNetwork className="black">
          <GoogleIcon onClick={() => onSubmit()} />
        </ButtonSocialNetwork>
      </div>
      {loginError && <p>{loginError}</p>}
    </footer>
  )
}
