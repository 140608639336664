import React from 'react'

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '17'}
      height={props.height || '27'}
      viewBox="0 0 17 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M14.5042 0H2.49592C2.30858 0 2.12891 0.105357 1.99644 0.292893C1.86397 0.48043 1.78955 0.734784 1.78955 1V3C1.78955 3.26522 1.86397 3.51957 1.99644 3.70711C2.12891 3.89464 2.30858 4 2.49592 4H14.5042C14.6916 4 14.8712 3.89464 15.0037 3.70711C15.1362 3.51957 15.2106 3.26522 15.2106 3V1C15.2106 0.734784 15.1362 0.48043 15.0037 0.292893C14.8712 0.105357 14.6916 0 14.5042 0ZM14.5042 23H2.49592C2.30858 23 2.12891 23.1054 1.99644 23.2929C1.86397 23.4804 1.78955 23.7348 1.78955 24V26C1.78955 26.2652 1.86397 26.5196 1.99644 26.7071C2.12891 26.8946 2.30858 27 2.49592 27H14.5042C14.6916 27 14.8712 26.8946 15.0037 26.7071C15.1362 26.5196 15.2106 26.2652 15.2106 26V24C15.2106 23.7348 15.1362 23.4804 15.0037 23.2929C14.8712 23.1054 14.6916 23 14.5042 23Z"
        fill={props.color || '#D5D5D5'}
      />
      <path
        d="M16.1053 6H0.894737C0.657438 6 0.429858 6.10536 0.262062 6.29289C0.0942666 6.48043 0 6.73478 0 7V20C0 20.2652 0.0942666 20.5196 0.262062 20.7071C0.429858 20.8946 0.657438 21 0.894737 21H16.1053C16.3426 21 16.5701 20.8946 16.7379 20.7071C16.9057 20.5196 17 20.2652 17 20V7C17 6.73478 16.9057 6.48043 16.7379 6.29289C16.5701 6.10536 16.3426 6 16.1053 6Z"
        fill={props.color || '#D5D5D5'}
      />
    </svg>
  )
}

export const DisplayVerticalFilterIcon = React.memo(SVGComponent)
