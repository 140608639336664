import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={16} viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.5 5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm11.91 2.58c.36.36.59.86.59 1.42 0 .55-.22 1.05-.59 1.41l-5 5a1.996 1.996 0 01-2.83 0L.59 8.42C.22 8.05 0 7.55 0 7V0h7c.55 0 1.05.22 1.41.58l7 7zm-3.87-5.87a1.414 1.414 0 012 0l5.87 5.87c.37.36.59.87.59 1.42 0 .55-.22 1.05-.58 1.41l-4.38 4.38a1.414 1.414 0 01-2 0l4.323-4.384a2 2 0 00-.002-2.81L11.54 1.71z"
        fill="#434343"
      />
      <path
        d="M23.41 7.58l-5.87-5.87a1.414 1.414 0 00-2 0l5.821 5.886a2 2 0 01.002 2.81L17.04 14.79a1.414 1.414 0 002 0l4.38-4.38c.36-.36.58-.86.58-1.41 0-.55-.22-1.06-.59-1.42z"
        fill="#434343"
      />
    </svg>
  )
}

export const PriceTagIcon = React.memo(SvgComponent)
