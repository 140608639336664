import React from 'react'

import { Icon } from '../../../../../components'

import { Container, ImageWrapper, NameWrapper, IconWrapper, NameText, NicknameText } from './AvatarContainer.styles'

interface AvatarContainerProps {
  image: string
  name: string
  nickname?: string
  onArrow?: () => void
  onImage?: () => void
}

export const AvatarContainer = ({ image, name, nickname, onArrow, onImage }: AvatarContainerProps) => (
  <Container>
    <ImageWrapper onClick={onImage}>
      <img src={image} alt="avatar" />
    </ImageWrapper>
    <NameWrapper>
      <NameText>{name}</NameText>
      {nickname && <NicknameText>{nickname}</NicknameText>}
    </NameWrapper>
    <IconWrapper onClick={onArrow}>
      <Icon name="arrow-down" width={14} height={9} pointer />
    </IconWrapper>
  </Container>
)
