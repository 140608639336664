import React, { ChangeEvent } from 'react'
import { Icon } from '../..'
import './Input.scss'
import { COLORS } from '../../../config/constants'
import { Wrapper } from '../Wrapper/Wrapper'
import { Spacings } from '../../../styles/spacings/Spacings'

export interface InputProps extends Spacings {
  showOkIcon?: boolean
  showErrorIcon?: boolean
  error?: boolean
  type?: string
  value?: string
  disabled?: boolean
  placeholder?: string
  onChangeText?: (event: ChangeEvent<HTMLInputElement>) => void
}

export const Input = (props: InputProps) => {
  const {
    showOkIcon,
    showErrorIcon,
    error,
    value,
    disabled,
    type = 'text',
    placeholder,
    onChangeText,
    ...otherProps
  } = props

  return (
    <Wrapper className="simple__input" {...otherProps}>
      {error && <p className="error__text">{error}</p>}
      <input
        placeholder={placeholder}
        disabled={disabled}
        type={type}
        value={value}
        className={`input__field ${disabled && 'is__disabled'}`}
        onChange={onChangeText}
        {...otherProps}
      />
      {showOkIcon && <Icon name="correct-tick" />}
      {showErrorIcon && <Icon color={COLORS.COLOR_ORANGE_1} name="cross" />}
    </Wrapper>
  )
}
