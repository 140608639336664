import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={props.width || 20}
      height={props.height || 21}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.71 5.167a3.182 3.182 0 00-6.33 0h6.33zm-8.154 0a5 5 0 019.979 0h1.391c.694 0 1.283.51 1.38 1.199l1.771 12.545a1.394 1.394 0 01-1.38 1.589H1.394a1.394 1.394 0 01-1.38-1.582l1.71-12.546a1.393 1.393 0 011.38-1.205h1.453zm-.01 2.151c0-.258.217-.454.475-.454h.919c.24 0 .424.213.424.454a3.182 3.182 0 006.363 0c0-.24.183-.454.424-.454h.919c.258 0 .476.196.476.454a5 5 0 01-10 0z"
        fill={props.color || '#434343'}
      />
    </svg>
  )
}

export const ShopIcon = React.memo(SvgComponent)
