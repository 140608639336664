import styled, { CSSObject } from 'styled-components'
import { WINDOW } from '../../config/constants'

export const WindowWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export interface WrapperProps {
  styles?: CSSObject
}

export const Wrapper = styled.div`
  width: 100%;
  max-width: ${WINDOW.MAX_WIDTH}px;
  position: relative;
  ${(p: WrapperProps) => p.styles}
`
