import { ICategoryNames } from '../../../../components/Categories/CategoryItem/CategoryItem.types'

export const categorySizes: { [key in ICategoryNames]: 'small' | 'large' } = {
  baby: 'small',
  hobbies: 'small',
  beauty: 'small',
  electronics: 'small',
  travel: 'small',
  home: 'small',
  games: 'small',
  events: 'small',
  clothing: 'large',
}
