import styled from 'styled-components'

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 14px;
  padding-top: 50px;
  height: 140px;
  background: linear-gradient(270deg, #eb4dbf 0%, #f98140 100%);
  box-shadow: -2px 2px 12px rgba(0, 0, 0, 0.13);
  border-radius: 8px;
`
