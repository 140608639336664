import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={15} viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.588 7.059h6.177v1.323h-6.177V7.06zm0-2.206h6.177v1.323h-6.177V4.853zm0 4.412h6.177v1.323h-6.177V9.265zM17.648 0H1.764A1.765 1.765 0 000 1.765v11.47A1.765 1.765 0 001.765 15h15.882a1.765 1.765 0 001.765-1.765V1.765A1.765 1.765 0 0017.647 0zm0 13.235H9.705V1.765h7.941"
        fill="#6B717A"
      />
    </svg>
  )
}

export const BookIcon = React.memo(SvgComponent)
