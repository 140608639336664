import React, { useContext, useState } from 'react'

import DummyAvatar from '../../../assets/images/DefaultAccountAvatar.svg'

import { DrawerWrapper, ItemsWrapper, MainItems, SecondaryItems } from './DrawerContent.styles'
import { AvatarContainer } from './components/AvatarContainer/AvatarContainer'
import { DrawerItem } from './components/DrawerItem/DrawerItem'
import { CompanyFooterBlock } from './components/FooterBlock'
import { companyContent } from './DrawerContent.config'
import { UserStore } from '../../../store/user.store'
import { CompanyPopupPicker } from '../../CompanyPopupPicker/CompanyPopupPicker'
import { CompanyStore } from '../../../store/company.store'
import { useHistory } from 'react-router-dom'

export const CompanyDrawerContent = () => {
  const { mainItems, secondaryItems } = companyContent
  const { userData } = useContext(UserStore)
  const { companyData } = useContext(CompanyStore)
  const [isCompanyPickerOpen, setIsCompanyPickerOpen] = useState(false)
  const history = useHistory()

  const closePicker = () => setIsCompanyPickerOpen(false)

  const onCreateCampaign = () => history.push('/campaign-registration')

  const onImage = () => history.push('/account/brand')

  return (
    <DrawerWrapper>
      {userData?.uid && (
        <CompanyPopupPicker open={isCompanyPickerOpen} onClose={closePicker} forceClose={closePicker} />
      )}
      <AvatarContainer
        image={companyData?.logo || DummyAvatar}
        name={companyData?.companyName || 'Error'}
        onArrow={() => setIsCompanyPickerOpen(true)}
        onImage={onImage}
      />
      <ItemsWrapper>
        <MainItems>
          {mainItems.map((item) => (
            <DrawerItem key={item.title} {...item} borderBottom />
          ))}
        </MainItems>
        {secondaryItems && (
          <SecondaryItems>
            {secondaryItems.map((item) => (
              <DrawerItem key={item.title} {...item} type="secondary" borderTop />
            ))}
          </SecondaryItems>
        )}
      </ItemsWrapper>
      <CompanyFooterBlock onCreateCampaign={onCreateCampaign} />
    </DrawerWrapper>
  )
}
