import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AccountSlider } from '../components/AccountSlider/AccountSlider'

import { DisplaySettings } from '../components/DisplaySettings/DisplaySettings'
import { DisplayType } from '../components/DisplaySettings/DisplaySettings.types'
import { ProductList } from '../components/DisplaySettings/ProductList'
import { Loader } from '../components/Loader'
import { ProductProps } from '../components/Product/Product.interfaces'
import { PageWrapper } from '../containers/PageWrapper/PageWrapper'
import { UserService } from '../services/entities/user.service'
import { UserStore } from '../store/user.store'

export const MyWishlist = observer(() => {
  const history = useHistory()
  const { userData } = useContext(UserStore)

  const [display, setDisplay] = useState<DisplayType>('vertical')
  const [campaigns, setCampaigns] = useState<ProductProps[]>([])

  useEffect(() => {
    const fetchLikedCampaigns = async () => {
      if (userData?.uid) {
        const campaigns = await UserService.getLikedCampaigns(userData?.uid)
        setCampaigns(campaigns.map((campaign) => ({ ...campaign, isLiked: true })))
      }
    }
    fetchLikedCampaigns()
  }, [userData])

  return (
    <PageWrapper>
      <AccountSlider icon="heart" title="My wishlist" onRightArrow={() => history.push('/account/orders')} />
      <DisplaySettings country="All locations" display={display} setDisplay={setDisplay} />
      {userData ? <ProductList display={display} products={campaigns} /> : <Loader absoluteCenter />}
    </PageWrapper>
  )
})
