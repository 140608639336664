import React, { ChangeEvent } from 'react'

import './Rate.scss'
import { InputNumber } from '../../../../components/Input/Input'

interface RateProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  value?: string
}

export const Rate = ({ onChange, value }: RateProps) => {
  return (
    <div className="commission__rate">
      <div className="title">Set a commission rate</div>
      <div className="description">
        Wecobi allows you to buy and sell Goods & Services in a way that lowers the prices for the buyer and increases
        the profits for the seller and saves the environment in the process.
      </div>
      <div className="input-number">
        <InputNumber type="commission" measure="%" onChange={onChange} value={value} />
      </div>
    </div>
  )
}
