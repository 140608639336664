import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'

import { NavigationPlug, Wrapper } from './Navigation.styles'
import { Icon } from '../Icon/Icon'

import { UserDrawerContent } from './DrawerContent/UserDrawerContent'
import { observer } from 'mobx-react-lite'
import { UserStore } from '../../store/user.store'
import { CompanyDrawerContent } from './DrawerContent/CompanyDrawerContent'

const ICON_COLOR = '#000'
const NAVIGATION_HEIGHT = 64

interface NavigationProps {
  absolute?: boolean
}

export const Navigation = observer(({ absolute = false }: NavigationProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { userData } = useContext(UserStore)

  const renderDrawer = () => {
    if (userData) {
      if (userData.currentCompany) {
        return <CompanyDrawerContent />
      } else {
        return <UserDrawerContent />
      }
    }
  }

  return (
    <>
      <Wrapper height={NAVIGATION_HEIGHT}>
        {userData ? (
          <Icon name="burger" width={23} height={17} color={ICON_COLOR} pointer onClick={() => setIsOpen(true)} />
        ) : (
          <div />
        )}
        <Link to="/">
          <Icon name="wecobi" />
        </Link>
        <Link to="/search">
          <Icon name="search" width={23} height={22} color={ICON_COLOR} pointer />
        </Link>
      </Wrapper>
      {!!userData && (
        <Drawer anchor="left" open={isOpen} onClose={() => setIsOpen(false)}>
          {renderDrawer()}
        </Drawer>
      )}
      {!absolute && <NavigationPlug height={NAVIGATION_HEIGHT} />}
    </>
  )
})
