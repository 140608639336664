import React, { useContext } from 'react'
import styled from 'styled-components'
import Collapsible from 'react-collapsible'

import { OrderCollapsed } from '../OrderCollapsed/OrderCollapsed'
import { OrderExpandedActive, CampaignExpandedActive } from './OrderExpandedActive/OrderExpandedActive'
import { OrderExpandedFinished, CampaignExpandedFinished } from './OrderExpandedFinished/OrderExpandedFinished'
import { OrderExpandedCancelled, CampaignExpandedCancelled } from './OrderExpandedCancelled/OrderExpandedCancelled'
import { Campaign } from '../../../types/company.types'
import { CompanyStore } from '../../../store/company.store'
import { observer } from 'mobx-react-lite'
import { CampaignService } from '../../../services/entities/campaign.service'
import { useHistory } from 'react-router-dom'

const ExpandedWrapper = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 23px;
`

export const OrderExpandable = ({ status, ...item }: Campaign) => {
  const renderExpandedContent = () => {
    switch (status) {
      case 'active':
        return <OrderExpandedActive {...item} />
      case 'finished':
        return <OrderExpandedFinished {...item} />
      case 'cancelled':
        return <OrderExpandedCancelled {...item} />
    }
  }

  return (
    <Collapsible trigger={<OrderCollapsed {...item} status={status} />}>
      <ExpandedWrapper>{renderExpandedContent()}</ExpandedWrapper>
    </Collapsible>
  )
}

export const CampaignExpandable = observer(({ status, ...item }: Campaign) => {
  const { fetchCampaigns } = useContext(CompanyStore)
  const history = useHistory()

  const onEdit = () => {
    history.push(`/edit/${item.id}`)
  }

  const onCancel = async () => {
    await CampaignService.cancelCampaign(item.id)
    await fetchCampaigns()
  }

  const onRenew = async () => {
    await CampaignService.activateCampaign(item.id)
    await fetchCampaigns()
  }

  const renderExpandedContent = () => {
    switch (status) {
      case 'active':
        return <CampaignExpandedActive {...item} onEdit={onEdit} onCancel={onCancel} />
      case 'finished':
        return <CampaignExpandedFinished {...item} />
      case 'cancelled':
        return <CampaignExpandedCancelled {...item} onRenew={onRenew} />
    }
  }

  return (
    <Collapsible trigger={<OrderCollapsed {...item} status={status} />}>
      <ExpandedWrapper>{renderExpandedContent()}</ExpandedWrapper>
    </Collapsible>
  )
})
