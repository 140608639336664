import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import './App.scss'
import { AuthProvider } from '../contexts/authContext'
import { Navigation } from '../navigation'
import { ModalProvider } from '../contexts/modal/modal'

const stripePromise = loadStripe(
  'pk_test_51JJgAeFgmpZVZ0aILiTUt3oBooZULhBHuoekbdof1FqFJEd3Bzh0fORLukk32TVuclZmrWQQZLXht889krO2xVgX00sT3LZZWp'
)

export const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <AuthProvider>
        <ModalProvider>
          <Navigation />
        </ModalProvider>
      </AuthProvider>
    </Elements>
  )
}
