import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={35} height={28} viewBox="0 0 35 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.725 3.785L16.248 5.16l1.387-2.455L16.248.25l2.477 1.375L21.203.25l-1.387 2.455 1.387 2.455-2.478-1.375zm9.04 20.12l2.478-1.375-1.387 2.455 1.387 2.455-2.477-1.375-2.478 1.375 1.387-2.455-1.387-2.455 2.477 1.375zM5.732 13.255L4.343 15.71l1.388 2.455-2.478-1.375-2.478 1.375 1.388-2.455-1.388-2.455 2.478 1.375 2.478-1.375zM34.24 3.298l-1.775-1.776c-.49-.516-1.351-.516-1.868 0L18.725 13.394l3.644 3.644L34.24 5.166c.517-.517.517-1.378 0-1.868zM16.076 15.713a3.975 3.975 0 00-3.975 3.975c0 1.736-1.537 2.65-2.65 2.65 1.22 1.617 3.313 2.65 5.3 2.65a5.3 5.3 0 005.3-5.3 3.975 3.975 0 00-3.975-3.975z"
        fill={props.color || '#FF6D0A'}
      />
    </svg>
  )
}

export const BrushIcon = React.memo(SvgComponent)
