import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { remove } from 'lodash'
import { observer } from 'mobx-react-lite'

import { PageWrapper } from '../../../containers/PageWrapper/PageWrapper'

import { FollowingHeader } from '../shared/components/Header'
import { Follower } from '../shared/components/Follower'
import { UserStore } from '../../../store/user.store'
import { FollowingVm } from '../Following.vm'

export const Followings = observer(() => {
  const history = useHistory()

  const { userData, unfollowUser } = useContext(UserStore)
  const [{ users, companies, setUsers, fetchFollowings }] = useState(new FollowingVm())

  useEffect(() => {
    const fetchFollowers = async () => {
      if (userData?.uid) {
        fetchFollowings(userData.following, userData.followingCompanies)
      }
    }
    fetchFollowers()
  }, [userData?.uid, userData?.following, userData?.following?.length, fetchFollowings, userData?.followingCompanies])

  const onAccessoryClick = () => history.push('/account/followers')

  const onUnfollow = async (uid: string) => {
    await unfollowUser(uid)
    setUsers(remove([...users], (following) => following.uid !== uid))
  }

  return (
    <PageWrapper>
      <FollowingHeader onAccessoryClick={onAccessoryClick} title="I am following" />
      {users.map(({ uid, avatar, name, username }) => (
        <Follower
          avatar={avatar}
          name={name}
          username={username}
          isFollowed
          onFollow={() => onUnfollow(uid)}
          key={uid}
        />
      ))}
      {companies.map(({ id, companyName, companyUsername, logo }) => (
        <Follower
          key={id}
          name={companyName}
          username={companyUsername}
          avatar={logo}
          onClick={() => history.push(`/brand/${id}`)}
        />
      ))}
    </PageWrapper>
  )
})
