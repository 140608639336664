import React from 'react'
import { Link } from 'react-router-dom'

interface NavigationElementProps {
  active: string
  id: string
  text: string
  onChange: (event: React.MouseEvent<HTMLDivElement>) => void
}

export const NavigationElement = ({ active, id, text, onChange }: NavigationElementProps) => (
  <Link to={`/product/${id}`} className={`link nav-elem ${active === id ? 'active' : ''}`}>
    <div
      id={id}
      // className={`nav-elem ${+active === id ? 'active' : ''}`}
      onClick={(e) => onChange(e)}>
      {text}
    </div>
  </Link>
)
