import React from 'react'

import { ProductsSlider, ProductWrapper } from './ProductsSlider.styles'
import { ProductOutlined } from '../../../../components'
import { ProductProps } from '../../../../components/Product/Product.interfaces'

interface ProductSliderProps {
  products: ProductProps[]
}

export const ProductSlider = ({ products }: ProductSliderProps) => (
  <ProductsSlider>
    {products.map((product, index) => (
      <ProductWrapper key={index}>
        <ProductOutlined {...product} />
      </ProductWrapper>
    ))}
  </ProductsSlider>
)
