import * as React from 'react'

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={18} height={5} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.5 2.25a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Zm-6.75 0a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0ZM0 2.25a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Z"
      fill={props.color || '#6B717A'}
    />
  </svg>
)

export const DotsIcon = React.memo(SvgComponent)
