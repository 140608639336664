import React from 'react'

import './Input.scss'
import { InputStateProps, TextareaInputStateProps } from './Input.interfaces'

export const Input = ({ label, type = 'text', register, error }: InputStateProps) => {
  return (
    <div className="authentication__input">
      <div className="label">
        {label}
        <p className="errorText">{error}</p>
      </div>
      <input type={type} className="inputField" {...register} />
    </div>
  )
}

export const TextareaInput = ({ label, register, error }: TextareaInputStateProps) => {
  return (
    <div className="authentication__textarea-input">
      <div className="label">
        {label}
        <p className="errorText">{error}</p>
      </div>
      <textarea className="textarea-inputField" {...register} />
    </div>
  )
}
