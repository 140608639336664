import styled, { CSSObject } from 'styled-components'

interface ContainerProps {
  size: number
  color: string
}

interface WrapperProps {
  style?: CSSObject
}

interface TextProps {
  size: number
}

export const Wrapper = styled.div`
  padding: 0 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(p: WrapperProps) => p.style};
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(p: ContainerProps) => p.size}px;
  height: ${(p: ContainerProps) => p.size}px;
  background-color: ${(p: ContainerProps) => p.color};
  border-radius: 25px;
  box-shadow: -2px 2px 12px rgba(0, 0, 0, 0.13);
  cursor: pointer;
`

export const TextWrapper = styled.div`
  width: ${(p: TextProps) => p.size}px;
  text-align: center;
  margin-top: 11px;
`
