import { action, makeObservable, observable } from 'mobx'
import { DisplayType } from '../../../components/DisplaySettings/DisplaySettings.types'
import { FirestoreService } from '../../../services/core/firestore.service'
import { UserService } from '../../../services/entities/user.service'
import { Campaign } from '../../../types/company.types'
import { User } from '../../../types/user.types'
import { Activity } from '../shared/AccountInfo/AccountInfo.interface'

export class AccountVm {
  display: DisplayType = 'vertical'
  sliderIndex = 0
  user?: User = undefined
  username?: string = undefined
  isFollowed = false
  userActivity: Activity = {
    followers: 0,
    following: 0,
    shares: 0,
  }
  wishlist: Campaign[] = []
  orders: Campaign[] = []

  constructor(username: string) {
    this.username = username

    makeObservable(this, {
      display: observable,
      sliderIndex: observable,
      user: observable,
      username: observable,
      isFollowed: observable,
      userActivity: observable,
      wishlist: observable,
      orders: observable,
      setDisplay: action,
      setSliderIndex: action,
      setUser: action,
      setIsFollowed: action,
      setUserActivity: action,
      setWishlist: action,
      setOrders: action,
    })
  }

  public setWishlist = (wishlist: Campaign[]) => {
    this.wishlist = wishlist
  }

  public setOrders = (orders: Campaign[]) => {
    this.orders = orders
  }

  public setDisplay = (display: DisplayType) => {
    this.display = display
  }

  public setSliderIndex = (sliderIndex: number) => {
    this.sliderIndex = sliderIndex
  }

  public setUser = (user: User) => {
    this.user = user
  }

  private fetchWishlist = async () => {
    if (this.user?.liked && this.user.liked.length > 0) {
      this.setWishlist(
        await Promise.all(
          this.user.liked.map(async (id) => await (await FirestoreService.getCampaignById(id)).campaignData)
        )
      )
    }
  }

  private fetchOrders = async () => {
    if (this.user?.orders && this.user.orders.length > 0) {
      this.setOrders(
        await Promise.all(
          this.user.orders.map(
            async ({ campaignId }) => await (await FirestoreService.getCampaignById(campaignId)).campaignData
          )
        )
      )
    }
  }

  public fetchUser = async (uid: string) => {
    if (this.username) {
      const { userData, userRef } = await FirestoreService.getUserByUsername(this.username)
      if (userData) {
        this.setUser({ ...userData, uid: userRef.id })
        this.setIsFollowed(!!this.user?.followers?.includes(uid))
        this.setUserActivity(UserService.getUserActivity(userData))
        await this.fetchWishlist()
        await this.fetchOrders()
        return userData
      }
    }
    return null
  }

  public setIsFollowed = (value: boolean) => {
    this.isFollowed = value
  }

  public setUserActivity = (value: Activity) => {
    this.userActivity = value
  }
}
