import React from 'react'
import styled from 'styled-components'
import { Text } from '../../components'

import { PageWrapper } from '../../containers/PageWrapper/PageWrapper'

const Header = styled(Text)`
  font-size: 50px;
  text-align: center;
`

const Email = styled(Text)`
  font-size: 25px;
  text-align: center;
`

export const About = () => (
  <PageWrapper>
    <Header>Wecobi</Header>
    <Email>wecobi@gmail.com</Email>
  </PageWrapper>
)
