import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={15} height={19} viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.118 14a3.086 3.086 0 01-.883-2.16V8.15c0-2.545-1.601-4.708-3.826-5.512-.337-.122-.585-.429-.585-.788 0-.358-.14-.701-.388-.955A1.31 1.31 0 007.5.5a1.31 1.31 0 00-.936.395 1.364 1.364 0 00-.388.955c0 .36-.248.666-.586.788-2.23.803-3.825 2.967-3.825 5.512v3.69c0 .808-.317 1.583-.883 2.16l-.252.257C.226 14.67 0 15.223 0 15.8h15c0-.577-.226-1.13-.63-1.543L14.118 14zM7.5 18.5a1.747 1.747 0 001.498-.849c.288-.471-.181-.951-.733-.951h-1.53c-.552 0-1.02.48-.733.951A1.747 1.747 0 007.5 18.5z"
        fill={props.color || '#434343'}
      />
    </svg>
  )
}

export const BellIcon = React.memo(SvgComponent)
