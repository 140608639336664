import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { PageWrapper } from '../../containers/PageWrapper/PageWrapper'
import { UserStore } from '../../store/user.store'
import { FollowingVm } from './Following.vm'
import { Follower } from './shared/components/Follower'
import { FollowingHeader } from './shared/components/Header'

export const UserFollowings = observer(() => {
  const location = useLocation()
  const history = useHistory()
  const { userData } = useContext(UserStore)
  const [{ users, fetchFollowingsByUsername }] = useState(new FollowingVm())

  const username = location.pathname.split('/')[2]

  useEffect(() => {
    if (userData?.username === username) {
      history.push('/account/following')
    } else {
      const fetch = async () => {
        try {
          await fetchFollowingsByUsername(username)
        } catch (error: any) {
          alert(error.message)
          history.push('/')
        }
      }
      fetch()
    }
  }, [userData?.uid, userData?.username, username, fetchFollowingsByUsername, history])

  const onAccessoryClick = () => history.push(`/users/${username}/followers`)

  return (
    <PageWrapper>
      <FollowingHeader onAccessoryClick={onAccessoryClick} />
      {users.map(({ uid, avatar, name, username }) => (
        <Follower avatar={avatar} name={name} username={username} key={uid} />
      ))}
    </PageWrapper>
  )
})
