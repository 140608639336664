import React, { useContext, useEffect, useState } from 'react'

import './CampaignDetails.scss'
import { PageWrapper } from '../../containers/PageWrapper/PageWrapper'
import { Overview } from './components/Overview/Overview'
import { Description } from './components/Description/Description'
import { MainInfo } from './components/MainInfo/MainInfo'
import { useHistory, useLocation } from 'react-router-dom'
import { CampaignVm } from './CampaignDetails.vm'
import { Loader } from '../../components/Loader'
import { observer } from 'mobx-react-lite'
import { UserStore } from '../../store/user.store'
import { saveShareIdLocally, splitUrl, saveShared } from './campaignDetail.helpers'
import { LocalStorageService } from '../../services/core/localStorage.service'
import { useStripe } from '@stripe/react-stripe-js'
import { useModal } from '../../contexts/modal/modal'
import { CheckoutSession } from '../../types/stripe.types'

export const CampaignDetails = observer(() => {
  const location = useLocation()
  const history = useHistory()
  const stripe = useStripe()
  const { openModal } = useModal()

  const userStore = useContext(UserStore)
  const [vm, setVm] = useState<CampaignVm | undefined>(undefined)
  const { campaignId, shareId } = splitUrl(location.pathname)

  useEffect(() => {
    if (vm) {
      const getCampaignData = async () => {
        const result = await vm.getCampaignData()
        await vm.checkIfProductAvailable()
        await vm.fetchLastUsersAvatar()
        if (!result) {
          alert('something happen, try again')
          history.push('/')
        }
      }
      getCampaignData()
    }
  }, [vm, history])

  useEffect(() => {
    setVm(new CampaignVm(userStore, campaignId))
  }, [userStore, userStore.userData, campaignId])

  useEffect(() => {
    if (campaignId && shareId) {
      const currentUser = LocalStorageService.getItem<{ userId: string }>('user')
      if (currentUser) {
        saveShared(campaignId, shareId, currentUser.userId)
      } else {
        saveShareIdLocally(campaignId, shareId)
      }
    }
  }, [campaignId, shareId])

  useEffect(() => {
    const checkoutSession = LocalStorageService.getItem<CheckoutSession>('checkoutSession')

    if (vm?.campaign && checkoutSession) {
      vm.createPaymentIntent(checkoutSession)
      openModal({
        title: 'Success',
        text: `Congratulations, you've just co-order ${vm.campaign.title} product!`,
        icon: {
          name: 'logo',
          width: 59,
          height: 40,
        },
        button: {
          text: 'Go to co-orders',
          onPress: () => history.push('/account/orders'),
        },
      })
    }
  }, [vm, vm?.campaign, history])

  const onBuy = async () => {
    if (vm && vm.campaign && vm.userStore?.userData?.stripeCustomerId && stripe) {
      try {
        const session = await vm.onBuy()
        if (session?.id && session.url) {
          LocalStorageService.setItem('checkoutSession', {
            sessionId: session.id,
            customerId: vm.userStore.userData.stripeCustomerId,
            userId: vm.userStore.userData.uid,
            campaignId: vm.campaign.id,
          })
          stripe.redirectToCheckout({
            sessionId: session.id,
          })
        }
      } catch (error) {
        alert('Something happen')
      }
    } else {
      history.push('/login')
    }
  }

  const onLogoClick = () => {
    if (vm?.campaign && vm.userStore?.userData?.uid) {
      history.push(`/brand/${vm.campaign?.companyId}`)
    } else {
      history.push('/login')
    }
  }

  console.log(vm?.isSubscribed)

  return (
    <>
      {vm && vm.campaign ? (
        <PageWrapper className="campaign">
          <div className="overview">
            <Overview
              images={vm.campaign.images}
              likeCount={vm.campaign.liked?.length || 0}
              isLiked={vm.isLiked}
              shareCount={vm.campaign.shared?.length || 0}
              onLikeClick={vm.onLikeAction}
              shareData={vm.shareData}
            />
          </div>
          <div className="main-info">
            <MainInfo
              title={vm.campaign.title}
              description={vm.campaign.description}
              logo={vm.company?.logo}
              price={vm.campaign.price}
              retailPrice={vm.campaign.retailPrice}
              ordered={vm.campaign.buyers?.length || 0}
              goal={vm.campaign.goal}
              daysLeft={vm.getRemainingDays()}
              commission={vm.campaign.commission}
              onBuy={onBuy}
              availability={vm.availability}
              shareData={vm.shareData}
              avatars={vm.lastAvatars}
              onAvatarsClick={() => history.push(`/campaign/${vm.campaignId}/buyers`)}
              onLogoClick={onLogoClick}
              status={vm.campaign.status}
              onSubscribe={vm.onSubscribe}
              isSubscribed={vm.isSubscribed}
            />
          </div>
          <div className="description">
            <Description
              shipping={false}
              fullDescription={vm.campaign.fullDescription}
              id={vm.campaign.id}
              categories={vm.campaign.categories}
            />
          </div>
        </PageWrapper>
      ) : (
        <Loader absoluteCenter />
      )}
    </>
  )
})
