import React from 'react'

import { DropdownBody, DropdownHeader, DropdownWrapper, IconWrapper } from './index.style'
import { Text } from '../../../../components/Text'
import { COLORS } from '../../../../config/constants'
import { Icon } from '../../../../components/Icon/Icon'
import { CategoryIcons } from '../../../../components/Icon/Icon.types'
import { CategoryDropdownItem } from '../../../../components/Categories/CategoryDropdownItem'
import { categorySizes } from './index.config'
import { categoryTitles, ICategoryNames } from '../../../../components/Categories/CategoryItem/CategoryItem.types'

interface Props {
  onCloseModal: () => void
  categories: string[]
  toggleCategories: (category: ICategoryNames) => void
}

export const CategoriesPopupDropdown = ({ categories, onCloseModal, toggleCategories }: Props) => (
  <DropdownWrapper>
    <DropdownHeader>
      <Text color={COLORS.COLOR_MIDDLE_GRAY} weight={600} size={16} lineHeight="126.2%">
        Choose categories
      </Text>
      <IconWrapper onClick={onCloseModal}>
        <Icon name="cross" width={15} height={15} color={COLORS.COLOR_MIDDLE_GRAY} />
      </IconWrapper>
    </DropdownHeader>
    <DropdownBody>
      {Object.entries(categoryTitles).map((categoryObj) => {
        const [category, title] = categoryObj as [ICategoryNames, string]
        return (
          <CategoryDropdownItem
            key={category}
            iconName={CategoryIcons[category]}
            categoryName={category}
            size={categorySizes[category]}
            title={title}
            active={categories.indexOf(category) !== -1}
            toggleCategories={toggleCategories}
          />
        )
      })}
    </DropdownBody>
  </DropdownWrapper>
)
