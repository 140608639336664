type LocalStoragePath = 'user' | 'shareIds' | 'checkoutSession' | 'selectedCategories'

class LocalStorageManager {
  public setItem = (field: LocalStoragePath, value: Record<string, any> | string) => {
    localStorage.setItem(field, JSON.stringify(value))
  }

  public getItem = <T extends string | Record<string, any>>(field: LocalStoragePath) => {
    const data = localStorage.getItem(field)
    if (data) {
      return JSON.parse(data) as T
    }
    return null
  }

  public removeItem = (field: LocalStoragePath) => {
    localStorage.removeItem(field)
  }

  public loginInit = (userId: string) => {
    this.setItem('user', { userId })
  }
}

export const LocalStorageService = new LocalStorageManager()
