import React from 'react'
import DatePicker from 'react-datepicker'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import 'react-datepicker/dist/react-datepicker.css'
import './Input.scss'

const useStylesSelectInput = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
)

interface SelectInputProps {
  label: string
  name: string
  options: string[]
  value: string
  onChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void
}

export const SelectInput = ({ label, name, options, value, onChange }: SelectInputProps) => {
  const classes = useStylesSelectInput()

  const renderOptions =
    options &&
    options.map((option: string) => {
      return (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      )
    })

  return (
    <FormControl className={`${classes.formControl} form-select`}>
      <InputLabel className="input-label" id="demo-simple-select-label">
        {label}
      </InputLabel>
      <Select
        className="input-select"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={onChange}
        name={name}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {renderOptions}
      </Select>
    </FormControl>
  )
}

interface DatePickerInputProps {
  value: Date
  onChange: (value: Date) => void
}

export const DatePickerInput = ({ value, onChange }: DatePickerInputProps) => {
  return (
    <DatePicker
      onChange={onChange}
      selected={value}
      minDate={new Date(Date.now())}
      maxDate={new Date(Date.now() + 86400000 * 365)}
      startDate={new Date(Date.now())}
      endDate={value}
      selectsRange
    />
  )
}
