export const texts = {
  finished: [
    `Campaigns ordered with your sharing`,
    `Products
  totally ordered`,
    `total earned commission`,
  ],
  progress: [
    `Campaigns ordered with your sharing`,
    `Products
  totally ordered`,
    `preliminary earned commission`,
  ],
}

export const titles = {
  finished: 'Finished campaigns',
  progress: 'Campaigns in progress',
}
