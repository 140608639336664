import React from 'react'

import './CampaignDuration.scss'
import { InputRadioBlock } from '../InputRadioBlock/InputRadioBlock'
import { InputNumber } from '../../../../components/Input/Input'
import { CampaignDurationStateProps } from './campaignDuration.interface'

export const CampaignDuration = ({
  isActive,
  isFirst,
  index,
  setIsActive,
  onDurationChange,
  value,
}: CampaignDurationStateProps) => {
  return (
    <div className={`price__campaignDuration ${isFirst ? 'first' : ''}`}>
      <InputRadioBlock
        title="Campaign duration"
        description={`Optional If you focus on achieving more public engagements, we advice to set NO.
            If you focus on sales, we advice to set YES.`}
        leftRadioLabel="on"
        rightRadioLabel="off"
        name={`campaignDuration-${index}`}
        isActive={isActive}
        setIsActive={setIsActive}
      />
      <div className="input-number">
        <InputNumber isActive={isActive} type="duration" measure="days" onChange={onDurationChange} value={value} />
      </div>
      {/* <div className="description">Or set starting and ending date for this campaign:</div>
      <div className="buttons">
        <Button isActive={isActive} title="Start of campaign" type="arrowDown" />
        <Button isActive={isActive} title="End of campaign" type="arrowDown" />
      </div> */}
    </div>
  )
}
