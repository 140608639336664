import { storage } from '../../firebase'
import { ref, uploadBytes, deleteObject, getDownloadURL, listAll } from 'firebase/storage'

class StorageManager {
  public addAvatar = async (name: string, value: File) => {
    await uploadBytes(ref(storage, `avatar/${name}`), value)
  }

  public removeAvatar = async (name: string) => {
    await deleteObject(ref(storage, `avatar/${name}`))
  }

  public getData = async (path: string) => {
    return await getDownloadURL(ref(storage, path))
  }

  public getListData = async (path: string) => {
    const items = await (await listAll(ref(storage, path))).items
    return (await Promise.all(items.map(async (item) => await getDownloadURL(item)))) as string[]
  }

  public addCampaignImages = async (campaignId: string, value: File[]) => {
    await Promise.all(
      value.map(async (file, index) => {
        await uploadBytes(ref(storage, `campaigns/${campaignId}/${String(index)}`), file)
      })
    )
  }
}

export const StorageService = new StorageManager()
