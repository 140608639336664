import React from 'react'
import { Campaign } from '../../../../types/company.types'
import { OrderActionButtonCTA } from '../styles'

import { OrderRow } from '../../components/OrderRow'
import { FooterContainer } from './OrderExpandedCancelled.style'
import { CampaignExpandedCancelledProps } from '../types'

export const OrderExpandedCancelled = ({ startDate, endDate }: Omit<Campaign, 'status'>) => (
  <>
    <OrderRow title="Campaign started:" date={startDate} />
    {endDate && <OrderRow title="Campaign ended:" date={endDate} />}
  </>
)

export const CampaignExpandedCancelled = ({
  startDate,
  endDate,
  cancelDate,
  onRenew,
}: CampaignExpandedCancelledProps) => (
  <>
    <OrderRow title="Campaign started:" date={startDate} />
    {endDate && <OrderRow title="Campaign ended:" date={endDate} />}
    {cancelDate && <OrderRow title="Campaign cancelled:" date={cancelDate} />}
    <FooterContainer>
      <OrderActionButtonCTA onClick={onRenew}>Renew</OrderActionButtonCTA>
    </FooterContainer>
  </>
)
