import { CSSObject } from 'styled-components'
import { CategoryIconNames } from '../../Icon/Icon.types'

export type ICategoryNames =
  | 'clothing'
  | 'beauty'
  | 'games'
  | 'electronics'
  | 'hobbies'
  | 'baby'
  | 'home'
  | 'travel'
  | 'events'

export const categoryTitles: { [key in ICategoryNames]: string } = {
  baby: 'Baby',
  hobbies: 'Hobbies',
  beauty: 'Beauty & Care',
  electronics: 'Electronics',
  travel: 'Travel',
  home: 'Home & Kitchen',
  games: 'Toys & Games',
  events: 'Events',
  clothing: 'Clothing, Shoes & Jewelry',
}

export const categories: ICategoryNames[] = [
  'clothing',
  'beauty',
  'games',
  'electronics',
  'hobbies',
  'baby',
  'home',
  'travel',
  'events',
]

export type CategorySizes = 'small' | 'medium'

export enum CATEGORY_SIZES {
  SMALL = 92,
  MEDIUM = 107,
}

export enum CATEGORY_BLOCK_SIZES {
  SMALL = 69,
  MEDIUM = 80,
}

interface CategoryPresentation {
  iconName: CategoryIconNames
  title: string
}

export const Categories: { [key in ICategoryNames]: CategoryPresentation } = {
  clothing: {
    iconName: 't-shirt',
    title: 'Clothing, Shoes & Jewelry',
  },
  beauty: {
    iconName: 'brush',
    title: 'Beauty & Care',
  },
  games: {
    iconName: 'puzzle',
    title: 'Toys & Games',
  },
  electronics: {
    iconName: 'cellphone',
    title: 'Electronics',
  },
  hobbies: {
    iconName: 'bike',
    title: 'Hobbies',
  },
  baby: {
    iconName: 'babysitter',
    title: 'Baby',
  },
  home: {
    iconName: 'home',
    title: 'Home & Kitchen',
  },
  travel: {
    iconName: 'earth',
    title: 'Travel',
  },
  events: {
    iconName: 'star',
    title: 'Events',
  },
}

export interface CategoryItemProps {
  name: ICategoryNames
  size: CategorySizes
  isActive: boolean
  style?: CSSObject
  onClick?: () => void
}
