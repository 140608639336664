import { action, computed, makeObservable, observable } from 'mobx'
import { FirestoreService } from '../../services/core/firestore.service'
import { LocalStorageService } from '../../services/core/localStorage.service'
import { StripeService } from '../../services/core/stripe.service'
import { CampaignLikeState, CampaignService } from '../../services/entities/campaign.service'
import { UserStoreVm } from '../../store/user.store'
import { Campaign, Company } from '../../types/company.types'
import { CheckoutSession } from '../../types/stripe.types'
import { DateTime } from 'luxon'

export type CampaignAvailability = {
  isAvailable: boolean
  isBought: boolean
}

export class CampaignVm {
  campaign?: Campaign = undefined
  userStore?: UserStoreVm = undefined
  campaignId?: string = undefined
  company?: Company = undefined
  availability?: CampaignAvailability = {
    isAvailable: false,
    isBought: false,
  }
  isLiked = false
  lastAvatars: string[] = []
  isSubscribed = false

  constructor(userStore: UserStoreVm, campaignId?: string) {
    this.userStore = userStore
    this.campaignId = campaignId
    this.isSubscribed = campaignId ? userStore.userData?.subscribedCampaigns?.includes(campaignId) || false : false

    makeObservable(this, {
      campaign: observable,
      isLiked: observable,
      setIsLiked: observable,
      availability: observable,
      lastAvatars: observable,
      isSubscribed: observable,
      setProduct: action,
      setAvailability: action,
      getCampaignData: action,
      setLastAvatars: action,
      setIsSubscribed: action,
      onSubscribe: action,
      shareData: computed,
    })
  }

  private fetchProduct = async (id: string) => {
    const { campaignData } = await FirestoreService.getCampaignById(id)
    const { companyData } = await FirestoreService.getCompanyById(campaignData.companyId)
    if (this.userStore?.userData && this.userStore.userData.liked.includes(campaignData.id)) {
      this.setIsLiked(true)
    }
    return this.setProduct(campaignData, companyData)
  }

  public setLastAvatars = (avatars: string[]) => {
    this.lastAvatars = avatars
  }

  private updateCampaignLiked = (result: CampaignLikeState, isLiked: boolean) => {
    if (this.campaign) {
      this.campaign.liked = result.campaignLikedBy
      this.userStore?.updateLiked(result.userLikes)
      this.setIsLiked(isLiked)
    }
  }

  private likeCampaign = async () => {
    if (this.campaignId && this.userStore?.userData?.uid) {
      const result = await CampaignService.likeCampaign(this.campaignId, this.userStore.userData.uid)
      this.updateCampaignLiked(result, true)
    }
  }

  private removeLikeCampaign = async () => {
    if (this.campaignId && this.userStore?.userData?.uid) {
      const result = await CampaignService.removeLikeCampaign(this.campaignId, this.userStore.userData.uid)
      this.updateCampaignLiked(result, false)
    }
  }

  public getCampaignData = async () => {
    if (this.campaignId) {
      return this.fetchProduct(this.campaignId)
    } else {
      return false
    }
  }

  public setProduct = (campaign: Campaign, company: Company): true => {
    this.campaign = campaign
    this.company = company
    return true
  }

  public setIsLiked = (value: boolean) => {
    this.isLiked = value
  }

  public setIsSubscribed = (value: boolean) => {
    this.isSubscribed = value
  }

  public setAvailability = (value: CampaignAvailability) => {
    this.availability = value
  }

  public onLikeAction = async () => {
    if (this.isLiked) {
      await this.removeLikeCampaign()
    } else {
      await this.likeCampaign()
    }
  }

  public get shareData(): ShareData | null {
    return this.userStore?.userData && this.campaign
      ? {
          title: this.campaign.companyName,
          text: this.campaign.description,
          url: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}/
            campaign/${this.campaignId}/${this.userStore.userData.uid}`,
        }
      : null
  }

  public checkIfProductAvailable = async () => {
    if (this.campaign?.companyId) {
      const { companyData } = await FirestoreService.getCompanyById(this.campaign.companyId)
      if (
        companyData.userId === this.userStore?.userData?.uid ||
        (this.userStore?.userData?.uid &&
          this.campaign.buyers.includes(this.userStore?.userData?.uid) &&
          !this.campaign.orderMoreThanOne)
      ) {
        const isBought = this.campaign.buyers.includes(this.userStore?.userData?.uid)
        this.setAvailability({ isAvailable: false, isBought })
      } else {
        this.setAvailability({ isAvailable: true, isBought: false })
      }
    } else {
      this.setAvailability({ isAvailable: true, isBought: false })
    }
  }

  public onBuy = async () => {
    if (this.campaign?.stripeId && this.userStore?.userData?.stripeCustomerId) {
      const stipeSession = await StripeService.createCheckout({
        customerId: this.userStore.userData.stripeCustomerId,
        successUrl: `https://${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}/campaign/${this.campaignId}`,
        cancelUrl: `https://${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}/campaign/${this.campaignId}`,
      })

      if (stipeSession) {
        return { id: stipeSession.id, url: stipeSession.url }
      }
    }
  }

  public createPaymentIntent = async (checkoutSession: CheckoutSession) => {
    const paymentIntent = await StripeService.createPaymentIntentFromCheckoutSession({
      sessionId: checkoutSession.sessionId,
      customerId: checkoutSession.customerId,
    })
    if (paymentIntent) {
      await FirestoreService.orderItem(checkoutSession.userId, checkoutSession.campaignId, paymentIntent)
    }
    LocalStorageService.removeItem('checkoutSession')
  }

  public fetchLastUsersAvatar = async () => {
    if (this.campaign?.buyers) {
      const result = await CampaignService.fetchAvatars(this.campaign.buyers)
      this.setLastAvatars(result)
    }
  }

  public getRemainingDays = () => {
    if (this.campaign?.endDate) {
      const endDate = DateTime.fromISO(this.campaign?.endDate)
      const startDate = DateTime.now()
      const result = endDate.diff(startDate, 'days')
      return result.days.toFixed(0)
    }
    return null
  }

  public onSubscribe = async () => {
    if (this.campaignId && this.userStore?.userData?.uid) {
      if (this.isSubscribed) {
        await this.userStore.unsubscribeToCampaign(this.campaignId)
        this.setIsSubscribed(false)
      } else {
        await this.userStore.subscribeToCampaign(this.campaignId)
        this.setIsSubscribed(true)
      }
    }
  }
}
