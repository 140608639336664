import React, { SVGProps, memo } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={props.width || 41} height={props.height || 43} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M20.5 43C31.822 43 41 33.374 41 21.5S31.822 0 20.5 0 0 9.626 0 21.5 9.178 43 20.5 43Z" fill="#000" />
    <path
      d="M21 25.6c.972 0 1.8-.36 2.532-1.068C24.24 23.8 24.6 22.972 24.6 22s-.36-1.8-1.068-2.532C22.8 18.76 21.972 18.4 21 18.4s-1.8.36-2.532 1.068C17.76 20.2 17.4 21.028 17.4 22s.36 1.8 1.068 2.532C19.2 25.24 20.028 25.6 21 25.6ZM21 10c3.3 0 6.12 1.2 8.46 3.54C31.8 15.88 33 18.7 33 22v1.74c0 1.2-.42 2.22-1.2 3.06-.84.804-1.8 1.2-3 1.2-1.44 0-2.628-.6-3.528-1.8-1.2 1.2-2.616 1.8-4.272 1.8-1.644 0-3.06-.6-4.248-1.752C15.6 25.06 15 23.656 15 22c0-1.644.6-3.06 1.752-4.248C17.94 16.6 19.356 16 21 16c1.656 0 3.06.6 4.248 1.752C26.4 18.94 27 20.356 27 22v1.74c0 .492.192.924.552 1.296.36.372.78.564 1.248.564.504 0 .924-.192 1.284-.564.36-.372.516-.804.516-1.296V22c0-2.628-.924-4.884-2.82-6.78S23.628 12.4 21 12.4s-4.884.924-6.78 2.82S11.4 19.372 11.4 22s.924 4.884 2.82 6.78S18.372 31.6 21 31.6h6V34h-6c-3.3 0-6.12-1.2-8.46-3.54C10.2 28.12 9 25.3 9 22c0-3.3 1.2-6.12 3.54-8.46C14.88 11.2 17.7 10 21 10Z"
      fill="#fff"
    />
  </svg>
)

export const EmailBlackIcon = memo(SvgComponent)
