import { IconProps } from '../../../components/Icon/Icon'
import { COLORS } from '../../../config/constants'

export const arrowLeftConfig: IconProps = {
  name: 'arrow-left',
  width: 11,
  height: 20,
  color: COLORS.COLOR_LIGHT_GRAY_2,
}

export const markIconConfig: IconProps = {
  name: 'account-mark',
  width: 28,
  height: 14,
  color: COLORS.COLOR_ORANGE_1,
}

export const arrowRightConfig: IconProps = {
  name: 'arrow-right',
  width: 11,
  height: 20,
  color: COLORS.COLOR_LIGHT_GRAY_2,
}

export const accountRightConfig: IconProps = {
  name: 'account-right-arrow',
  width: 28,
  height: 14,
  color: COLORS.COLOR_ORANGE_1,
}
