import styled from 'styled-components'
import { Text } from '../../../Text'
import { COLORS } from '../../../../config/constants'

export const FooterContainer = styled.div`
  margin-top: 37px;
`

export const DescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const Description = styled(Text)`
  line-height: 18px;
  font-weight: 400;
  margin-top: 25px;
  max-width: 304px;
  text-align: center;
  color: ${COLORS.COLOR_MIDDLE_GRAY};
`
