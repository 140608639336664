import React from 'react'

import './Footer.scss'
import { FooterStateProps } from './footer.interface'
import { ArrowBack, ArrowNext } from './Arrow/Arrow'

export const Footer = ({ onClickBackButton, onClickNextButton, stage }: FooterStateProps) => {
  return (
    <footer className="campaignRegistration__footer">
      <div className="container">
        <ArrowBack onClick={onClickBackButton} stage={stage} />
        <ArrowNext onClick={onClickNextButton} stage={stage} />
      </div>
    </footer>
  )
}
