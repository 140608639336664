import React from 'react'

import { Wrapper, Input, SearchIcon } from './Search.styles'
import { Icon } from '../../../../components'

interface SearchProps {
  value: string
  placeholder?: string
  onChange: (value: string) => void
  onClick: () => void
}

export const Search = ({ placeholder, value, onChange, onClick }: SearchProps) => {
  const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onClick()
    }
  }

  return (
    <Wrapper>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyPress={onKeyPress}
      />
      <SearchIcon onClick={onClick}>
        <Icon name="search" width={18.47} height={17.38} />
      </SearchIcon>
    </Wrapper>
  )
}
