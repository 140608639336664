import styled from 'styled-components'
import { COLORS } from '../../../../config/constants'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 63px;
`

export const TextWrapper = styled.div`
  margin-left: 16px;
`
export const InfoWrapper = styled.div`
  display: flex;
`

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 18px;
  padding-right: 18px;
`

export const PlusButtonContainer = styled.button`
  display: flex;
  border: 0;
  background-color: ${COLORS.COLOR_WHITE};
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 31px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;

  & p {
    color: ${COLORS.COLOR_MIDDLE_GRAY};
    font-size: 40px;
  }
`
