import React, { useState } from 'react'
import NumericInput from 'react-numeric-input'

import './Rewards.scss'
import { RewardsStateProps } from './Rewards.interfaces'
import { Block } from '../../../../containers/Block/Block'
import { SelectInput } from '../../../../components/Input/Input'

export const Rewards = () => {
  const [state, setState] = useState<RewardsStateProps>({
    type: '',
    references: undefined,
    commission: undefined,
  })

  const onChangeSelect = (e: any) => {
    setState({
      ...state,
      type: e.target.value,
      references: undefined,
      commission: undefined,
    })
  }

  const commissionFormatter = (value: number | null) => `${value}%`

  return (
    <div className="rewards__wrapper">
      <Block label="Campaign reward">
        <div className="selectInput">
          <SelectInput
            label="type of reward"
            name="type"
            value={state.type}
            onChange={(e) => onChangeSelect(e)}
            options={['references', 'commission']}
          />
          <div className={`${state.type !== 'references' && 'hide'} number-input references`}>
            <NumericInput
              min={0}
              onChange={(value: any) => setState({ ...state, references: value })}
              value={state.references}
              placeholder="references for free goods"
            />
          </div>
          <div className={`${state.type !== 'commission' && 'hide'} number-input commission`}>
            <NumericInput
              onChange={(value: any) => setState({ ...state, commission: value })}
              format={commissionFormatter}
              min={0}
              max={100}
              value={state.commission}
              placeholder="commission for each recommendation %"
            />
          </div>
        </div>
      </Block>
    </div>
  )
}
