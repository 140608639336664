import React from 'react'

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.43 2.16L18.04 6.02L21.55 7.81L19.78 11.31L21.56 14.81L18.02 16.6L17.41 20.46L13.52 19.85L10.75 22.61L7.97 19.81L4.11 20.45L3.49 16.56L2.86102e-08 14.78L1.78 11.28L0.01 7.81L3.52 6L4.13 2.17L8 2.81L10.78 -2.38419e-09L13.55 2.77L17.43 2.16ZM8.28 6.31C7.88217 6.31 7.50064 6.46804 7.21934 6.74934C6.93804 7.03064 6.78 7.41218 6.78 7.81C6.78 8.20782 6.93804 8.58936 7.21934 8.87066C7.50064 9.15197 7.88217 9.31 8.28 9.31C8.67782 9.31 9.05936 9.15197 9.34066 8.87066C9.62196 8.58936 9.78 8.20782 9.78 7.81C9.78 7.41218 9.62196 7.03064 9.34066 6.74934C9.05936 6.46804 8.67782 6.31 8.28 6.31ZM13.28 13.31C12.8822 13.31 12.5006 13.468 12.2193 13.7493C11.938 14.0306 11.78 14.4122 11.78 14.81C11.78 15.2078 11.938 15.5894 12.2193 15.8707C12.5006 16.152 12.8822 16.31 13.28 16.31C13.6778 16.31 14.0594 16.152 14.3407 15.8707C14.622 15.5894 14.78 15.2078 14.78 14.81C14.78 14.4122 14.622 14.0306 14.3407 13.7493C14.0594 13.468 13.6778 13.31 13.28 13.31ZM7.19 16.31L15.78 7.72L14.37 6.31L5.78 14.9L7.19 16.31Z"
        fill={props.color || 'black'}
      />
    </svg>
  )
}

export const PercentIcon = React.memo(SVGComponent)
