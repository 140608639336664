import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../../../../../config/constants'

const Container = styled.div`
  display: flex;
`

const WhiteButton = styled.button`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-right: 10px;
  border: 0;
  border-radius: 4px;
  background-color: ${COLORS.COLOR_LIGHT_GRAY_1};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 157.2%;
  font-family: 'Poppins';
`

const ColoredButton = styled.button`
  display: flex;
  flex: 1.4;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 0;
  border-radius: 4px;
  background: linear-gradient(270deg, #eb4dbf 0%, #f98140 100%);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 157.2%;
  font-family: 'Poppins';
`

interface ActionBarProps {
  isFollowing: boolean
  onFollow: () => void
  onUnfollow: () => void
}

export const ActionBar = ({ onFollow, isFollowing, onUnfollow }: ActionBarProps) => (
  <Container>
    <WhiteButton>Contact</WhiteButton>
    <WhiteButton>Share</WhiteButton>
    <ColoredButton onClick={isFollowing ? onUnfollow : onFollow}>{isFollowing ? 'Unfollow' : 'Follow'}</ColoredButton>
  </Container>
)
