import React from 'react'
import { Modal } from '@material-ui/core'

import { CategoriesPopupDropdown } from './Dropdown'
import { ICategoryNames } from '../../../../components/Categories/CategoryItem/CategoryItem.types'

interface Props {
  isOpen: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  categories: string[]
  toggleCategories: (category: ICategoryNames) => void
}

export const CategoriesPopup = ({ isOpen, setOpen, ...props }: Props) => {
  const closeModal = () => setOpen(false)

  return (
    <Modal open={isOpen}>
      <CategoriesPopupDropdown onCloseModal={closeModal} {...props} />
    </Modal>
  )
}
