import React, { useMemo } from 'react'

import { OrderButtonWrapper, Title, IconWrapper } from './OrderButton.style'
import { OrderButtonProps } from './OrderButton.interface'
import { Icon } from '../../Icon/Icon'
import { GetInfoFromStatus } from './OrderButton.service'

export const OrderButton = ({ status }: OrderButtonProps) => {
  const data = useMemo(() => GetInfoFromStatus(status), [status])

  return (
    <OrderButtonWrapper width={data.width} borderColor={data.borderColor}>
      <Title color={data.color} size={10} weight={600} lineHeight="15px">
        {status}
      </Title>
      <IconWrapper>
        <Icon color={data.iconColor} name={data.icon} />
      </IconWrapper>
    </OrderButtonWrapper>
  )
}
