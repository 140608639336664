import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'

import './Price.scss'
import { InputTitle } from './components/InputTitle/InputTitle'
import { InputRadioBlock } from './components/InputRadioBlock/InputRadioBlock'
import { CampaignDuration } from './components/CampaignDuration/CampaignDuration'
import { Footer } from '../../components/Footer/Footer'
import { RenderedComponentStateProps } from '../../campaignRegistration.interface'
import { CampaignRegistrationVm } from '../../campaignRegistration.vm'

export const Price = observer(({ onClickBackButton, onClickNextButton, stage }: RenderedComponentStateProps) => {
  const { formData, updateFormData } = useContext(CampaignRegistrationVm)

  const updateCampaignDurationStatus = (value: boolean) => {
    if (value) {
      updateFormData({ isDurationActive: value })
    } else {
      updateFormData({ isDurationActive: value, campaignDuration: null })
    }
  }

  return (
    <div className="campaignRegistration__price">
      <div className="container">
        <div className="price__title">Set a price and a goal of your campaign</div>
        <InputTitle
          type="price"
          measure="$"
          title="Retail price"
          value={String(formData.retailPrice)}
          onChange={(event) => updateFormData({ retailPrice: parseInt(event.target.value) })}
        />
        <InputTitle
          type="price"
          measure="$"
          title="Price of one product"
          value={String(formData.price)}
          onChange={(event) => updateFormData({ price: parseInt(event.target.value) })}
        />
        <InputTitle
          type="goal"
          measure="pieces"
          title="Goal of the campaign"
          value={String(formData.goal)}
          onChange={(event) => updateFormData({ goal: parseInt(event.target.value) })}
        />
        <div className="price__pricesCounter">
          <InputRadioBlock
            title="Can one user order more than one priece? "
            description={`If you focus on achieving more public engagements, we advice to set NO.
            If you focus on sales, we advice to set YES.`}
            leftRadioLabel="yes"
            rightRadioLabel="no"
            name="pricesCounter"
            setIsActive={(value) => updateFormData({ orderMoreThanOne: value })}
            isActive={formData.orderMoreThanOne}
          />
        </div>
        <CampaignDuration
          index={1}
          isFirst={false}
          isActive={formData.isDurationActive}
          setIsActive={(value) => updateCampaignDurationStatus(value)}
          onDurationChange={(value) => updateFormData({ campaignDuration: parseInt(value.target.value) })}
          value={String(formData.campaignDuration)}
        />
      </div>
      <Footer onClickBackButton={onClickBackButton} onClickNextButton={onClickNextButton} stage={stage} />
    </div>
  )
})
