import React from 'react'

import { InnerCircle, OuterCircle } from './Circle.style'

interface CircleProps {
  isVisible: boolean
}

export const Circle = ({ isVisible }: CircleProps) => {
  return (
    <OuterCircle>
      <InnerCircle isVisible={isVisible} />
    </OuterCircle>
  )
}
