import { observable, makeObservable, action } from 'mobx'
import { debounce } from 'lodash'

import { ICategoryNames } from '../../components/Categories/CategoryItem/CategoryItem.types'
import { DisplayType } from '../../components/DisplaySettings/DisplaySettings.types'
import { ProductProps } from '../../components/Product/Product.interfaces'
import { FirestoreService } from '../../services/core/firestore.service'
import { LocalStorageService } from '../../services/core/localStorage.service'

export class SearchVm {
  display: DisplayType = 'vertical'
  input = ''
  categories: Record<ICategoryNames, boolean> = {
    clothing: false,
    beauty: false,
    games: false,
    electronics: false,
    hobbies: false,
    baby: false,
    home: false,
    travel: false,
    events: false,
  }
  products: ProductProps[] = []
  isFetching = false

  constructor() {
    makeObservable(this, {
      isFetching: observable,
      input: observable,
      categories: observable,
      display: observable,
      products: observable,
      setInput: action,
      updateCategories: action,
      setDisplay: action,
      setProducts: action,
      setIsFetching: action,
    })

    const storedCategories = LocalStorageService.getItem<Record<ICategoryNames, boolean>>('selectedCategories')
    if (storedCategories) {
      this.categories = storedCategories
    }
  }

  public fetchCampaigns = debounce(async () => {
    if (this.input.length > 0 || Object.values(this.categories).some((value) => !!value)) {
      const result = await FirestoreService.fetchCampaignsWithFilter(
        this.input,
        Object.entries(this.categories)
          .filter((category) => !!category[1])
          .map((category) => category[0]) as ICategoryNames[]
      )
      this.setProducts(result)
    } else {
      this.fetchAllCampaigns()
    }
  }, 700)

  public setInput = (input: string) => {
    this.input = input
  }

  public updateCategories = (category: ICategoryNames) => {
    const newCategories = { ...this.categories }
    newCategories[category] = !this.categories[category]
    this.categories = newCategories
  }

  public setDisplay = (displayType: DisplayType) => {
    this.display = displayType
  }

  public setProducts = (products: ProductProps[]) => {
    this.products = products
  }

  public setIsFetching = (value: boolean) => {
    this.isFetching = value
  }

  public getCategoryStatus = (category: ICategoryNames) => !!this.categories[category]

  public fetchAllCampaigns = async () => {
    this.setIsFetching(true)
    const result = await FirestoreService.fetchCampaigns()
    this.setProducts(result)
    this.setIsFetching(false)
    this.fetchCampaigns.cancel()
  }
}
