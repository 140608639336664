import { CurrencyVariants } from './basics.interfaces'

export const titleDescription =
  "Our search looks through words from your project title, so make them clear and descriptive of what you're making. Your profile name will be searchable, too."

export const goalDescription =
  "Funding on Wecobi is all-or-nothing. It's okay to raise more than your goal, but if your goal isn't met, no money will be collected. Your goal should reflect the minimum amount of funds you need send out rewards, and include a buffer for payments precessing fees. If your Campaign is successfully funded, the following fees will be collected from your funding total: Wecobi's 5% fee, and payment processing fees (between 3% and 5%). If funding isn't successfull, there are no fees."

export const collaboratorsDescription = `Grant your teammates access to help with your project`

export const currencyOptions: CurrencyVariants[] = ['USD', 'EURO', 'BYN']

export const msDateTime = 86400000
