import React from 'react'
import { CSSObject } from 'styled-components'

import { WindowWrapper, Wrapper } from './PageWrapper.styles'
import { Navigation } from '../../components/Navigation/Navigation'
import { Footer } from '../../components/Footer/Footer'

interface PageWrapperStateProps {
  children: React.ReactNode
  style?: CSSObject
  className?: string
  noNavigation?: boolean
  navigationAbsolute?: boolean
  noFooter?: boolean
}

export const PageWrapper = ({
  children,
  style,
  className,
  noNavigation = false,
  navigationAbsolute,
  noFooter = false,
}: PageWrapperStateProps) => (
  <WindowWrapper>
    <Wrapper className={className} styles={style}>
      {!noNavigation && <Navigation absolute={navigationAbsolute} />}
      {children}
      {!noFooter && <Footer />}
    </Wrapper>
  </WindowWrapper>
)
