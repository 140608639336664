import styled from 'styled-components'

import { Text } from '../../../../components'
import { COLORS } from '../../../../config/constants'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
  margin-top: 31px;
  margin-bottom: 46px;
`

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 17px;
  border-radius: 8px;
  filter: drop-shadow(-2px 2px 10px rgba(0, 0, 0, 0.25));
  background: linear-gradient(270deg, #eb4dbf 0%, #f98140 100%);
`

export const Title = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  line-height: 125.7%;
  text-align: center;
  color: ${COLORS.COLOR_WHITE};
  margin-top: 20px;
`

export const CardText = styled(Text)`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.08px;
  color: ${COLORS.COLOR_WHITE};
  margin-top: 8px;
`

export const CopyContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`

export const CopyText = styled(Text)`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  max-width: 73px;
  text-align: center;
  letter-spacing: -0.08px;
  color: #e6e6e6;
  margin-top: 10px;
`
