import styled from 'styled-components'

export const FilterWrapper = styled.div`
  display: flex;
  height: 146px;
  overflow-x: scroll;
  padding: 20px 0 0 11px;

  &::-webkit-scrollbar {
    display: none;
  }
`
