import React, { useState } from 'react'

import { DescriptionStateProps } from './Description.interfaces'
import { maxTextareaLength } from './Description.config'
import './Description.scss'
import { Block } from '../../../../containers/Block/Block'

export const Description = () => {
  const [state, setState] = useState<DescriptionStateProps>({
    description: '',
  })

  const onChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  return (
    <div className="description__wrapper">
      <Block label="Campaign description">
        <textarea
          onChange={(e) => onChange(e)}
          value={state.description}
          name="description"
          placeholder="description..."
          className="textarea"
          maxLength={maxTextareaLength}
        />
        <div className="input-description">max length: {maxTextareaLength}</div>
      </Block>
    </div>
  )
}
