import React from 'react'

import { Wrapper, ProductFull } from './ProductsPreview.styles'
import { ProductSlider } from '../../components/ProductsSlider/ProductsSlider'
import { Product } from '../../../../components'
import { ProductProps } from '../../../../components/Product/Product.interfaces'

interface ProductPreviewProps {
  products: ProductProps[]
}

export const ProductPreview = ({ products }: ProductPreviewProps) => (
  <>
    {products.length > 0 && (
      <Wrapper>
        <ProductSlider products={products} />
        <ProductFull>
          <Product size="large" {...products[0]} />
        </ProductFull>
      </Wrapper>
    )}
  </>
)
