import { createContext } from 'react'
import { observable, makeObservable, action } from 'mobx'

import { Campaign, Company } from '../types/company.types'
import { CompanyService } from '../services/entities/company.service'

export class CompanyStoreVm {
  companyData: Company | null = null
  campaignsList: Campaign[] = []

  constructor() {
    makeObservable(this, {
      companyData: observable,
      campaignsList: observable,
      setCompany: action,
      setCampaigns: action,
    })
  }

  public fetchCampaigns = async () => {
    if (this.companyData?.id) {
      const campaigns = await CompanyService.getCampaigns(this.companyData?.id)
      this.setCampaigns(campaigns)
    }
  }

  public setCompany = (data: Company | null) => {
    this.companyData = data
  }

  public setCampaigns = (data: Campaign[]) => {
    this.campaignsList = data
  }

  public updateLogo = async (value: File) => {
    if (this.companyData?.id) {
      const logo = await CompanyService.updateLogo(value, this.companyData.id)
      this.companyData.logo = logo
      return logo
    } else {
      return null
    }
  }

  public removeLogo = async () => {
    if (this.companyData?.id) {
      await CompanyService.removeLogo(this.companyData.id)
      this.companyData.logo = undefined
    }
  }
}

export const CompanyStore = createContext(new CompanyStoreVm())
