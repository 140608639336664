import React, { useContext } from 'react'
import { Campaign } from '../../../../types/company.types'
import { OrderActionButton } from '../styles'

import { OrderRow } from '../../components/OrderRow'
import { ButtonsContainer, FooterContainer, LeftButton, RightButton } from './OrderExpandedActive.styles'
import { CampaignExpandedActiveProps } from '../types'
import { useHistory } from 'react-router-dom'
import { UserService } from '../../../../services/entities/user.service'
import { UserStore } from '../../../../store/user.store'
import { RWebShare } from 'react-web-share'

export const OrderExpandedActive = ({
  orderMoreThanOne,
  startDate,
  id,
  title,
  description,
}: Omit<Campaign, 'status'>) => {
  const history = useHistory()
  const { userData } = useContext(UserStore)

  const orderMore = () => {
    history.push(`/campaign/${id}`)
  }

  const onCancel = async () => {
    if (userData?.uid && id) {
      await UserService.cancelOrder(userData?.uid, id)
    }
  }

  const shareData: ShareData = {
    title,
    text: description,
    url: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}/campaign/${id}/${userData?.uid}`,
  }

  return (
    <>
      <OrderRow title="Campaign started:" date={startDate} />
      <ButtonsContainer>
        {orderMoreThanOne && <LeftButton onClick={orderMore}>Buy more pieces</LeftButton>}
        <RWebShare data={shareData}>
          <RightButton>Invite friends to co-buy</RightButton>
        </RWebShare>
      </ButtonsContainer>
      <FooterContainer>
        <OrderActionButton style={{ flex: 1 }} onClick={onCancel}>
          Cancel
        </OrderActionButton>
      </FooterContainer>
    </>
  )
}

export const CampaignExpandedActive = ({ startDate, onEdit, onCancel }: CampaignExpandedActiveProps) => (
  <>
    <OrderRow title="Campaign started:" date={startDate} />
    <FooterContainer>
      <OrderActionButton style={{ flex: 3, marginRight: 8 }} onClick={onCancel}>
        Cancel the campaign
      </OrderActionButton>
      <OrderActionButton style={{ flex: 1 }} onClick={onEdit}>
        Edit
      </OrderActionButton>
    </FooterContainer>
  </>
)
