import styled from 'styled-components'

import { COLORS } from '../../config/constants'

interface InnerCircle {
  isVisible: boolean
}

export const OuterCircle = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid ${COLORS.COLOR_LIGHT_GRAY_2};
  border-radius: 50%;
  cursor: pointer;
`

export const InnerCircle = styled.div`
  position: absolute;
  display: ${(p: InnerCircle) => (p.isVisible ? 'block' : 'none')};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: ${COLORS.COLOR_ORANGE_1};
`
