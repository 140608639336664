import React, { Fragment } from 'react'
import Truncate from 'react-truncate'

import './MainInfo.scss'
import { ProgressBar } from '../../../../components/reusable'
import { BoughtButton, Button } from './Button'
import companyLogo from '../../../../assets/images/DefaultAccountAvatar.svg'

import { PROGRESS_BAR_HEIGHTS } from '../../../../components/reusable'
import { Campaign } from '../../../../types/company.types'
import { RWebShare } from 'react-web-share'
import { CampaignAvailability } from '../../CampaignDetails.vm'
import { Loader } from '../../../../components/Loader'
import { Icon } from '../../../../components'
import { OrderStatus } from '../../../../components/Order/OrderButton/OrderButton.interface'

export interface CampaignDetailsMainProps
  extends Pick<Campaign, 'title' | 'description' | 'price' | 'retailPrice' | 'goal' | 'commission'> {
  logo?: string
  daysLeft?: number | string | null
  ordered: number
  onBuy?: () => void
  shareData?: ShareData | null
  avatars: string[]
  onAvatarsClick: () => void
  onLogoClick: () => void
  availability?: CampaignAvailability
  status: OrderStatus
  onSubscribe: () => void
  isSubscribed: boolean
}

const AVATAR_WIDTH = 26

export const MainInfo = ({
  title,
  description,
  logo,
  price,
  retailPrice,
  ordered,
  goal,
  daysLeft,
  commission,
  onBuy,
  shareData,
  avatars,
  onAvatarsClick,
  onLogoClick,
  availability,
  status,
  onSubscribe,
  isSubscribed,
}: CampaignDetailsMainProps) => {
  const renderButton = () => {
    if (!availability) {
      return <Loader />
    }
    if (availability.isAvailable) {
      return (
        <Button type="large" onClick={onBuy}>
          Co-buy
        </Button>
      )
    } else {
      if (availability.isBought) {
        return (
          <BoughtButton>
            <Icon name="price-tag" width={25} height={25} style={{ marginRight: 5 }} />
            You placed the order
          </BoughtButton>
        )
      } else {
        return <div />
      }
    }
  }

  return (
    <Fragment>
      <div className="title">
        <div className="company-text">
          <div className="title" title={title}>
            <Truncate width={350} ellipsis="...">
              {title}
            </Truncate>
          </div>
          <div className="subtitle" title={description}>
            <Truncate width={350} ellipsis="...">
              {description}
            </Truncate>
          </div>
        </div>
        <div className="company-logo" onClick={onLogoClick}>
          <img src={logo || companyLogo} alt="company-logo" />
        </div>
      </div>
      <div className="price">
        <div className="company-price">${price}</div>
        <div className="company-retail-price">
          retail price:&nbsp;<span>${retailPrice}</span>
        </div>
      </div>
      <div className="progress">
        <ProgressBar pcs={ordered} pcsMax={goal} height={PROGRESS_BAR_HEIGHTS.LARGE} />
        <div className="contributors__wrapper">
          <div className="company-contributors">
            <div className="pcs">
              {ordered}
              <span>/{goal}</span>
            </div>
            <div className="avatars" onClick={onAvatarsClick}>
              {avatars.map((avatar, index) => (
                <div
                  key={index}
                  className="centered position-absolute"
                  style={{
                    left: `${(index * AVATAR_WIDTH) / 2}px`,
                    zIndex: index,
                  }}>
                  <img src={avatar} alt="avatar" />
                </div>
              ))}
            </div>
          </div>
          {daysLeft && <div className="company-days-left">{`${daysLeft} days left`}</div>}
        </div>
      </div>
      <div className="buttons">
        {renderButton()}
        {shareData && (
          <RWebShare data={shareData}>
            <Button type="small">Share</Button>
          </RWebShare>
        )}
      </div>
      {status !== 'active' && (
        <div>
          <div className={`subscribe-button ${isSubscribed ? 'subscribed' : ''}`}>
            <button onClick={onSubscribe}>
              <Icon name="bell" width={20} height={20} />
              <p>{isSubscribed ? 'You will be notified' : 'Notify me when campaign is renewed'}</p>
            </button>
          </div>
        </div>
      )}
      <div className="company-commission">
        <div className="text">Advice your friends and get a commission for sharing</div>
        <div className="commission">
          commission <span>{commission}%</span>
        </div>
      </div>
    </Fragment>
  )
}
