import * as React from 'react'

function SvgComponent() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.336 4.55994C18.5701 3.80587 17.3256 3.81454 16.5705 4.57922L9.23185 12.0118L5.30343 8.01983C4.56204 7.26644 3.3397 7.25032 2.57809 7.98389C1.82077 8.71333 1.80557 9.90846 2.54411 10.6563L9.23185 17.4286L19.3508 7.24888C20.0951 6.50003 20.0886 5.30083 19.336 4.55994Z"
        fill="#27AE60"
      />
    </svg>
  )
}

export const CorrectTickIcon = React.memo(SvgComponent)
