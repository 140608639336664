import React from 'react'

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '30'}
      height={props.height || '23'}
      viewBox="0 0 30 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M5.19601 12.1578C4.128 12.1578 3.2622 11.292 3.2622 10.224C3.2622 9.15595 4.12799 8.29016 5.19601 8.29016H16.3162C17.3842 8.29016 18.25 9.15595 18.25 10.224C18.25 11.292 17.3842 12.1578 16.3162 12.1578H5.19601ZM9.49972 20.0924C8.39867 20.0924 7.5061 19.1998 7.5061 18.0988C7.5061 16.9977 8.39867 16.1051 9.49972 16.1051H11.7564C12.8574 16.1051 13.75 16.9977 13.75 18.0988C13.75 19.1998 12.8574 20.0924 11.7564 20.0924H9.49972ZM2.22368 4.26304C1.13365 4.26304 0.25 3.37939 0.25 2.28936C0.25 1.19932 1.13365 0.315674 2.22368 0.315674H20.2763C21.3664 0.315674 22.25 1.19932 22.25 2.28936C22.25 3.37939 21.3664 4.26304 20.2763 4.26304H2.22368Z"
        fill={props.color || '#434343'}
      />
      <path
        d="M29.25 16C29.25 15.0335 28.4665 14.25 27.5 14.25H24V10.75C24 9.7835 23.2165 9 22.25 9C21.2835 9 20.5 9.7835 20.5 10.75V14.25H17C16.0335 14.25 15.25 15.0335 15.25 16C15.25 16.9665 16.0335 17.75 17 17.75H20.5V21.25C20.5 22.2165 21.2835 23 22.25 23C23.2165 23 24 22.2165 24 21.25V17.75H27.5C28.4665 17.75 29.25 16.9665 29.25 16Z"
        fill={props.color || '#434343'}
      />
    </svg>
  )
}

export const SortIcon = React.memo(SVGComponent)
