import React, { useContext } from 'react'

import './Commission.scss'
import { Rate } from './components/Rate/Rate'
import { Footer } from '../../components/Footer/Footer'
import { CommissionStateProps } from './commission.interface'
import { observer } from 'mobx-react-lite'
import { CampaignRegistrationVm } from '../../campaignRegistration.vm'
import { useHistory } from 'react-router-dom'

export const Commission = observer(({ onClickBackButton, stage }: CommissionStateProps) => {
  const { formData, updateFormData, createCampaign, updateCampaign, isEditing } = useContext(CampaignRegistrationVm)

  const history = useHistory()

  const onSubmit = async () => {
    try {
      if (isEditing) {
        await updateCampaign()
        alert('Campaign was updated')
      } else {
        await createCampaign()
        alert('Campaign was created')
      }
      history.push('/')
    } catch (error) {
      alert('Failed to create campaign')
    }
  }

  return (
    <div className="campaignRegistration__commission">
      <div className="container">
        <Rate
          onChange={(event) => updateFormData({ commission: parseInt(event.target.value) })}
          value={String(formData.commission)}
        />
      </div>
      <Footer onClickNextButton={onSubmit} onClickBackButton={onClickBackButton} stage={stage} />
    </div>
  )
})
