import { Spacings } from './Spacings'

export const resolveStyledSpacings = (props: Spacings): string => {
  let result = ''
  if (props.mb) {
    result += `margin-bottom: ${props.mb}px;`
  }
  if (props.ml) {
    result += `margin-left: ${props.ml}px;`
  }
  if (props.mr) {
    result += `margin-right: ${props.mr}px;`
  }
  if (props.mt) {
    result += `margin-top: ${props.mt}px;`
  }
  if (props.mx) {
    result += `margin-right: ${props.mx}px;margin-left: ${props.mx}px;`
  }
  if (props.my) {
    result += `margin-top: ${props.my}px;margin-bottom: ${props.my}px;`
  }
  if (props.pb) {
    result += `padding-bottom: ${props.mb}px;`
  }
  if (props.pl) {
    result += `padding-left: ${props.pl}px;`
  }
  if (props.pr) {
    result += `padding-right: ${props.pr}px;`
  }
  if (props.pt) {
    result += `padding-top: ${props.pt}px;`
  }
  if (props.px) {
    result += `padding-right: ${props.px}px;padding-left: ${props.px}px;`
  }
  if (props.py) {
    result += `padding-top: ${props.py}px;padding-bottom: ${props.py}px;`
  }

  return result
}
