import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router-dom'

import { EmptyContainer, EmptyText, EmptyTitle, Header } from './Search.styles'
import { PageWrapper } from '../../containers/PageWrapper/PageWrapper'
import { SearchInput } from './components/SearchInput/SearchInput'
import { DisplaySettings } from '../../components/DisplaySettings/DisplaySettings'
import { SearchFilter } from './components/SearchFilter/SearchFilter'
import { ProductList } from '../../components/DisplaySettings/ProductList'
import { SearchVm } from './Search.vm'
import { Loader } from '../../components/Loader'
import { ICategoryNames } from '../../components/Categories/CategoryItem/CategoryItem.types'

interface Params {
  query?: string
  category?: ICategoryNames
}

export const Search = observer(() => {
  const [
    {
      display,
      input,
      products,
      categories,
      isFetching,
      setInput,
      setDisplay,
      updateCategories,
      getCategoryStatus,
      fetchAllCampaigns,
      fetchCampaigns,
    },
  ] = useState(new SearchVm())
  const { state } = useLocation<Params>()

  useEffect(() => {
    const initFetchCampaigns = async () => {
      await fetchAllCampaigns()
    }
    initFetchCampaigns()
  }, [fetchAllCampaigns])

  useEffect(() => {
    fetchCampaigns()
  }, [input, categories, fetchCampaigns])

  useEffect(() => {
    !!state?.query && setInput(state.query)
    !!state?.category && updateCategories(state.category)
  }, [setInput, state?.category, state?.query, updateCategories])

  const renderResult = () => {
    if (isFetching) {
      return <Loader />
    } else {
      if (products.length > 0) {
        return <ProductList display={display} products={products} />
      } else {
        return (
          <EmptyContainer>
            <EmptyTitle>No search results</EmptyTitle>
            <EmptyText>Type something else or change your filter settings</EmptyText>
          </EmptyContainer>
        )
      }
    }
  }

  return (
    <PageWrapper style={{ background: '#F7F7FA' }}>
      <Header>
        <SearchInput value={input} onChange={setInput} />
        <DisplaySettings display={display} setDisplay={setDisplay} country="All countries" />
      </Header>
      <SearchFilter onClick={updateCategories} getCategoryStatus={getCategoryStatus} />
      {renderResult()}
    </PageWrapper>
  )
})
