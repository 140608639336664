import React from 'react'

import { Icon } from '../../../../components'
import { Wrapper, Container, Title, CardText, CopyContainer, CopyText } from './CommunityCard.style'

export const CommunityCard = () => (
  <Wrapper>
    <Container>
      <Icon name="logo-sliced" width={186} height={80} />
      <Title>i co-buy</Title>
      <CardText>Hey! Join us in wecobi</CardText>
      <CopyContainer>
        <Icon name="copy" width={26} height={30} color="#e6e6e6" />
        <CopyText>Click to copy the link</CopyText>
      </CopyContainer>
    </Container>
  </Wrapper>
)
