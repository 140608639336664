import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import { CompanyService } from '../../services/entities/company.service'
import { CompanyStore } from '../../store/company.store'
import { CampaignExpandable } from '../../components/Order/OrderExpandedable/OrderExpandable'
import { PageWrapper } from '../../containers/PageWrapper/PageWrapper'
import { AccountSlider } from '../../components/AccountSlider/AccountSlider'

export const CampaignList = observer(() => {
  const { companyData, campaignsList, setCampaigns } = useContext(CompanyStore)

  useEffect(() => {
    const getCampaigns = async () => {
      if (companyData && companyData.id) {
        const campaigns = await CompanyService.getCampaigns(companyData.id)
        setCampaigns(campaigns)
      }
    }
    getCampaigns()
  }, [campaignsList.length, companyData, setCampaigns])

  return (
    <PageWrapper>
      <AccountSlider icon="price-tag" title="All campaigns" />
      {campaignsList.map((campaignOrder, index) => (
        <CampaignExpandable key={index} {...campaignOrder} />
      ))}
    </PageWrapper>
  )
})
