import React, { ChangeEvent, useState, useContext } from 'react'

import './ProductDetails.scss'
import { InputTextarea } from '../../components/Input/Input'
import { ProductImages } from './components/ProductImages/ProductImages'
import { CategoryBlock } from './components/Category/Category'
import { Location } from './components/Location/Location'
import { Footer } from '../../components/Footer/Footer'
import { ProductDetailsStateProps } from './productDetails.interface'
import { CategoriesPopup } from '../../components/CategoriesPopup'
import { observer } from 'mobx-react-lite'
import { CampaignRegistrationVm } from '../../campaignRegistration.vm'
import { CompanyStore } from '../../../../store/company.store'
import { ICategoryNames } from '../../../../components/Categories/CategoryItem/CategoryItem.types'

const COUNTRY_LIST = ['US', 'China', 'Sweden', 'Poland', 'Czech Republic', 'Switzerland']
const CITY_LIST = {
  US: ['New York', 'Los Angeles', 'Chicago'],
  China: ['Hong Kong', 'Macau', 'Beijing'],
  Sweden: ['Stockholm'],
  Poland: ['Warsaw', 'Krakow'],
  'Czech Republic': ['Prague'],
  Switzerland: ['Bern', 'Geneva'],
}

export const ProductDetails = observer(({ onClickBackButton, onClickNextButton, stage }: ProductDetailsStateProps) => {
  const [isOpenModal, setOpenModal] = useState<boolean>(false)
  const { formData, updateFormData } = useContext(CampaignRegistrationVm)
  const { companyData } = useContext(CompanyStore)

  const toggleCategory = (category: ICategoryNames) =>
    formData.categories.indexOf(category) === -1
      ? updateFormData({ categories: [...formData.categories, category] })
      : updateFormData({ categories: formData.categories.filter((cat) => cat !== category) })

  const openModal = () => setOpenModal(true)

  const chooseCountry = (currentCountry: string) =>
    updateFormData({ location: { ...formData.location, country: currentCountry } })
  const chooseCity = (currentCity: string) => updateFormData({ location: { ...formData.location, city: currentCity } })

  const countryProps = { country: formData.location.country, chooseCountry, countryList: COUNTRY_LIST }
  const cityProps = { city: formData.location.city, chooseCity, cityList: CITY_LIST }

  const onCategoryRemove = (value: string) =>
    updateFormData({ categories: formData.categories.filter((category) => category !== value) })

  const onUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files?.item(0)
    const isImageUnique = formData.images.findIndex((currentImage) => currentImage.name === image?.name) === -1
    if (image && isImageUnique) {
      const newImagesSet = [...formData.images]
      newImagesSet.push(image)
      updateFormData({ images: newImagesSet })
    }
  }

  const onImageRemove = (name: string) =>
    updateFormData({ images: formData.images.filter((image) => image.name !== name) })

  const onNext = () => {
    if (companyData && companyData.id && companyData.companyName) {
      updateFormData({ companyId: companyData.id, companyName: companyData.companyName })
      onClickNextButton?.()
    } else {
      alert('Error')
    }
  }

  return (
    <div className="campaignRegistrations__productDetails">
      <div className="container">
        <div className="title">Add the information about your campaign</div>
        <div className="campaign-title">
          <InputTextarea
            title="Campaign title"
            value={formData.title}
            onChange={(value) => updateFormData({ title: value.target.value })}
          />
        </div>
        <div className="short-description">
          <InputTextarea
            title="Short description"
            value={formData.description}
            onChange={(value) => updateFormData({ description: value.target.value })}
          />
        </div>
        <div className="short-description">
          <InputTextarea
            title="Product description"
            value={formData.fullDescription}
            onChange={(value) => updateFormData({ fullDescription: value.target.value })}
          />
        </div>
        <div className="product-images">
          <ProductImages images={formData.images} onUpload={onUpload} onImageRemove={onImageRemove} />
        </div>
        <div className="category">
          <CategoryBlock openModal={openModal} categories={formData.categories} onCategoryRemove={onCategoryRemove} />
        </div>
        <div className="location">
          <Location {...countryProps} {...cityProps} />
        </div>
      </div>
      <div className="campaignRegistrations__footer">
        <Footer onClickBackButton={onClickBackButton} onClickNextButton={onNext} stage={stage} />
      </div>
      <CategoriesPopup
        categories={formData.categories}
        toggleCategories={toggleCategory}
        isOpen={isOpenModal}
        setOpen={setOpenModal}
      />
    </div>
  )
})
