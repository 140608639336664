import { updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore'
import { DateTime } from 'luxon'
import { FirestoreService } from '../core/firestore.service'
import { UserService } from '../entities/user.service'

export interface CampaignLikeState {
  userLikes: string[]
  campaignLikedBy: string[]
}

class CampaignManager {
  private getLiked = async (campaignId: string, userId: string): Promise<CampaignLikeState> => {
    const { campaignData } = await FirestoreService.getCampaignById(campaignId)
    const { userData } = await FirestoreService.getUserById(userId)
    return { userLikes: userData.liked, campaignLikedBy: campaignData.liked }
  }

  public fetchCampaignImagesFromStorage = async (campaignId: string, images: string[]) => {
    const { campaignRef } = await FirestoreService.getCampaignById(campaignId)
    await updateDoc(campaignRef, { images })
  }

  public likeCampaign = async (campaignId: string, userId: string) => {
    const { campaignRef } = await FirestoreService.getCampaignById(campaignId)
    const { userRef } = await FirestoreService.getUserById(userId)
    await updateDoc(campaignRef, { liked: arrayUnion(userId) })
    await updateDoc(userRef, { liked: arrayUnion(campaignId) })
    return await this.getLiked(campaignId, userId)
  }

  public removeLikeCampaign = async (campaignId: string, userId: string) => {
    const { campaignRef } = await FirestoreService.getCampaignById(campaignId)
    const { userRef } = await FirestoreService.getUserById(userId)
    await updateDoc(campaignRef, { liked: arrayRemove(userId) })
    await updateDoc(userRef, { liked: arrayRemove(campaignId) })
    return await this.getLiked(campaignId, userId)
  }

  public fetchAvatars = async (buyers: string[]) => {
    const result: string[] = []
    await Promise.all(
      buyers.map(async (buyer) => {
        if (result.length < 5) {
          try {
            const avatar = await UserService.getAvatar(buyer)
            result.push(avatar)
          } catch (error) {
            return
          }
        }
        return
      })
    )
    return result
  }

  public getBuyers = async (campaignId: string) => {
    const { campaignData } = await FirestoreService.getCampaignById(campaignId)
    if (campaignData.buyers.length > 0) {
      return Promise.all(
        campaignData.buyers.map(async (userId) => {
          const { userData } = await FirestoreService.getUserById(userId)
          return userData
        })
      )
    }
    return []
  }

  public cancelCampaign = async (campaignId: string) => {
    await FirestoreService.updateCampaign(campaignId, { status: 'cancelled', cancelDate: DateTime.now().toISODate() })
  }

  public activateCampaign = async (campaignId: string) => {
    await FirestoreService.updateCampaign(campaignId, { status: 'active', cancelDate: null })
  }
}

export const CampaignService = new CampaignManager()
