import React from 'react'

import { SwitcherWrapper, Content } from './Switcher.style'
import { RadioButtonItem } from '../../../components/RadioButtonItem/RadioButtonItem'

interface SwitcherProps {
  titles: string[]
  name: string
  active: number
  setActive: React.Dispatch<React.SetStateAction<number>>
  isHidden: boolean
}

export const Switcher = ({ titles, name, active, setActive, isHidden }: SwitcherProps) => {
  return (
    <SwitcherWrapper isHidden={isHidden}>
      <Content>
        {titles.map((title, index) => (
          <RadioButtonItem name={name} title={title} key={index} active={active} index={index} setActive={setActive} />
        ))}
      </Content>
    </SwitcherWrapper>
  )
}
