import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={33} height={23} viewBox="0 0 33 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M29.75 18.125h-5.3V8.85h5.3v9.275zM31.075 6.2h-7.95A1.325 1.325 0 0021.8 7.525v13.25a1.325 1.325 0 001.325 1.325h7.95a1.325 1.325 0 001.325-1.325V7.525A1.325 1.325 0 0031.075 6.2zM5.9 3.55h23.85V.9H5.9a2.65 2.65 0 00-2.65 2.65v14.575H.6V22.1h18.55v-3.975H5.9V3.55z"
        fill={props.color || '#FF6D0A'}
      />
    </svg>
  )
}

export const CellphoneIcon = React.memo(SvgComponent)
