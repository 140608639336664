import React from 'react'

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '255'}
      height={props.height || '172'}
      viewBox="0 0 255 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.469 109.611L71.1286 42.0448C65.511 31.6 52.4899 27.6868 42.0451 33.3044C31.6003 38.922 27.6871 51.9432 33.3048 62.388L69.6448 129.955C75.2624 140.399 88.2836 144.313 98.7283 138.695C109.173 133.077 113.086 120.056 107.469 109.611ZM33.9546 18.2618C15.202 28.3477 8.17623 51.7259 18.2621 70.4785L54.6022 138.045C64.6881 156.798 88.0663 163.823 106.819 153.738C125.571 143.652 132.597 120.273 122.511 101.521L86.1712 33.9542C76.0854 15.2017 52.7071 8.17591 33.9546 18.2618Z"
        fill={props.color || '#FF6D0A'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.393 109.611L147.053 42.0448C141.435 31.6 128.414 27.6868 117.969 33.3044C107.525 38.922 103.611 51.9432 109.229 62.388L145.569 129.955C151.187 140.399 164.208 144.313 174.653 138.695C185.097 133.077 189.011 120.056 183.393 109.611ZM109.879 18.2618C91.1263 28.3477 84.1005 51.7259 94.1864 70.4785L130.526 138.045C140.612 156.798 163.991 163.823 182.743 153.738C201.496 143.652 208.522 120.273 198.436 101.521L162.096 33.9542C152.01 15.2017 128.631 8.17591 109.879 18.2618Z"
        fill={props.color || '#FF6D0A'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.2664 109.611L107.606 42.0448C113.224 31.6 126.245 27.6868 136.69 33.3044C147.135 38.922 151.048 51.9432 145.43 62.388L109.09 129.955C103.473 140.399 90.4516 144.313 80.0068 138.695C69.562 133.077 65.6488 120.056 71.2664 109.611ZM144.781 18.2618C163.533 28.3477 170.559 51.7259 160.473 70.4785L124.133 138.045C114.047 156.798 90.6688 163.823 71.9163 153.738C53.1637 143.652 46.1379 120.273 56.2238 101.521L92.5639 33.9542C102.65 15.2017 126.028 8.17591 144.781 18.2618Z"
        fill={props.color || '#FF6D0A'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.191 109.611L183.532 42.0448C189.149 31.6 202.17 27.6868 212.615 33.3044C223.06 38.922 226.973 51.9432 221.355 62.388L185.015 129.955C179.398 140.399 166.377 144.313 155.932 138.695C145.487 133.077 141.574 120.056 147.191 109.611ZM220.706 18.2618C239.458 28.3477 246.484 51.7259 236.398 70.4785L200.058 138.045C189.972 156.798 166.594 163.823 147.841 153.738C129.089 143.652 122.063 120.273 132.149 101.521L168.489 33.9542C178.575 15.2017 201.953 8.17591 220.706 18.2618Z"
        fill={props.color || '#FF6D0A'}
      />
    </svg>
  )
}

export const LogoIcon = React.memo(SVGComponent)
