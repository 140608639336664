import React, { useState, useContext, useEffect, ChangeEvent } from 'react'
import { observer } from 'mobx-react-lite'

import { Loader } from '../../../../components/Loader'
import { PageWrapper } from '../../../../containers/PageWrapper/PageWrapper'
import { CompanyStore } from '../../../../store/company.store'
import { Header } from '../shared/Header'
import { ActionBar } from './components/ActionBar'
import { BrandAccountVm } from '../shared/BrandAccount.vm'
import { DisplayGrid } from '../../../../components/DisplaySettings/displays/Displays'
import { UserStore } from '../../../../store/user.store'

export const MyBrandAccount = observer(() => {
  const { companyData, updateLogo, removeLogo } = useContext(CompanyStore)
  const { userData } = useContext(UserStore)
  const [vm, setVm] = useState<BrandAccountVm | undefined>(undefined)

  const onLogo = async (e: ChangeEvent<HTMLInputElement>) => {
    if (userData?.currentCompany === companyData?.id) {
      const image = e.target.files?.item(0)
      if (image) {
        try {
          await updateLogo(image)
        } catch (error) {
          alert('Error uploading logo...')
        }
      }
    }
  }

  useEffect(() => {
    if (companyData?.id) {
      setVm(new BrandAccountVm(companyData.id, companyData))
    }
  }, [companyData])

  useEffect(() => {
    if (vm) {
      vm.fetchData()
    }
  }, [vm])

  return (
    <PageWrapper>
      {vm && vm?.brandData ? (
        <>
          <Header
            name={vm.brandData.companyName}
            username={vm.brandData.companyUsername}
            shared={vm.shared}
            followed={vm.followed}
            modalData={{
              description: vm.brandData.description,
              email: vm.brandData.email,
              contactNumber: vm.brandData.contactNumber,
              socials: vm.brandData.socials,
            }}
            image={vm.brandData.logo}
            onLogo={onLogo}
            onRemoveLogo={removeLogo}
          />
          <ActionBar />
          {vm?.campaigns && <DisplayGrid products={vm?.campaigns} />}
        </>
      ) : (
        <Loader absoluteCenter />
      )}
    </PageWrapper>
  )
})
