import styled from 'styled-components'
import { COLORS } from '../../../../config/constants'
import { Button } from '../../../Button/Button'

export const ButtonsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex: 1;
  justify-content: space-between;
`

export const FooterContainer = styled.div`
  margin-top: 18px;
  display: flex;
`

export const BottomLink = styled.div`
  margin-top: 8px;
`

const StyledButton = styled(Button)`
  display: flex;
  border-radius: 4px;
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 9px;
  padding-bottom: 9px;
  text-align: center;
  justify-content: center;
`

export const LeftButton = styled(StyledButton)`
  margin-right: 10px;
  color: ${COLORS.COLOR_BLACK};
  background-color: ${COLORS.COLOR_LIGHT_GRAY_2};
`

export const RightButton = styled(StyledButton)`
  flex: 1;
  color: ${COLORS.COLOR_BLACK};
  background: linear-gradient(270deg, #eb4dbf 0%, #f98140 100%);
`

export const LeftButtonContainer = styled.div`
  flex: 4;
`

export const RightButtonContainer = styled.div`
  flex: 1;
`
