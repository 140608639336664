import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={27} height={31} viewBox="0 0 27 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M23.375 27.5H8.25V8.25h15.125V27.5zm0-22H8.25A2.75 2.75 0 005.5 8.25V27.5a2.75 2.75 0 002.75 2.75h15.125a2.75 2.75 0 002.75-2.75V8.25a2.75 2.75 0 00-2.75-2.75zM19.25 2.75A2.75 2.75 0 0016.5 0H2.75A2.75 2.75 0 000 2.75v16.5A2.75 2.75 0 002.75 22V2.75h16.5z"
        fill={props.color || '#fff'}
      />
    </svg>
  )
}

export const CopyIcon = React.memo(SvgComponent)
