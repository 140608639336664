import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 800px;
  position: relative;
  margin-bottom: 100px;
`

export const ProductFull = styled.div`
  width: 100%;
  position: absolute;
  top: 360px;
  right: 0;
  padding-right: 12px;
  padding-left: 12px;
`
