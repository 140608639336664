import { action, makeAutoObservable, observable } from 'mobx'
import { UserStoreVm } from '../../../../store/user.store'
import { ChangeEvent } from 'react'
import { ShippingData } from '../../../../types/user.types'
import { FirestoreService } from '../../../../services/core/firestore.service'

const DEFAULT_SHIPPING_DATA: ShippingData = {
  country: '',
  city: '',
  street: '',
  streetNumber: '',
  zipCode: '',
}

export class SettingsVm {
  userStore?: UserStoreVm
  formLoading = false
  locationName = ''
  username?: string = ''
  name?: string = ''
  locations?: string[] = []
  error = ''
  shippingData: ShippingData = DEFAULT_SHIPPING_DATA

  constructor(userStore: UserStoreVm) {
    this.userStore = userStore
    this.username = userStore.userData?.username
    this.name = userStore.userData?.name
    this.locations = userStore.userData?.deliveryLocations
    this.shippingData = userStore.userData?.shippingData || DEFAULT_SHIPPING_DATA

    makeAutoObservable(this, {
      shippingData: observable,
      setShippingCountry: action,
      setShippingCity: action,
      setShippingStreet: action,
      setShippingStreetNumber: action,
      setShippingZipCode: action,
      deleteLocation: action,
      setLocationName: action,
      setName: action,
      setUsername: action,
      addLocation: action,
    })
  }

  private setError(error: string) {
    this.error = error
  }

  public setShippingCountry = (value: string) => {
    this.shippingData.country = value
  }

  public setShippingCity = (value: string) => {
    this.shippingData.city = value
  }

  public setShippingStreet = (value: string) => {
    this.shippingData.street = value
  }

  public setShippingStreetNumber = (value: string) => {
    this.shippingData.streetNumber = value
  }

  public setShippingZipCode = (value: string) => {
    this.shippingData.zipCode = value
  }

  public addAvatar(event: ChangeEvent<HTMLInputElement>) {
    if (this.userStore) {
      try {
        this.userStore?.onAddAvatar(event)
      } catch (e: any) {
        this.setError(e.message)
      }
    }
  }

  public addLocation(location: string) {
    if (this.locations && this.locations?.length > 0) {
      this.userStore?.addDeliveryLocations([...this.locations, location])
      this.locations = [...this.locations, location]
    } else {
      this.userStore?.addDeliveryLocations([location])
      this.locations = [location]
    }
  }

  public deleteLocation(name: string) {
    if (this.locations) {
      this.userStore?.addDeliveryLocations(this.locations.filter((location) => location !== name))
      this.locations = this.locations.filter((location) => location !== name)
    }
  }

  public setLocationName(name: string) {
    this.locationName = name
  }

  public setName(name: string) {
    this.name = name
  }

  public setUsername(name: string) {
    this.username = name
  }

  public canSaveChanges() {
    return (
      this.userStore &&
      (this.userStore.userData?.name !== this.name ||
        (this.userStore.getUsername() as string) !== this.username ||
        this.locationName.length > 0)
    )
  }

  public onSubmit = async () => {
    this.formLoading = true

    if (this.locationName) {
      this.addLocation(this.locationName)
      this.setLocationName('')
    }

    if (this.userStore) {
      if (this.name && this.userStore.userData?.name !== this.name) {
        await this.userStore.updateName(this.name)
      }

      if (this.username && this.userStore.userData?.username !== this.username) {
        try {
          await this.userStore.updateUsername(this.username)
        } catch (e: any) {
          this.setError(e.message)
        }
      }

      if (
        this.userStore.userData &&
        JSON.stringify(this.userStore.userData?.shippingData) !== JSON.stringify(this.shippingData)
      ) {
        await FirestoreService.updateUser(this.userStore.userData.uid, { shippingData: this.shippingData })
      }
    }
    this.formLoading = false
  }
}
