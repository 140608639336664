import styled from 'styled-components'
import { TEXT_DEFAULT_STYLES } from '../../../../config/styles'
import { COLORS } from '../../../../config/constants'

const CIRCLE_SIZE = 42

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 44px;
`

export const Input = styled.input`
  width: 100%;
  height: 100%;
  border: 1px solid #d5d5d5;
  border-radius: 59px;
  outline: none;
  padding: 0 ${CIRCLE_SIZE + 15}px 0 15px;
  &:focus {
    border-color: ${COLORS.COLOR_ORANGE_1};
  }
  ${TEXT_DEFAULT_STYLES}
`
export const Circle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding-left: 2px;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: ${CIRCLE_SIZE}px;
  height: ${CIRCLE_SIZE}px;
  border: none;
  border-radius: 50%;
  background-color: ${COLORS.COLOR_GRAY_1};
`
