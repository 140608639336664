import { createContext } from 'react'
import { observable, makeObservable, action } from 'mobx'
import { FirestoreService } from '../../services/core/firestore.service'
import { StorageService } from '../../services/core/storage.service'
import { defaultCampaignRegistrationForm } from './campaignRegistration.helpers'
import { CampaignFormData } from './campaignRegistration.interface'
import { CampaignService } from '../../services/entities/campaign.service'
import { StripeProductParams, StripeService } from '../../services/core/stripe.service'
import { DateTime } from 'luxon'

export class CampaignRegistrationVmClass {
  isEditing = false
  campaignId: string | null = null
  formData: CampaignFormData = {
    companyId: '',
    companyName: '',
    title: '',
    description: '',
    fullDescription: '',
    images: [],
    categories: [],
    location: {
      country: '',
      city: '',
    },
    price: 0,
    retailPrice: 0,
    goal: 0,
    orderMoreThanOne: false,
    isDurationActive: false,
    commission: 0,
    status: 'active',
    liked: [],
    shared: [],
    buyers: [],
    startDate: DateTime.now().toISODate(),
    subscribeBuyers: [],
  }

  constructor() {
    makeObservable(this, {
      formData: observable,
      updateFormData: action,
      setCampaignId: action,
    })
  }

  public updateFormData = (data: Partial<CampaignFormData>) => {
    this.formData = { ...this.formData, ...data }
  }

  public setCampaignId = async (id: string) => {
    const { campaignData } = await FirestoreService.getCampaignById(id)
    this.campaignId = id
    this.updateFormData({ ...campaignData, images: [] })
    this.isEditing = true
  }

  private getImages = async (campaignId: string) => await StorageService.getListData(`campaigns/${campaignId}`)

  public updateCampaign = async () => {
    const { images, ...data } = this.formData
    if (this.campaignId) {
      await FirestoreService.updateCampaign(this.campaignId, data)
    }
  }

  public createCampaign = async () => {
    const stripeData: Omit<StripeProductParams, 'images'> = {
      name: this.formData.title,
      description: this.formData.description,
      price: this.formData.price,
    }
    try {
      const { images, ...formData } = this.formData
      const startDate = DateTime.now().toISODate()
      const endDate = formData.campaignDuration
        ? DateTime.now().plus({ days: formData.campaignDuration }).toISODate()
        : null
      const campaignId = await FirestoreService.addCampaign({ ...formData, startDate, endDate })
      await StorageService.addCampaignImages(campaignId, images)
      const imagesPaths = await this.getImages(campaignId)
      await CampaignService.fetchCampaignImagesFromStorage(campaignId, imagesPaths)
      const { productId, priceId } = await StripeService.createProduct({ ...stripeData, images: imagesPaths })
      await FirestoreService.updateCampaign(campaignId, { stripeId: productId, stripePriceId: priceId })
      this.formData = defaultCampaignRegistrationForm
    } catch (error) {
      alert('Failed to create campaign')
    }
  }
}

export const CampaignRegistrationVm = createContext(new CampaignRegistrationVmClass())
