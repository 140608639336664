import React from 'react'

import { AccountAvatar, AccountInfoWrapper, AccountName, AccountId } from './AccountInfo.style'
import { Text } from '../../../../components/Text'
import { COLORS } from '../../../../config/constants'
import { Activity } from './Activity/Activity'
import { Actions } from './Actions/Actions'
import { AccountInfoProps } from './AccountInfo.interface'
import default_account_avatar from '../../../../assets/images/DefaultAccountAvatar.svg'
import default_my_account_avatar from '../../../../assets/images/DefaultMyAccountAvatar.svg'
import { observer } from 'mobx-react-lite'
import { Icon } from '../../../../components'

export const AccountInfo = observer(
  ({
    title,
    username,
    activity,
    avatar,
    onAvatar,
    type = 'people-view',
    isFollowed,
    onFollow,
    onUnfollow,
    onRemoveAvatar,
  }: AccountInfoProps) => {
    const renderUserAvatar = () => {
      if (type === 'people-view') {
        return avatar || default_account_avatar
      }
      return avatar || default_my_account_avatar
    }

    return (
      <AccountInfoWrapper>
        <AccountAvatar>
          <div>
            {onRemoveAvatar && avatar && (
              <div className="cross-avatar" onClick={onRemoveAvatar}>
                <Icon name="cross" width={12} height={12} color="black" />
              </div>
            )}
            <img src={renderUserAvatar()} alt="avatar" />
            {type === 'owner-view' && <input type="file" name="image" accept=".jpeg, .png, .jpg" onChange={onAvatar} />}
          </div>
        </AccountAvatar>
        <AccountName>
          <Text size={20} weight={400} lineHeight="22px" letterSpacing={-0.41}>
            {title}
          </Text>
        </AccountName>
        <AccountId>
          <Text color={COLORS.COLOR_ORANGE_1}>{username}</Text>
        </AccountId>
        <Activity {...activity} username={username} />
        {type === 'people-view' ? (
          <Actions isFollowed={!!isFollowed} onFollow={onFollow} onUnfollow={onUnfollow} />
        ) : null}
      </AccountInfoWrapper>
    )
  }
)
