import React from 'react'

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={'20' || props.width}
      height={'19' || props.height}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M7.5238 0.851074C9.34337 0.851074 11.0884 1.53138 12.3751 2.74233C13.6617 3.95328 14.3845 5.59568 14.3845 7.30822C14.3845 8.9076 13.7618 10.3778 12.7379 11.5103L13.0229 11.7785H13.8568L19.1342 16.7456L17.551 18.2357L12.2735 13.2687V12.4839L11.9885 12.2156C10.7853 13.1792 9.22315 13.7654 7.5238 13.7654C5.70423 13.7654 3.95918 13.0851 2.67254 11.8741C1.38591 10.6632 0.663086 9.02076 0.663086 7.30822C0.663086 5.59568 1.38591 3.95328 2.67254 2.74233C3.95918 1.53138 5.70423 0.851074 7.5238 0.851074ZM7.5238 2.83789C4.88506 2.83789 2.77407 4.8247 2.77407 7.30822C2.77407 9.79173 4.88506 11.7785 7.5238 11.7785C10.1625 11.7785 12.2735 9.79173 12.2735 7.30822C12.2735 4.8247 10.1625 2.83789 7.5238 2.83789Z"
        fill={'white' || props.color}
      />
    </svg>
  )
}

export const SearchInputIcon = React.memo(SVGComponent)
