import styled from 'styled-components'

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 31px;
  padding-right: 31px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
`

export const ItemUserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const ItemIcon = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 14px;

  & img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`
