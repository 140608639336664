import React from 'react'

import { ParticipantsListProps } from './PraticipantsList.interface'
import { ParticipantsListWrapper, Image } from './ParticipantsList.style'

export const ParticipantsList = ({ width, imageWidth, images }: ParticipantsListProps) => {
  return (
    <ParticipantsListWrapper width={width}>
      {images.map((image, index) => {
        return (
          <Image
            key={index}
            left={(imageWidth / 2) * (index + 1)}
            zIndex={index}
            width={imageWidth}
            src={image}
            alt="img"
          />
        )
      })}
    </ParticipantsListWrapper>
  )
}
