import styled from 'styled-components'
import MainImage from '../../../../assets/images/home-main.png'

//MAIN CONTAINER

export const Wrapper = styled.div`
  width: 100%;
  height: 585px;
  background-image: url(${MainImage});
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.838542) 34.38%,
      rgba(255, 255, 255, 0.672164) 74.48%,
      rgba(255, 255, 255, 0) 100%
    );
    width: 100%;
    height: 585px;
  }
`

export const TextContainer = styled.div`
  width: 300px;
  display: flex;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 110px;
  margin-bottom: 160px;
`
