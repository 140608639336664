import React, { useState, useRef, useCallback } from 'react'
import ImageUploader from 'react-images-upload'
import NumericInput from 'react-numeric-input'

import './Basics.scss'
import { BasicsStateProps } from './basics.interfaces'
import {
  titleDescription,
  goalDescription,
  collaboratorsDescription,
  currencyOptions,
  msDateTime,
} from './basics.config'
import { SelectInput, DatePickerInput } from '../../../../components/Input/Input'
import { Block } from '../../../../containers/Block/Block'

export const Basics = () => {
  const imageUploaderRef: any = useRef('')

  const [state, setState] = useState<BasicsStateProps>({
    pictures: [],
    category: '',
    subcategory: '',
    country: '',
    duration: {
      days: 1,
      endDate: new Date(Date.now() + msDateTime),
    },
    currency: 'USD',
    count: undefined,
    collaborators: [],
  })

  const onDrop = useCallback(
    (picture: any) => {
      setState({
        ...state,
        pictures: state.pictures.concat(picture),
      })
    },
    [state]
  )

  const onChangeDays = useCallback(
    (number: number) => {
      setState({
        ...state,
        duration: {
          ...state.duration,
          days: number,
          endDate: new Date(Date.now() + msDateTime * number),
        },
      })
    },
    [state]
  )

  const onChangeDate = useCallback(
    (date: Date) => {
      const startDate = Date.now()
      const resultDays = (date.getTime() - startDate) / msDateTime
      setState({
        ...state,
        duration: {
          ...state.duration,
          days: Math.round(resultDays),
          endDate: date,
        },
      })
    },
    [state]
  )

  const onChange = useCallback(
    (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      e.target.name && setState({ ...state, [e.target.name]: e.target.value })
    },
    [state]
  )

  const formatDateInput = useCallback(
    (num: number | null) => (num ? (+num === 1 && `${num} day`) || `${num} days` : 'null days'),
    []
  )

  return (
    <div className="basics__wrapper">
      <Block label="Campaign image">
        <ImageUploader
          withIcon
          buttonText="Choose image"
          onChange={onDrop}
          imgExtension={['.jpg', '.gif', '.png', '.gif']}
          singleImage
          withPreview
          ref={imageUploaderRef}
          labelStyles={{ fontSize: '1.8rem' }}
        />
      </Block>
      <Block label="Campaign title" description={titleDescription}>
        <input maxLength={200} placeholder="title" className="input" type="text" />
        <div className="input-description">max length: 200 characters</div>
      </Block>
      <Block label="Short campaign description">
        <textarea maxLength={1000} placeholder="description" className="textarea" />
        <div className="input-description">max length: 1000 characters</div>
      </Block>
      <Block label="Campaign category">
        <div className="selectInput">
          <SelectInput
            label="category"
            options={['test 1', 'test 2', 'test 3']}
            value={state.category}
            onChange={onChange}
            name="category"
          />
          <SelectInput
            label="Sub category"
            options={['test 1', 'test 2', 'test 3']}
            value={state.subcategory}
            onChange={onChange}
            name="subcategory"
          />
        </div>
      </Block>
      <Block label="Campaign location">
        <div className="selectInput">
          <SelectInput
            label="Country"
            options={[
              'test 1',
              'test 2',
              'test 3',
              'test 4',
              'test 5',
              'test 6',
              'test 7',
              'test 8',
              'test 9',
              'test 10',
              'test 11',
              'test 12',
              'test 13',
              'test 14',
              'test 15',
              'test 16',
              'test 17',
              'test 18',
            ]}
            value={state.country}
            onChange={onChange}
            name="country"
          />
        </div>
      </Block>
      <Block label="Campaign duration">
        <NumericInput
          min={1}
          max={365}
          value={state.duration.days}
          onChange={(number: any) => onChangeDays(number)}
          format={formatDateInput}
        />
        <DatePickerInput value={state.duration.endDate} onChange={onChangeDate} />
      </Block>
      <Block label="Campaign goal" description={goalDescription}>
        <div className="selectInput">
          <SelectInput
            label="Currency"
            options={currencyOptions}
            value={state.currency}
            onChange={onChange}
            name="currency"
          />
        </div>
        <NumericInput
          className="numeric"
          min={0}
          value={state.count}
          onChange={(value: any) => setState({ ...state, count: value })}
          placeholder="price"
        />
      </Block>
      <Block label="Campaign collaborators" description={collaboratorsDescription}>
        <input type="text" className="input" placeholder="Emails of collaborators" />
        <div className="input-description">should be pressed separated by space</div>
      </Block>
    </div>
  )
}
