import styled from 'styled-components'
import { COLORS } from '../../../config/constants'
import { FOOTER_HEIGHT } from '../../Footer/Footer'

export interface ButtonProps {
  isFullWidth?: boolean
  isRounded?: boolean
  isBottomFixed?: boolean
  isDisabled?: boolean
}

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const Button = styled.button<ButtonProps>`
  ${({ isFullWidth }) => isFullWidth && 'width: 100%;'}
  border-radius: ${({ isRounded }) => (isRounded ? '41px' : '6px')};
  ${({ isBottomFixed }) => isBottomFixed && `position: fixed; bottom: ${18 + FOOTER_HEIGHT}px;`}
  min-width: 20em;
  border: none;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  outline: none;
  font-family: 'Montserrat';
  padding: 14px 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 157.2%;
  background-color: ${({ isDisabled }) => (isDisabled ? COLORS.COLOR_LIGHT_GRAY_1 : COLORS.COLOR_DARK_GRAY)};
  color: ${COLORS.COLOR_WHITE};
`
