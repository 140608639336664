import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={22} height={16} viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.125 9.5c-2.25 0-6.75 1.125-6.75 3.375a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25c0-2.25-4.5-3.375-6.75-3.375zm0-2.25a3.375 3.375 0 100-6.75 3.375 3.375 0 000 6.75zm-5.625 0c0-.621-.504-1.125-1.125-1.125h-2.25v-2.25a1.125 1.125 0 00-2.25 0v2.25h-2.25a1.125 1.125 0 000 2.25h2.25v2.25a1.125 1.125 0 002.25 0v-2.25h2.25c.621 0 1.125-.504 1.125-1.125z"
        fill="#434343"
      />
    </svg>
  )
}

export const AccountPlusIcon = React.memo(SvgComponent)
