import React from 'react'
import './Block.scss'
import { BlockProps } from './block.interface'

export const Block = ({ label, description, align = 'center', children }: BlockProps) => {
  const descriptionStyle = { textAlign: align }

  return (
    <div className="block">
      <div className="block__input-label">{label}</div>
      <div className="block__input-info">
        <div className="input">{children}</div>
        <div
          style={description ? { ...descriptionStyle, display: 'flex' } : { ...descriptionStyle, display: 'none' }}
          className="description">
          {description}
        </div>
      </div>
    </div>
  )
}
