import React from 'react'

import { DisplayType } from './DisplaySettings.types'
import { DisplayGrid, DisplayVertical } from './displays/Displays'
import { ProductProps } from '../Product/Product.interfaces'

interface ProductListProps {
  display: DisplayType
  products: ProductProps[]
}

export const ProductList = ({ display, products }: ProductListProps) => {
  const renderList = () => {
    if (display === 'grid') {
      return <DisplayGrid products={products} />
    } else if (display === 'vertical') {
      return <DisplayVertical products={products} />
      // } else if (display === 'horizontal') {
      //   return <DisplayHorizontal />
    } else {
      return null
    }
  }

  return <>{renderList()}</>
}
