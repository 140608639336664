import React from 'react'

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect y="1.38995" width="1.52167" height="10.7619" transform="rotate(-45 0 1.38995)" fill="#434343" />
      <rect x="14.687" width="1.35965" height="11.3683" transform="rotate(45 14.687 0)" fill="#434343" />
    </svg>
  )
}

export const ArrowDownIcon = React.memo(SVGComponent)
