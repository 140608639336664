import React from 'react'

import './InputRadioBlock.scss'
import { InputRadioBlockStateProps } from './inputRadioBlock.interface'
import { CustomRadio } from './CustomRadio/CustomRadio'

export const InputRadioBlock = ({
  title,
  description,
  leftRadioLabel,
  rightRadioLabel,
  name,
  isActive = true,
  setIsActive,
}: InputRadioBlockStateProps) => {
  return (
    <div className="price__inputRadioBlock">
      <div className="inputRadioBlock__title">{title}</div>
      <div className="inputRadioBlock__radioBlock">
        <CustomRadio
          isDefault={isActive}
          name={name}
          label={leftRadioLabel}
          onClick={() => !!setIsActive && setIsActive(true)}
        />
        <CustomRadio
          isDefault={!isActive}
          name={name}
          label={rightRadioLabel}
          onClick={() => !!setIsActive && setIsActive(false)}
        />
      </div>
      <div className="inputRadioBlock__description">{description}</div>
    </div>
  )
}
