import React from 'react'
import { Text } from '../../../../components/Text'
import { COLORS } from '../../../../config/constants'
import { Circle } from '../../../RadioButtonItem/Circle'
import { ItemContainer, ItemUserContainer, ItemIcon } from './Item.styles'

interface ItemProps {
  avatar: string
  name: string
  isPicked: boolean
  onClick: () => void
}

export const Item = ({ avatar, name, isPicked, onClick }: ItemProps) => (
  <ItemContainer onClick={onClick}>
    <ItemUserContainer>
      <ItemIcon>
        <img src={avatar} />
      </ItemIcon>
      <Text size={17} color={COLORS.COLOR_DARK_GRAY} letterSpacing={-0.41} lineHeight="22px">
        {name}
      </Text>
    </ItemUserContainer>
    <div>
      <Circle isVisible={isPicked} />
    </div>
  </ItemContainer>
)
