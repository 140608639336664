import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { PageWrapper } from '../../../../containers/PageWrapper/PageWrapper'
import { UserStore } from '../../../../store/user.store'
import { Icon } from '../../../../components'
import { Input } from '../../../../components/reusable'
import { Button, ButtonWrapper } from '../../../../components/reusable/Button/Button'
import { Text } from '../../../../components'
import { Wrapper } from '../../../../components/reusable/Wrapper/Wrapper'
import { COLORS } from '../../../../config/constants'
import { ListItem } from '../../../../components/reusable/ListItem/ListItem'
import { CategoriesList } from '../../../Home/layouts/CategoriesPreview/CategoriesList'
import { Header, InputWithAnchor, SettingsAvatar } from './Settings.styles'
import default_my_account_avatar from '../../../../assets/images/DefaultMyAccountAvatar.svg'
import { SettingsVm } from './Settings.vm'
import { Loader } from '../../../../components/Loader'
import {
  CategoriesContainer,
  CategoriesWrapper,
} from '../../../Home/layouts/CategoriesPreview/CategoriesPreview.styles'
import { SettingsStore } from '../../../../store/settings.store'

export const Settings = observer(() => {
  const userStore = useContext(UserStore)
  const { updateCategories } = useContext(SettingsStore)
  const [vm, setVm] = useState<SettingsVm | undefined>(undefined)

  useEffect(() => {
    if (userStore.userData) {
      setVm(new SettingsVm(userStore))
    }
  }, [userStore, userStore.userData])

  return vm && userStore.userData ? (
    <PageWrapper>
      <Header>
        <Icon name="settings" />
        <Text ml={10} color={COLORS.COLOR_DARK_GRAY} size={24}>
          Settings
        </Text>
      </Header>

      <Wrapper mx={19} mt={30} mb={92}>
        <Text weight={800} size={19}>
          Profile settings
        </Text>

        <SettingsAvatar mt={20}>
          <div>
            <img src={vm.userStore?.userData?.avatar || default_my_account_avatar} alt="avatar" />
            <input type="file" name="image" accept=".jpeg, .png, .jpg" onChange={(e) => vm.addAvatar(e)} />
          </div>
        </SettingsAvatar>

        <Input my={10} value={vm.name} onChangeText={(event) => vm.setName(event.target.value)} />
        <InputWithAnchor>
          <Text>@</Text>
          <Input pl={30} mb={10} value={vm.username} onChangeText={(event) => vm.setUsername(event.target.value)} />
        </InputWithAnchor>

        <Text weight={800} mt={20} size={19}>
          Shipping address
        </Text>
        <Input
          my={10}
          placeholder="Enter your country"
          value={vm.shippingData.country}
          onChangeText={(event) => vm.setShippingCountry(event.target.value)}
        />
        <Input
          my={10}
          placeholder="Enter your current city"
          value={vm.shippingData.city}
          onChangeText={(event) => vm.setShippingCity(event.target.value)}
        />
        <Input
          mb={10}
          placeholder="Enter your current street"
          value={vm.shippingData.street}
          onChangeText={(event) => vm.setShippingStreet(event.target.value)}
        />
        <Input
          mb={10}
          placeholder="Enter your current street number"
          value={vm.shippingData.streetNumber}
          onChangeText={(event) => vm.setShippingStreetNumber(event.target.value)}
        />
        <Input
          mb={10}
          placeholder="Enter your current zip code"
          value={vm.shippingData.zipCode}
          onChangeText={(event) => vm.setShippingZipCode(event.target.value)}
        />

        {vm.error && (
          <Text align="center" color={COLORS.COLOR_ORANGE_2} size={14} weight={300} mt={40} mb={10}>
            {vm.error}
          </Text>
        )}

        <Text weight={800} size={19} mt={40} mb={10}>
          Feed settings
        </Text>

        <Text color={COLORS.COLOR_DARK_GRAY} weight={300} size={14} mb={20}>
          Choose categiries you would like to follow
        </Text>

        <CategoriesWrapper>
          <CategoriesContainer>
            <CategoriesList onClick={(category) => updateCategories(category)} />
          </CategoriesContainer>
        </CategoriesWrapper>

        <Text weight={800} size={19} mb={20}>
          Delivery location of campaigns
        </Text>

        {vm.locations &&
          vm.locations.map((location) => (
            <ListItem onDelete={() => vm.deleteLocation(location)} mb={8} key={location} value={location} />
          ))}

        <Input
          onChangeText={(e) => vm.setLocationName(e.target.value)}
          value={vm.locationName}
          placeholder="I Add Country"
        />

        <ButtonWrapper>
          <Button
            onClick={vm.onSubmit}
            disabled={!vm.canSaveChanges() || vm.formLoading}
            isDisabled={!vm.canSaveChanges() || vm.formLoading}
            isBottomFixed
            isRounded
            type="submit">
            {vm.formLoading ? 'Loading...' : 'Save changes'}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </PageWrapper>
  ) : (
    <Loader absoluteCenter />
  )
})
