import { action, makeObservable, observable } from 'mobx'
import { FirestoreService } from '../../services/core/firestore.service'
import { UserService } from '../../services/entities/user.service'
import { Company } from '../../types/company.types'
import { User } from '../../types/user.types'

export class FollowingVm {
  users: User[] = []
  companies: Company[] = []

  constructor() {
    makeObservable(this, {
      users: observable,
      companies: observable,
      setUsers: action,
      setCompanies: action,
    })
  }

  public setUsers = (users: User[]) => {
    this.users = users
  }

  public setCompanies = (companies: Company[]) => {
    this.companies = companies
  }

  public fetchFollowings = async (userIds?: string[], companyIds?: string[]) => {
    if (userIds && userIds.length > 0) {
      const users = await UserService.getFollowings(userIds)
      this.setUsers(users)
    }
    if (companyIds && companyIds.length > 0) {
      const companies = await UserService.getFollowingCompanies(companyIds)
      this.setCompanies(companies)
    }
  }

  public fetchFollowingsByUsername = async (username: string) => {
    const { userData } = await FirestoreService.getUserByUsername(username)
    if (!userData) {
      throw new Error('username was not found')
    } else if (userData?.following && userData.following.length > 0) {
      const users = await UserService.getFollowings(userData.following)
      this.setUsers(users)
    }
  }

  public fetchFollowersByUsername = async (username: string) => {
    const { userData } = await FirestoreService.getUserByUsername(username)
    if (!userData) {
      throw new Error('username was not found')
    } else if (userData?.followers && userData.followers.length > 0) {
      const users = await UserService.getFollowings(userData.followers)
      this.setUsers(users)
    }
  }
}
