import styled from 'styled-components'

import { Text } from '../../../components/Text'

export const AboutCampaignWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px 13px 10px;
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 0 16px;
`

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 29px 0 29px;
  margin-top: 24px;
  height: 90px;
`

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95px;
`

export const StyledText = styled(Text)`
  text-align: center;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Button = styled.button`
  width: 347px;
  margin-top: 10px;
  height: 43px;
  background: linear-gradient(270deg, #f98140 0%, #fcba69 100%);
  border-radius: 4px;
  border: none;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 157.2%;

  color: #000;
`
