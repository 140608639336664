import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import HomeTrendingImage from '../../assets/images/home_trending.png'
import HomeLocalImage from '../../assets/images/home_local.png'
import HomeEnvironmentImage from '../../assets/images/home_environment.png'
import HomePromoteImage from '../../assets/images/home_promote.png'

import { COLORS } from '../../config/constants'
import { PageWrapper } from '../../containers/PageWrapper/PageWrapper'
import { FooterPreview } from '../../components/Footer/Footer'

import { MainLayout } from './layouts/MainLayout/MainLayout'
import { ProductPreview } from './layouts/ProductsPreview/ProductsPreview'
import { CategoryPreview } from './layouts/CategoriesPreview/CategoriesPreview'
import { PreviewPagePart } from './components/PreviewPagePart/PreviewPagePart'
import { ProductDetailsPreview } from './layouts/ProductDetailsPreview/ProductDetailsPreview'
import { CommissionPreview } from './layouts/CommissionPreview/CommissionPreview'
import { HomeVm } from './Home.vm'
import { UserStore } from '../../store/user.store'
import { Loader } from '../../components/Loader'

export const Home = observer(() => {
  const { userData } = useContext(UserStore)
  const [vm, setVm] = useState<HomeVm | undefined>(undefined)

  useEffect(() => {
    const fetch = async () => {
      if (vm) {
        try {
          await vm.fetchCampaigns()
        } catch (error) {
          alert('Failed fetching campaigns')
        }
      }
    }
    fetch()
  }, [vm])

  useEffect(() => {
    setVm(new HomeVm(userData))
  }, [userData])

  return (
    <>
      {vm ? (
        <PageWrapper style={{ backgroundColor: COLORS.BACKGROUND_COLOR }} noFooter>
          <MainLayout />
          <ProductPreview products={vm.campaigns} />
          <CategoryPreview />
          <PreviewPagePart title="Trending campaigns" image={HomeTrendingImage} withSlider products={vm.campaigns} />
          <PreviewPagePart title="Local choice" image={HomeLocalImage} withSlider products={vm.campaigns} />
          <ProductDetailsPreview />
          <PreviewPagePart
            title="Help to save the environment"
            description="We help to reduce waste of energy, manpower and materials!"
            image={HomeEnvironmentImage}
            styles={{ height: 492, paddingTop: 163, paddingLeft: 29 }}
            products={vm.campaigns}
          />
          <CommissionPreview />
          <PreviewPagePart
            title="Do you have a product that you want to promote?"
            description="Register as a  company and create your campaigns"
            image={HomePromoteImage}
            styles={{ height: 532, paddingTop: 163, paddingLeft: 29 }}
            products={vm.campaigns}
          />
          <FooterPreview />
        </PageWrapper>
      ) : (
        <Loader absoluteCenter />
      )}
    </>
  )
})
