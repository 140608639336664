import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { PageWrapper } from '../../../containers/PageWrapper/PageWrapper'
import { AccountInfo } from '../shared/AccountInfo/AccountInfo'
import { MyAccountTab } from './MyAccountTab/MyAccountTab'
import { UserStore } from '../../../store/user.store'
import { MyAccountVm } from './MyAccount.vm'
import { Loader } from '../../../components/Loader'
import { UserService } from '../../../services/entities/user.service'
import { TabsWrapper } from './MyAccountTab/MyAccountTab.style'
import { useHistory } from 'react-router-dom'

export const MyAccount = observer(() => {
  const history = useHistory()
  const { userData, getUsername, updateAvatar, removeAvatar } = useContext(UserStore)
  const [vm, setVm] = useState<MyAccountVm | undefined>(undefined)

  const onAvatar = async (e: ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files?.item(0)
    if (image) {
      try {
        await updateAvatar(image)
      } catch (error) {
        alert('Error uploading avatar...')
      }
    }
  }

  useEffect(() => {
    if (userData) {
      setVm(new MyAccountVm(userData))
    }
  }, [userData])

  useEffect(() => {
    if (vm?.userData) {
      vm.fetchData()
    }
  }, [vm, vm?.userData])

  return (
    <>
      {vm ? (
        <PageWrapper style={{ backgroundColor: '#F7F7FA', minHeight: '100vh' }}>
          {userData?.name && (
            <AccountInfo
              type="owner-view"
              title={userData.name}
              username={getUsername() as string}
              activity={UserService.getUserActivity(userData)}
              avatar={userData.avatar}
              isFollowed={false}
              onAvatar={onAvatar}
              onRemoveAvatar={removeAvatar}
            />
          )}
          <TabsWrapper>
            <MyAccountTab
              onPlus={() => history.push('/search')}
              tabTitle="Co-orders"
              tabIcon="cart-filled"
              products={vm.orders}
            />
            <MyAccountTab
              onPlus={() => history.push('/search')}
              tabTitle="Wish list"
              tabIcon="like"
              products={vm.wishlist}
            />
          </TabsWrapper>
        </PageWrapper>
      ) : (
        <Loader absoluteCenter />
      )}
    </>
  )
})
