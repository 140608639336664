import React, { useCallback, useContext, useEffect } from 'react'
import { auth, googleProvider } from '../firebase'
import { signInWithEmailAndPassword, signInWithPopup, createUserWithEmailAndPassword } from 'firebase/auth'
import { User } from '../types/user.types'
import { UserStore } from '../store/user.store'
import { observer } from 'mobx-react-lite'
import { LocalStorageService } from '../services/core/localStorage.service'
import { FirestoreService } from '../services/core/firestore.service'
import { CompanyStore } from '../store/company.store'
import { UserService } from '../services/entities/user.service'
import { StripeService } from '../services/core/stripe.service'
import { SettingsStore } from '../store/settings.store'

interface LayoutProps {
  children: React.ReactNode
}

interface IFormInput {
  name: string
  username: string
  email: string
  password: string
}

interface AuthContext {
  login: ({ email, password }: Pick<IFormInput, 'email' | 'password'>) => Promise<void>
  logout: () => Promise<void>
  signup: ({ name, username, email, password }: IFormInput) => Promise<void>
  googleAuth: () => Promise<void>
}

const AuthContext = React.createContext<AuthContext | undefined>(undefined)

export function useAuthContext() {
  return useContext(AuthContext)
}

const getUserDefaultData = (
  userData: Omit<User, 'uid' | 'liked' | 'shared' | 'followingCompanies' | 'orders'>
): Omit<User, 'uid'> => ({
  ...userData,
  liked: [],
  shared: [],
  followingCompanies: [],
  orders: [],
  subscribedCampaigns: [],
})

export const AuthProvider = observer(({ children }: LayoutProps) => {
  const { setUser } = useContext(UserStore)
  const { setCompany } = useContext(CompanyStore)
  const { clearCategories } = useContext(SettingsStore)

  const login = async ({ email, password }: Pick<IFormInput, 'email' | 'password'>) => {
    const loginResult = await signInWithEmailAndPassword(auth, email, password)
    if (loginResult.user?.uid) {
      LocalStorageService.loginInit(loginResult.user.uid)
      clearCategories()
    }
  }

  const googleAuth = async () => {
    const authResult = await signInWithPopup(auth, googleProvider)
    if (authResult.user?.uid) {
      const { userData } = await FirestoreService.getUserById(authResult.user?.uid)
      const username = authResult.user?.email?.split('@')[0]
      const email = authResult.user?.email
      const newUserData = {
        name: authResult.user?.displayName,
        username,
        email,
      } as Omit<User, 'uid'>
      LocalStorageService.loginInit(authResult.user.uid)
      clearCategories()
      if (!userData && username && email) {
        await FirestoreService.addUser(authResult.user.uid, getUserDefaultData(newUserData))
        await UserService.saveSharedIds(authResult.user.uid)
        const stripeCustomerId = await StripeService.createCustomer({
          name: username,
          email,
        })
        await FirestoreService.updateUser(authResult.user.uid, { stripeCustomerId })
        setUser({ ...newUserData, uid: authResult.user.uid })
      } else {
        setUser({ ...userData, uid: authResult.user.uid })
      }
    }
  }

  const logout = useCallback(async () => {
    await auth.signOut()
    LocalStorageService.removeItem('user')
    setUser(null)
  }, [setUser])

  const signup = async ({ name, username, email, password }: IFormInput) => {
    const existedUser = await FirestoreService.getUserByUsername(username)
    if (existedUser.userData) {
      throw new Error('User already exist')
    } else {
      const signupResult = await createUserWithEmailAndPassword(auth, email, password)
      if (signupResult.user?.uid) {
        await FirestoreService.addUser(signupResult.user.uid, getUserDefaultData({ name, username, email }))
        await UserService.saveSharedIds(signupResult.user.uid)
        const stripeCustomerId = await StripeService.createCustomer({ name: username, email })
        await FirestoreService.updateUser(signupResult.user.uid, { stripeCustomerId })
        LocalStorageService.loginInit(signupResult.user.uid)
        clearCategories()
      }
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const { userData } = await FirestoreService.getUserById(user.uid)
        if (userData) {
          LocalStorageService.setItem('user', { userId: user.uid })
          setUser({ ...userData, uid: user.uid })
          if (userData.currentCompany) {
            const { companyData } = await FirestoreService.getCompanyById(userData.currentCompany)
            setCompany({ ...companyData, id: userData.currentCompany })
          }
        }
      } else {
        await logout()
      }
    })

    return unsubscribe
  }, [setUser, setCompany, logout])

  return <AuthContext.Provider value={{ signup, login, logout, googleAuth }}>{children}</AuthContext.Provider>
})
