import React from 'react'

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.469238" width="23.5308" height="3.21951" fill={props.color || 'white'} />
      <rect x="0.469238" y="9.39026" width="23.5308" height="3.21951" fill={props.color || 'white'} />
      <rect x="0.469238" y="18.7805" width="23.5308" height="3.21951" fill={props.color || 'white'} />
    </svg>
  )
}

export const BurgerIcon = React.memo(SVGComponent)
