import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import { Bars } from 'react-loader-spinner'
import { observer } from 'mobx-react-lite'

import DummyAvatar from '../../assets/images/DefaultAccountAvatar.svg'
import { COLORS, WINDOW } from '../../config/constants'
import { FirestoreService } from '../../services/core/firestore.service'

import { Item } from './components/Item/Item'
import { Company } from '../../types/company.types'
import { ItemAddCompany } from './components/Item/ItemAddCompany'
import { UserService } from '../../services/entities/user.service'
import { UserStore } from '../../store/user.store'
import { CompanyStore } from '../../store/company.store'
import { useHistory } from 'react-router-dom'

interface CompanyPopupPickerProps extends DialogProps {
  forceClose: () => void
}

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    display: 'flex',
    bottom: 0,
    width: '100%',
    margin: 0,
    padding: 0,
    borderRadius: 0,
    maxWidth: WINDOW.MAX_WIDTH,
  },
})

const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 20px;
`

export const CompanyPopupPicker = observer(({ forceClose, ...props }: CompanyPopupPickerProps) => {
  const classes = useStyles()
  const [companies, setCompanies] = useState<Company[]>([])
  const [isFetching, setIsFetching] = useState(true)
  const popupRef = useRef()
  const history = useHistory()

  const { userData, setUser } = useContext(UserStore)
  const { setCompany } = useContext(CompanyStore)

  const chosenItem = userData?.currentCompany || userData?.uid

  useEffect(() => {
    const getCompanies = async () => {
      if (userData?.uid) {
        const companies = await FirestoreService.getCompaniesByUserId(userData.uid)
        setCompanies(companies)
        setIsFetching(true)
      }
    }
    getCompanies()
  }, [props.open, userData?.uid])

  const updateCurrentCompany = async (companyId: string | null) => {
    if (userData?.uid) {
      await UserService.setCurrentCompany(userData.uid, companyId)
      await setUser({ ...userData, currentCompany: companyId })
    }
  }

  const chooseCurrentUser = async (id: string) => {
    if (id === userData?.uid) {
      updateCurrentCompany(null)
    } else if (id !== chosenItem && userData?.uid) {
      updateCurrentCompany(id)
    }
    setCompany(companies.find((company) => company.id === id) || null)
    history.push('/')
    forceClose()
  }

  return (
    <>
      {userData?.name && (
        <div>
          <Dialog {...props} ref={popupRef} classes={{ paper: classes.dialog }}>
            <Item
              avatar={userData.avatar || DummyAvatar}
              name={userData?.name}
              isPicked={chosenItem === userData.uid}
              onClick={() => chooseCurrentUser(userData.uid)}
            />
            {isFetching ? (
              companies.map((company) => (
                <Item
                  key={company.companyUsername}
                  avatar={company.logo || DummyAvatar}
                  name={company.companyName}
                  isPicked={chosenItem === company.id}
                  onClick={() => chooseCurrentUser(company.id)}
                />
              ))
            ) : (
              <LoaderContainer>
                <Bars color={COLORS.COLOR_ORANGE_1} height={50} width={50} />
              </LoaderContainer>
            )}
            <ItemAddCompany />
          </Dialog>
        </div>
      )}
    </>
  )
})
