import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Icon } from '../../components'
import { COLORS } from '../../config/constants'
import { PageWrapper } from '../../containers/PageWrapper/PageWrapper'
import { Wrapper, Header, HeaderTitle, TextTitle, TextDescription, FollowersContainer } from './Community.style'
import { CommunityCard } from './components/CommunityCard/CommunityCard'
import { Follower } from '../Following/shared/components/Follower'
import { FirestoreService } from '../../services/core/firestore.service'
import { User } from '../../types/user.types'
import { UserStore } from '../../store/user.store'

export const Community = observer(() => {
  const [users, setUsers] = useState<User[]>([])
  const { userData, followUser, unfollowUser } = useContext(UserStore)

  useEffect(() => {
    if (userData?.uid) {
      const fetchUsers = async () => {
        const users = await FirestoreService.getUsers()
        setUsers(users.filter((user) => user.uid !== userData?.uid))
      }
      fetchUsers()
    }
  }, [userData?.uid])

  const onFollow = async (uid: string, type: 'follow' | 'unfollow') => {
    if (type === 'follow') {
      await followUser(uid)
    } else {
      await unfollowUser(uid)
    }
  }

  return (
    <PageWrapper>
      <Wrapper>
        <Header>
          <Icon name="account-group" width={29} height={17} color={COLORS.COLOR_ORANGE_1} />
          <HeaderTitle>Community</HeaderTitle>
        </Header>
        <TextTitle>Invite your friends in social media</TextTitle>
        <TextDescription>Share this invitation link to wecobi in social media</TextDescription>
        <CommunityCard />
      </Wrapper>
      <FollowersContainer>
        {users.map(({ avatar, username, name, uid }, index) => {
          const isFollowed = userData?.following?.includes(uid)
          return (
            <Follower
              avatar={avatar}
              username={username}
              name={name}
              isFollowed={!!isFollowed}
              key={index}
              onFollow={() => onFollow(uid, isFollowed ? 'unfollow' : 'follow')}
            />
          )
        })}
      </FollowersContainer>
    </PageWrapper>
  )
})
