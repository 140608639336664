import React from 'react'

import { Wrapper, ImageWrapper, Header, SliderWrapper, Description } from './PreviewPagePart.styles'
import { ProductSlider } from '../ProductsSlider/ProductsSlider'
import { CSSObject } from 'styled-components'
import { ProductProps } from '../../../../components/Product/Product.interfaces'

interface PreviewImageProps {
  products: ProductProps[]
  title: string
  description?: string
  image: any
  withSlider?: boolean
  styles?: CSSObject
}

export const PreviewPagePart = ({ title, image, withSlider, styles, description, products }: PreviewImageProps) => (
  <Wrapper>
    <ImageWrapper image={image} styles={styles}>
      <Header>{title}</Header>
      {description && <Description>{description}</Description>}
    </ImageWrapper>
    {withSlider && products.length > 0 && (
      <SliderWrapper>
        <ProductSlider products={products} />
      </SliderWrapper>
    )}
  </Wrapper>
)
