import React, { useState } from 'react'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'

import ImagePreviewPrice from '../../../../assets/images/home_product_preview_1.png'
import ImagePreviewCommission from '../../../../assets/images/home_product_preview_2.png'
import ImagePreviewCount from '../../../../assets/images/home_product_preview_3.png'
import ImagePreviewLike from '../../../../assets/images/home_product_preview_4.png'
import {
  Wrapper,
  Header,
  ImageWrapper,
  DescriptionHeader,
  Description,
  SliderContainer,
} from './ProductDetailsPreview.styles'

SwiperCore.use([Navigation])

const slides = [
  {
    title: 'Progress Bar and goal of the campaign',
    text: 'here will be explaining text here will be explaining text here will be explaining text',
    image: ImagePreviewPrice,
  },
  {
    title: 'Progress Bar and goal of the campaign',
    text: 'here will be explaining text here will be explaining text here will be explaining text',
    image: ImagePreviewCommission,
  },
  {
    title: 'Progress Bar and goal of the campaign',
    text: 'here will be explaining text here will be explaining text here will be explaining text',
    image: ImagePreviewCount,
  },
  {
    title: 'Progress Bar and goal of the campaign',
    text: 'here will be explaining text here will be explaining text here will be explaining text',
    image: ImagePreviewLike,
  },
]

export const ProductDetailsPreview = () => {
  const [currentSlide, setCurrentSlide] = useState(0)

  return (
    <Wrapper>
      <Header>Discover the campaign</Header>
      <ImageWrapper>
        <img src={slides[currentSlide].image} />
      </ImageWrapper>
      <SliderContainer>
        <Swiper onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)} style={{ width: '100%' }}>
          {slides.map(({ title, text }, index) => {
            return (
              <SwiperSlide style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} key={index}>
                <DescriptionHeader>{title}</DescriptionHeader>
                <Description>{text}</Description>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </SliderContainer>
    </Wrapper>
  )
}
