import React from 'react'

import './Category.scss'
import { CategoryTag } from '../../../../../../components/Categories/CategoryTag/CategoryTag'
import { Button } from '../../../../components/Button/Button'
import { categoryTitles, ICategoryNames } from '../../../../../../components/Categories/CategoryItem/CategoryItem.types'

interface CategoryBlockProps {
  openModal: () => void
  categories: ICategoryNames[]
  onCategoryRemove: (value: string) => void
}

export const CategoryBlock = ({ openModal, categories, onCategoryRemove }: CategoryBlockProps) => {
  return (
    <div className="productDetails__category">
      <div className="title">Category</div>
      <div className="buttons">
        <Button type="arrowRight" title="Choose categories" onClick={openModal} />
      </div>
      <div className="tags">
        {categories.map((value, index) => (
          <CategoryTag title={categoryTitles[value]} key={index} onRemove={() => onCategoryRemove(value)} />
        ))}
      </div>
    </div>
  )
}
