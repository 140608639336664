import React from 'react'

import './Input.scss'
import { InputNumberStateProps, InputTextareaStateProps } from './input.interface'

export const InputNumber = ({ type, measure, isActive = true, onChange, value }: InputNumberStateProps) => {
  return (
    <div className="campaignRegistration__inputNumber">
      <input type="number" disabled={!isActive} onChange={onChange} value={value} />
      <div className={`measure ${type} ${!isActive ? 'not-active' : ''}`}>{measure}</div>
    </div>
  )
}

export const InputTextarea = ({ title, onChange, value }: InputTextareaStateProps) => {
  return (
    <div className="campaignRegistration__inputTextarea">
      <div className="title">{title}</div>
      <textarea onChange={onChange} value={value} />
    </div>
  )
}
