import React from 'react'

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '23'}
      height={props.height || '17'}
      viewBox="0 0 23 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M19.1333 2.26667C18.581 2.26667 18.1333 2.71438 18.1333 3.26667V13.7333C18.1333 14.2856 18.581 14.7333 19.1333 14.7333H21.6667C22.219 14.7333 22.6667 14.2856 22.6667 13.7333V3.26667C22.6667 2.71438 22.219 2.26667 21.6667 2.26667H19.1333ZM0 13.7333C0 14.2856 0.447715 14.7333 1 14.7333H3.53333C4.08562 14.7333 4.53333 14.2856 4.53333 13.7333V3.26667C4.53333 2.71438 4.08562 2.26667 3.53333 2.26667H0.999999C0.447715 2.26667 0 2.71438 0 3.26667V13.7333ZM5.66667 16C5.66667 16.5523 6.11438 17 6.66667 17H16C16.5523 17 17 16.5523 17 16V1C17 0.447715 16.5523 0 16 0H6.66667C6.11438 0 5.66667 0.447715 5.66667 1V16Z"
        fill={props.color || '#D5D5D5'}
      />
    </svg>
  )
}

export const DisplayHorizontalFilterIcon = React.memo(SVGComponent)
