import React from 'react'

import './Button.scss'
import { ButtonStateProps } from './button.interface'
import { ArrowRightIcon, ArrowDownBoldIcon } from '../../../../assets/icons'

export const Button = ({ title, type, isActive = true, onClick }: ButtonStateProps) => {
  const color = !isActive ? '#D5D5D5' : '#434343'
  const renderedIcon = type === 'arrowDown' ? <ArrowDownBoldIcon color={color} /> : <ArrowRightIcon color={color} />

  return (
    <button className="campaignRegistration__button pointer" onClick={onClick}>
      <div className={`container ${type}`}>
        <div className={`text ${!isActive ? 'not-active' : ''}`}>{title}</div>
        <div className="arrowIcon">{renderedIcon}</div>
      </div>
    </button>
  )
}
