import styled, { CSSObject } from 'styled-components'
import { resolveStyledSpacings } from '../styles/spacings/resolve-styled-spacings'
import { Spacings } from '../styles/spacings/Spacings'

interface TextProps extends Spacings {
  font?: string
  size?: number
  weight?: number
  lineHeight?: string
  align?: 'center' | 'end' | 'start' | 'justify' | 'left' | 'right'
  style?: CSSObject
  color?: string
  letterSpacing?: number
}

export const Text = styled.p<TextProps>`
  font-family: ${(p) => p.font || 'Poppins'};
  font-size: ${(p) => p.size || 14}px;
  font-weight: ${(p) => p.weight || 600};
  line-height: ${(p) => p.lineHeight || '130.2%'};
  text-align: ${(p) => p.align};
  color: ${(p) => p.color || '#000'};
  font-style: normal;
  letter-spacing: ${(p) => p.letterSpacing || 0}px;
  ${(p) => p.style}
  ${(p) => resolveStyledSpacings(p)}
`
