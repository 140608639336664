import React from 'react'

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '10'}
      height={props.height || '10'}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.67432 1.70711C10.0648 1.31658 10.0648 0.683417 9.67432 0.292893C9.2838 -0.0976311 8.65063 -0.0976311 8.26011 0.292893L4.98361 3.56939L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L3.56939 4.98361L0.292893 8.26011C-0.0976311 8.65063 -0.0976311 9.2838 0.292893 9.67432C0.683417 10.0648 1.31658 10.0648 1.70711 9.67432L4.98361 6.39782L8.26011 9.67432C8.65063 10.0648 9.2838 10.0648 9.67432 9.67432C10.0648 9.2838 10.0648 8.65063 9.67432 8.26011L6.39782 4.98361L9.67432 1.70711Z"
        fill={props.color || '#D5D5D5'}
      />
    </svg>
  )
}

export const CrossIcon = React.memo(SVGComponent)
