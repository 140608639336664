import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { observer } from 'mobx-react-lite'

import { PageWrapper } from '../../../containers/PageWrapper/PageWrapper'
import { AccountInfo } from '../shared/AccountInfo/AccountInfo'
import { SLIDER_HEIGHT } from './Account.config'
import { AccountProps } from './Account.interface'
import { EmptyProfileTextWrapper } from './Account.style'
import { Text } from '../../../components/Text'
import { COLORS } from '../../../config/constants'
import { AccountProducts } from '../shared/AccountProducts/AccountProducts'
import { UserService } from '../../../services/entities/user.service'
import { UserStore } from '../../../store/user.store'
import { AccountVm } from './Account.vm'
import { Loader } from '../../../components/Loader'

export const Account = observer(({ isEmpty = false }: AccountProps) => {
  const { userData, followUser, unfollowUser } = useContext(UserStore)
  const [vm, setVm] = useState<AccountVm | undefined>(undefined)
  const location = useLocation()
  const history = useHistory()

  const username = location.pathname.split('/').pop()

  useEffect(() => {
    if (userData && username) {
      setVm(new AccountVm(username))
    }
  }, [userData, username])

  useEffect(() => {
    if (vm && userData) {
      const fetchUser = async () => {
        const user = await vm.fetchUser(userData.uid)
        if (user && user.username === userData.username) {
          history.push('/account')
        } else if (!user) {
          alert('error fetching username')
          history.push('/')
        }
      }
      fetchUser()
    }
  }, [vm, history, userData])

  const onFollow = async () => {
    if (vm?.user?.uid) {
      followUser(vm.user.uid)
      vm.setIsFollowed(true)
      vm.setUserActivity({ ...vm.userActivity, followers: vm.userActivity.followers + 1 })
    }
  }

  const onUnfollow = async () => {
    if (vm?.user?.uid) {
      unfollowUser(vm.user.uid)
      vm.setIsFollowed(false)
      vm.setUserActivity({ ...vm.userActivity, followers: vm.userActivity.followers - 1 })
    }
  }

  return (
    <>
      {vm && vm.user ? (
        <PageWrapper>
          {userData && userData.username && (
            <AccountInfo
              title={vm.user.name}
              username={UserService.getUsername(vm.user.username)}
              avatar={vm.user.avatar}
              activity={vm.userActivity}
              isFollowed={vm.isFollowed}
              onFollow={onFollow}
              onUnfollow={onUnfollow}
            />
          )}
          {!isEmpty ? (
            <AccountProducts
              country="Czech Republic"
              index={vm.sliderIndex}
              setIndex={vm.setSliderIndex}
              display={vm.display}
              setDisplay={vm.setDisplay}
              height={SLIDER_HEIGHT}
              wishlist={vm.wishlist}
              orders={vm.orders}
            />
          ) : (
            <EmptyProfileTextWrapper>
              <Text size={14} letterSpacing={-0.08} lineHeight="18px" color={COLORS.COLOR_MIDDLE_GRAY} weight={400}>
                {`${vm.user?.name || 'User'} did not join any campaign yet, or hided them from showing`}
              </Text>
            </EmptyProfileTextWrapper>
          )}
        </PageWrapper>
      ) : (
        <Loader absoluteCenter />
      )}
    </>
  )
})
