import React, { useContext, useState } from 'react'

import DummyAvatar from '../../../assets/images/DefaultMyAccountAvatar.svg'
import { useHistory } from 'react-router'

import { DrawerWrapper, ItemsWrapper, MainItems, SecondaryItems } from './DrawerContent.styles'
import { AvatarContainer } from './components/AvatarContainer/AvatarContainer'
import { DrawerItem } from './components/DrawerItem/DrawerItem'
import { UserFooterBlock } from './components/FooterBlock'
import { ContentProps, userContent } from './DrawerContent.config'
import { useAuthContext } from '../../../contexts/authContext'
import { UserStore } from '../../../store/user.store'
import { CompanyPopupPicker } from '../../CompanyPopupPicker/CompanyPopupPicker'

export const UserDrawerContent = () => {
  const history = useHistory()
  const { secondaryItems } = userContent
  const { logout } = useAuthContext()!
  const { userData, getUsername } = useContext(UserStore)
  const [isCompanyPickerOpen, setIsCompanyPickerOpen] = useState(false)

  const onLogout = async () => {
    await logout()
    history.push('/login')
  }

  const onCreateCompany = () => history.push('/signup-company')

  const closePicker = () => setIsCompanyPickerOpen(false)

  const onImage = () => history.push('/account')

  const mainItems: ContentProps['mainItems'] = [
    {
      iconName: 'like',
      title: 'Wish list',
      navigatePath: '/account/wishlist',
      accessoryText: userData?.liked?.length || '',
    },
    {
      iconName: 'shop',
      title: 'Co-orders',
      navigatePath: '/account/orders',
      accessoryText: userData?.orders?.length || '',
    },
    {
      iconName: 'account-group',
      title: 'Community',
      navigatePath: '/community',
      accessoryText: userData?.following?.length || '',
    },
    {
      iconName: 'chat',
      title: 'Messages',
      navigatePath: '/account/messages',
      accessoryText: 5,
    },
    {
      iconName: 'commission',
      title: 'Commissions',
      navigatePath: '/account/commissions',
    },
    {
      iconName: 'bell',
      title: 'Notifications',
      navigatePath: '/account/notifications',
      accessoryText: 5,
    },
  ]

  return (
    <DrawerWrapper>
      {userData?.uid && (
        <CompanyPopupPicker open={isCompanyPickerOpen} onClose={closePicker} forceClose={closePicker} />
      )}
      {userData?.name && (
        <AvatarContainer
          image={userData.avatar || DummyAvatar}
          name={userData.name}
          nickname={getUsername()}
          onArrow={() => setIsCompanyPickerOpen(true)}
          onImage={onImage}
        />
      )}
      <ItemsWrapper>
        <MainItems>
          {mainItems.map((item) => (
            <DrawerItem key={item.title} {...item} borderBottom />
          ))}
        </MainItems>
        {secondaryItems && (
          <SecondaryItems>
            {secondaryItems.map((item) => (
              <DrawerItem key={item.title} {...item} type="secondary" borderTop />
            ))}
          </SecondaryItems>
        )}
      </ItemsWrapper>
      <UserFooterBlock onCreateCompany={onCreateCompany} onLogout={onLogout} />
    </DrawerWrapper>
  )
}
