import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={186} height={76} viewBox="0 0 186 76" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.867 4.228C-4.599-11.358 1.3-30.788 17.041-39.17c15.742-8.383 35.367-2.543 43.834 13.043l29.823 54.9c8.467 15.586 2.57 35.016-13.173 43.399-15.742 8.383-35.367 2.543-43.834-13.043L3.867 4.228zm19.147-32.402C13.407-23.058 9.807-11.2 14.974-1.686l29.824 54.9c5.167 9.513 17.145 13.076 26.753 7.96 9.608-5.116 13.208-16.975 8.04-26.488l-29.823-54.9c-5.168-9.513-17.146-13.077-26.754-7.96z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.348 4.228C56.881-11.358 62.78-30.788 78.521-39.17c15.743-8.383 35.368-2.543 43.835 13.043l29.823 54.9c8.467 15.586 2.569 35.016-13.173 43.399-15.742 8.383-35.368 2.543-43.835-13.043l-29.823-54.9zm19.147-32.402c-9.608 5.116-13.208 16.975-8.04 26.488l29.823 54.9c5.168 9.513 17.146 13.076 26.754 7.96 9.608-5.116 13.208-16.975 8.04-26.488l-29.823-54.9c-5.168-9.513-17.146-13.077-26.754-7.96z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M182.133 4.228c8.466-15.586 2.568-35.016-13.174-43.399-15.742-8.383-35.367-2.543-43.834 13.043l-29.823 54.9c-8.467 15.586-2.57 35.016 13.173 43.399 15.742 8.383 35.367 2.543 43.834-13.043l29.824-54.9zm-19.147-32.402c9.608 5.116 13.207 16.975 8.04 26.488l-29.824 54.9c-5.167 9.513-17.145 13.076-26.753 7.96-9.608-5.116-13.208-16.975-8.04-26.488l29.823-54.9c5.168-9.513 17.146-13.077 26.754-7.96z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.648 4.228c8.467-15.586 2.569-35.016-13.173-43.399-15.742-8.383-35.367-2.543-43.834 13.043l-29.823 54.9C25.35 44.358 31.248 63.788 46.99 72.17c15.742 8.383 35.367 2.543 43.834-13.043l29.823-54.9zm-19.147-32.402c9.608 5.116 13.208 16.975 8.04 26.488l-29.823 54.9c-5.168 9.513-17.146 13.076-26.754 7.96-9.608-5.116-13.207-16.975-8.04-26.488l29.824-54.9c5.167-9.513 17.145-13.077 26.753-7.96z"
        fill="#fff"
      />
    </svg>
  )
}

export const LogoSlicedIcon = React.memo(SvgComponent)
