import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={20} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 0a4 4 0 110 8 4 4 0 010-8zm0 10c4.42 0 8 1.79 8 4a2 2 0 01-2 2H2a2 2 0 01-2-2c0-2.21 3.58-4 8-4zm-5 9a1 1 0 112 0 1 1 0 01-2 0zm4 0a1 1 0 112 0 1 1 0 01-2 0zm4 0a1 1 0 112 0 1 1 0 01-2 0z"
        fill={props.color || '#6B717A'}
      />
    </svg>
  )
}

export const UserAccountIcon = React.memo(SvgComponent)
