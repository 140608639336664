import styled from 'styled-components'

import { Text } from '../../components'
import { COLORS } from '../../config/constants'

export const Wrapper = styled.div`
  background-color: ${COLORS.COLOR_LIGHT_GRAY_1};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 46px 0 54px 0;
`

export const HeaderTitle = styled(Text)`
  font-size: 24px;
  color: ${COLORS.COLOR_DARK_GRAY};
  margin-left: 9px;
  line-height: 30px;
  font-weight: bold;
`

export const TextTitle = styled(HeaderTitle)`
  margin: 0;
  max-width: 225px;
  text-align: center;
`

export const TextDescription = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  letter-spacing: -0.08px;
  color: ${COLORS.COLOR_DARK_GRAY};
  max-width: 225px;
  text-align: center;
  margin-top: 8px;
`

export const FollowersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 43px;
`
