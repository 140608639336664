import React from 'react'
import { useHistory } from 'react-router-dom'

import BlankImage from '../../assets/images/blank_product.webp'
import { ProductComponentProps, ProductProps } from './Product.interfaces'
import { colorIcon, colorFilledIcon } from './Product.config'
import { ProgressBar } from '../reusable'
import { Icon } from '../Icon/Icon'

import { ProductContainer } from './Product.styles'
import { PROGRESS_BAR_HEIGHTS } from '../reusable/ProgressBar/ProgressBar.types'

export const ProductComponent = ({
  id,
  images,
  buyers,
  goal,
  price,
  retailPrice,
  title,
  companyName,
  commission,
  progressBarHeight = PROGRESS_BAR_HEIGHTS.SMALL,
  type,
  size = 'small',
  style,
  isLiked,
}: ProductComponentProps) => {
  const history = useHistory()
  const likeColor = isLiked ? colorFilledIcon : colorIcon

  return (
    <ProductContainer type={type} progressBarHeight={progressBarHeight} style={style} size={size}>
      <div className="product_image_wrapper" onClick={() => history.push(`/campaign/${id}`)}>
        <div className="like">
          <Icon name="like" width={20} height={19} color={likeColor} pointer />
        </div>
        <div className="product_image_container">
          <img src={(images && images[0]) || BlankImage} alt="image" />
        </div>
        <div className="product_status_bar">
          <ProgressBar pcs={buyers.length} pcsMax={goal} height={progressBarHeight} />
        </div>
      </div>
      <div>
        <div className="details">
          <div>
            <div className="price">${price}</div>
            {retailPrice && <div className="price_old">${retailPrice}</div>}
          </div>
          <div>
            <div className="buyers">{buyers.length}</div>
            <div className="goal">/{goal}</div>
          </div>
        </div>
        <div className="description">
          <div className="title">{title}</div>
          <div className="description_details">
            <div>{companyName}</div>
            <div>commission: {commission}%</div>
          </div>
        </div>
      </div>
    </ProductContainer>
  )
}

export const Product = (props: ProductProps) => <ProductComponent {...props} type="filled" />

export const ProductOutlined = (props: ProductProps) => <ProductComponent {...props} type="outlined" />
