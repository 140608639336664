import React, { useState, useMemo, useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import './CampaignRegistration.scss'
import { Navbar } from './components/Navbar/Navbar'
import { FIRST_STAGE_INDEX, LAST_STAGE_INDEX } from './campaignRegistration.config'
import { chooseComponent, defaultCampaignRegistrationForm, splitUrl } from './campaignRegistration.helpers'
import { RenderedComponentStateProps } from './campaignRegistration.interface'
import { PageWrapper } from '../../containers/PageWrapper/PageWrapper'
import { CampaignRegistrationVm } from './campaignRegistration.vm'

export const CampaignRegistration = observer(() => {
  const { pathname } = useLocation()
  const history = useHistory()
  const { setCampaignId, updateFormData } = useContext(CampaignRegistrationVm)

  const [stage, setStage] = useState(0)

  const onClickNextButton = stage < LAST_STAGE_INDEX ? () => setStage(stage + 1) : undefined

  const onClickBackButton = stage > FIRST_STAGE_INDEX ? () => setStage(stage - 1) : undefined

  const onClickCancelButton = () => history.replace('/')

  const RenderedComponent:
    | (({ onClickBackButton, onClickNextButton }: RenderedComponentStateProps) => JSX.Element)
    | undefined = useMemo(() => chooseComponent(stage), [stage])

  useEffect(() => {
    const { isEditing, campaignId } = splitUrl(pathname)
    if (isEditing && campaignId) {
      setCampaignId(campaignId)
    } else {
      updateFormData(defaultCampaignRegistrationForm)
    }
  }, [pathname, setCampaignId, updateFormData])

  return (
    <PageWrapper className="campaignRegistration" noFooter>
      <Navbar stage={stage} />
      {RenderedComponent && (
        <RenderedComponent
          onClickBackButton={stage === 0 ? onClickCancelButton : onClickBackButton}
          onClickNextButton={onClickNextButton}
          stage={stage}
        />
      )}
    </PageWrapper>
  )
})
