import React from 'react'

import { Header, InfoWrapper, PlusButtonContainer, TextWrapper, Wrapper } from './MyAccountTab.style'
import { ProductsSlider, ProductWrapper } from '../../../../components/Product/Product.styles'
import { Icon } from '../../../../components'
import { COLORS } from '../../../../config/constants'
import { MyAccountTabProps } from './MyAccountTab.interface'
import { Text } from '../../../../components'
import { Product } from '../../../../components'

export const MyAccountTab = ({ tabTitle, tabIcon, products, onPlus }: MyAccountTabProps) => {
  return (
    <Wrapper>
      <Header>
        <InfoWrapper>
          <Icon width={27} height={27} name={tabIcon} color={COLORS.COLOR_ORANGE_1} />
          <TextWrapper>
            <Text size={24} lineHeight="125.7%" weight={700} color={COLORS.COLOR_DARK_GRAY}>
              {tabTitle}
            </Text>
          </TextWrapper>
        </InfoWrapper>
        <Icon name="more" />
      </Header>
      <ProductsSlider>
        {products.length ? (
          products.map((product, index) => (
            <ProductWrapper width={224} key={index}>
              <Product {...product} />
            </ProductWrapper>
          ))
        ) : (
          <PlusButtonContainer onClick={onPlus}>
            <p>+</p>
          </PlusButtonContainer>
        )}
      </ProductsSlider>
    </Wrapper>
  )
}
