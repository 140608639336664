import React, { Fragment } from 'react'

import './Description.scss'
import '../../../../config/headers.scss'
import '../../../../config/texts.scss'
import { LocationIcon } from '../../../../assets/icons'
import { CategoryTag } from '../../../../components/Categories/CategoryTag/CategoryTag'
import { useHistory } from 'react-router-dom'

interface DescriptionProps {
  shipping: boolean
  fullDescription: string
  id: string
  categories: string[]
}

export const Description = ({ shipping, fullDescription, id, categories }: DescriptionProps) => {
  const history = useHistory()

  return (
    <Fragment>
      <div className="description-tip" onClick={() => history.push('/about')}>
        <div className="title">How to buy with wecobi and earn a commission?</div>
        <div className="subtitle">Read a short description</div>
      </div>
      <div className="shipping">
        <div className="icon">
          <LocationIcon />
        </div>
        <div className="text">{shipping ? 'Shipping to your country' : 'Shipping to your country not available'}</div>
      </div>
      <div className="company-description">
        <div className="title header-3">Product description</div>
        <div className="product-description text-1">{fullDescription}</div>
        <div className="serial-number text-1">
          Campaign id: <span className="text-3">{id}</span>
        </div>
        {categories.length > 0 && (
          <div className="tags">
            {categories.map((category) => (
              <CategoryTag title={category} key={category} />
            ))}
          </div>
        )}
      </div>
    </Fragment>
  )
}
