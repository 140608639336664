import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={22} height={14} viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11 .795a3 3 0 100 6 3 3 0 000-6zm-6 2.17a2.5 2.5 0 100 5c.88 0 1.65-.46 2.09-1.14C6.42 5.975 6 4.945 6 3.795c0-.2 0-.4.04-.6-.32-.15-.67-.23-1.04-.23zm12 0c-.37 0-.72.08-1.04.23.04.2.04.4.04.6 0 1.15-.42 2.18-1.09 3.03a2.49 2.49 0 003.858.408A2.5 2.5 0 0017 2.965zm-6 5.83c-2 0-6 1-6 3a2 2 0 002 2h8a2 2 0 002-2c0-2-4-3-6-3zm-7.33.97C2 10.055 0 10.835 0 12.125c0 .923.748 1.67 1.67 1.67H3v-2c0-.78.29-1.47.67-2.03zm14.66 0c.38.56.67 1.25.67 2.03v2h1.33a1.67 1.67 0 001.67-1.67c0-1.29-2-2.07-3.67-2.36z"
        fill={props.color || '#434343'}
      />
    </svg>
  )
}

export const AccountGroupIcon = React.memo(SvgComponent)
