import React from 'react'

import {
  AboutCampaignWrapper,
  TitleWrapper,
  InfoWrapper,
  InfoBlock,
  StyledText,
  Button,
  ButtonWrapper,
} from './AboutCampaign.style'
import { Text } from '../../../components/Text'
import { COLORS } from '../../../config/constants'
import { Icon } from '../../../components/Icon/Icon'
import { AboutCampaignProps } from './AboutCampaign.interface'
import { titles, texts } from './AboutCampaign.config'

export const AboutCampaign = ({ type, campaignInfo, toggleSwitcherHidden }: AboutCampaignProps) => {
  const campaignInfoArray = [...Object.values(campaignInfo)]

  return (
    <AboutCampaignWrapper>
      <TitleWrapper>
        <Text size={19} color={COLORS.COLOR_BLACK} weight={700} lineHeight="119.2%">
          {titles[type]}
        </Text>
        <Icon onClick={toggleSwitcherHidden} name="arrow-down-bold" color={COLORS.COLOR_DARK_GRAY} />
      </TitleWrapper>
      <InfoWrapper>
        {texts[type].map((text, index) => {
          return (
            <InfoBlock key={index}>
              <Text size={24} color={COLORS.COLOR_BLACK} lineHeight="125.7%" weight={700}>
                {campaignInfoArray[index]}
              </Text>
              <StyledText size={10} weight={500} color={COLORS.COLOR_BLACK} lineHeight="127.2%">
                {text}
              </StyledText>
            </InfoBlock>
          )
        })}
      </InfoWrapper>
      {type === 'finished' && (
        <ButtonWrapper>
          <Button>Withdraw my commissions</Button>
        </ButtonWrapper>
      )}
    </AboutCampaignWrapper>
  )
}
