import { action, makeObservable, observable } from 'mobx'
import { UserService } from '../../../services/entities/user.service'
import { Campaign } from '../../../types/company.types'
import { User } from '../../../types/user.types'

export class MyAccountVm {
  wishlist: Campaign[] = []
  orders: Campaign[] = []
  userData: User | null = null

  constructor(userData: User | null) {
    this.userData = userData

    makeObservable(this, {
      wishlist: observable,
      orders: observable,
      userData: observable,
      setWishlist: action,
      setOrders: action,
    })
  }

  public setWishlist = (wishlist: Campaign[]) => {
    this.wishlist = wishlist
  }

  public setOrders = (orders: Campaign[]) => {
    this.orders = orders
  }

  private getWishlist = async () => {
    if (this.userData?.uid) {
      const wishlist = await UserService.getLikedCampaigns(this.userData.uid)
      this.setWishlist(wishlist)
    }
  }

  private getOrders = async () => {
    if (this.userData?.uid) {
      const orders = await UserService.getOrders(this.userData.uid)
      if (orders) {
        this.setOrders(orders)
      }
    }
  }

  public fetchData = async () => {
    this.getWishlist()
    this.getOrders()
  }
}
