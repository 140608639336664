import React from 'react'

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '35'}
      height={props.height || '35'}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M21.5 17.25C21.5 16.6533 21.7371 16.081 22.159 15.659C22.581 15.2371 23.1533 15 23.75 15C24.3467 15 24.919 15.2371 25.341 15.659C25.7629 16.081 26 16.6533 26 17.25C26 17.8467 25.7629 18.419 25.341 18.841C24.919 19.2629 24.3467 19.5 23.75 19.5C23.1533 19.5 22.581 19.2629 22.159 18.841C21.7371 18.419 21.5 17.8467 21.5 17.25ZM14.75 17.25C14.75 16.6533 14.9871 16.081 15.409 15.659C15.831 15.2371 16.4033 15 17 15C17.5967 15 18.169 15.2371 18.591 15.659C19.0129 16.081 19.25 16.6533 19.25 17.25C19.25 17.8467 19.0129 18.419 18.591 18.841C18.169 19.2629 17.5967 19.5 17 19.5C16.4033 19.5 15.831 19.2629 15.409 18.841C14.9871 18.419 14.75 17.8467 14.75 17.25ZM8 17.25C8 16.6533 8.23705 16.081 8.65901 15.659C9.08097 15.2371 9.65326 15 10.25 15C10.8467 15 11.419 15.2371 11.841 15.659C12.2629 16.081 12.5 16.6533 12.5 17.25C12.5 17.8467 12.2629 18.419 11.841 18.841C11.419 19.2629 10.8467 19.5 10.25 19.5C9.65326 19.5 9.08097 19.2629 8.65901 18.841C8.23705 18.419 8 17.8467 8 17.25Z"
        fill={props.color || '#434343'}
      />
    </svg>
  )
}

export const MoreIcon = React.memo(SVGComponent)
