import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'

import { COLORS } from '../../config/constants'
import { Icon } from '../Icon/Icon'

import {
  FooterWrapper,
  FooterPlug,
  FooterCTAWrapper,
  FooterCTABackground,
  CreateButton,
  LoginButton,
  FooterPreviewWrapper,
  FooterPreviewLinksWrapper,
  FooterPreviewLink,
} from './Footer.styles'
import { LocalStorageService } from '../../services/core/localStorage.service'
import { CompanyStore } from '../../store/company.store'

export const FOOTER_HEIGHT = 62
const ICONS_COLOR = COLORS.COLOR_MIDDLE_GRAY

const UserFooter = () => (
  <>
    <FooterWrapper height={FOOTER_HEIGHT}>
      <Link to="/account">
        <Icon name="user-account" width={23} height={23} color={ICONS_COLOR} pointer />
      </Link>
      <Link to="/">
        <Icon name="logo" width={47} height={32} color={ICONS_COLOR} pointer />
      </Link>
      <Link to="/community">
        <Icon name="account-group" width={32} height={18} color={ICONS_COLOR} pointer />
      </Link>
    </FooterWrapper>
    <FooterPlug height={FOOTER_HEIGHT} />
  </>
)

const CompanyFooter = () => (
  <>
    <FooterWrapper height={FOOTER_HEIGHT}>
      <Link to="/account/brand">
        <Icon name="star" width={23} height={23} color={ICONS_COLOR} pointer />
      </Link>
      <Link to="/">
        <Icon name="logo" width={47} height={32} color={ICONS_COLOR} pointer />
      </Link>
      <Link to="/community">
        <Icon name="account-group" width={32} height={18} color={ICONS_COLOR} pointer />
      </Link>
    </FooterWrapper>
    <FooterPlug height={FOOTER_HEIGHT} />
  </>
)

export const Footer = observer(() => {
  const { companyData } = useContext(CompanyStore)

  return companyData ? <CompanyFooter /> : <UserFooter />
})

export const FooterCTA = () => (
  <FooterCTAWrapper>
    <FooterCTABackground />
    <CreateButton to="signup-company">Create a campaign</CreateButton>
    <LoginButton to="login">Login</LoginButton>
  </FooterCTAWrapper>
)

const LINKS = [
  {
    title: 'About wecobi',
    link: '/about',
  },
  {
    title: 'Terms and conditions',
    link: '/terms',
  },
  {
    title: 'Privacy policy',
    link: '/policy',
  },
]

export const FooterPreview = observer(() => {
  const user = LocalStorageService.getItem('user')

  return (
    <FooterPreviewWrapper>
      <Icon name="logo" width={132} height={91} color={COLORS.COLOR_BLACK} />
      <FooterPreviewLinksWrapper>
        {LINKS.map(({ link, title }) => (
          <FooterPreviewLink key={title}>
            <Link to={link}>{title}</Link>
          </FooterPreviewLink>
        ))}
      </FooterPreviewLinksWrapper>
      {user ? <Footer /> : <FooterCTA />}
    </FooterPreviewWrapper>
  )
})
