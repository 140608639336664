import styled, { CSSObject } from 'styled-components'

import { COLORS } from '../../../../config/constants'
import { Text } from '../../../../components/Text'

interface ImageWrapperProps {
  image: any
  styles?: CSSObject
}

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
`

export const ImageWrapper = styled.div`
  width: 100%;
  height: 346px;
  background-image: url(${(p: ImageWrapperProps) => p.image});
  background-size: cover;
  background-position: center;
  padding-left: 19px;
  padding-top: 19px;
  ${(p: ImageWrapperProps) => p.styles}
`

export const Header = styled(Text)`
  width: 268px;
  font-size: 32px;
  line-height: 119.2%;
  color: ${COLORS.COLOR_WHITE};
`

export const Description = styled(Text)`
  width: 268px;
  font-weight: normal;
  font-size: 16px;
  line-height: 157.2%;
  margin-top: 12px;
  color: ${COLORS.COLOR_WHITE};
`

export const SliderWrapper = styled.div`
  position: relative;
  height: 350px;
  background-color: ${COLORS.COLOR_WHITE};
`
