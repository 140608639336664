import React from 'react'

import { IconNames } from '../../Icon/Icon.types'
import { IconWrapper, ItemWrapper } from './index.style'
import { Icon } from '../../Icon/Icon'
import { Text } from '../../Text'
import { COLORS } from '../../../config/constants'
import { ICategoryNames } from '../CategoryItem/CategoryItem.types'

interface Props {
  iconName: IconNames
  title: string
  categoryName: ICategoryNames
  size: 'small' | 'large'
  active: boolean
  toggleCategories: (category: ICategoryNames) => void
}

export const CategoryDropdownItem = ({ iconName, categoryName, title, size, ...props }: Props) => {
  const { active, toggleCategories } = props

  return (
    <ItemWrapper
      bgColor={active ? COLORS.COLOR_ORANGE_GRADIENT_1 : COLORS.COLOR_WHITE}
      size={size}
      onClick={() => toggleCategories(categoryName)}>
      <IconWrapper>
        <Icon name={iconName} color={active ? COLORS.COLOR_WHITE : COLORS.COLOR_ORANGE_1} />
      </IconWrapper>
      <Text color={COLORS.COLOR_BLACK}>{title}</Text>
    </ItemWrapper>
  )
}
