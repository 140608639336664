import React from 'react'

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '18'}
      height={props.height || '18'}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M0 7C0 7.55228 0.447715 8 1 8H7C7.55228 8 8 7.55228 8 7V1C8 0.447715 7.55228 0 7 0H1C0.447715 0 0 0.447715 0 1V7ZM0 17C0 17.5523 0.447715 18 1 18H7C7.55228 18 8 17.5523 8 17V11C8 10.4477 7.55228 10 7 10H1C0.447715 10 0 10.4477 0 11V17ZM10 17C10 17.5523 10.4477 18 11 18H17C17.5523 18 18 17.5523 18 17V11C18 10.4477 17.5523 10 17 10H11C10.4477 10 10 10.4477 10 11V17ZM18 1C18 0.447715 17.5523 0 17 0H11C10.4477 0 10 0.447715 10 1V7C10 7.55228 10.4477 8 11 8H17C17.5523 8 18 7.55228 18 7V1Z"
        fill={props.color || '#434343'}
      />
    </svg>
  )
}

export const DisplayGridFilterIcon = React.memo(SVGComponent)
