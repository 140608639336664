import React from 'react'
import { PageWrapper } from '../../containers/PageWrapper/PageWrapper'
import { GuiderHeader } from './components/GuideHeader'
import { GuideRow } from './components/GuideRow'

const TERMS_DATA = [
  {
    title: 'Welcome to Wecobi',
    text: `Welcome to Wecobi. By using this website (the “Site”) and services (together with the Site, the
      “Services”) offered by Wecobi, (together with its parents, subsidiaries, affiliates, agents,
      representatives, consultants, employees, officers, and directors — collectively,“Wecobi,” “we,”
      “our” or “us”), you’re agreeing to these legally binding rules (the “Terms”). You’re also agreeing to
      our Privacy Policy and agreeing to follow any other rules on the Site, like our Community
      Guidelines and rules for starting a Campaign.
      Wecobi acts as a marketplace that allows merchants (“Seller”) to offer and sell certain goods at
      an aggregate to individuals at an optimized or wholesale price.
      Wecobi is not directly involved in the transaction of goods between buyers and sellers. As a
      result, Wecobi has no control over the quality, safety, morality or legality of any aspect of the
      items listed, the truth or accuracy of the listings, the ability of sellers to sell items or the ability of
      buyers to pay for items. Wecobi cannot ensure that a buyer or seller will actually complete a
      transaction. Wecobi does not transfer legal ownership of items from the seller to the buyer.
      Wecobi cannot guarantee the true identity, age, and nationality of a merchant or a buyer. Wecobi
      encourages you to communicate directly with potential transaction partners through the tools
      available on the Site.
      We may change these terms from time to time. If we do, we’ll let you know about any material
      changes, either by notifying you on the Site or by sending you an email. New versions of the
      terms will never apply retroactively — we’ll tell you the exact date they go into effect. If you keep
      using Wecobi after a change, that means you accept the new terms.`,
  },
  {
    title: 'Creating an Account',
    text: `Creating a Wecobi account is free. In order to create an account on wecobi, you need to be 18 or
    over. The account is you responsibility and you are responsible for all of the activity on the
    account.
    You are able to browse Wecobi without needing to sign up for a registered account. But in order
    to access all of the functions on Wecobi, you have to register, sign up with an account name, and
    
    create a password. Keep your password secure. You are fully responsible for all activities,
    liabilities and damages resulting from your failure to maintain password confidentiality. You agree
    to immediately notify Wecobi of any unauthorized use of your password or any breach of security.
    You also agree that Wecobi cannot and will not be liable for any loss or damage arising from your
    failure to keep your password secure. You agree not to provide your username and password
    information in combination to any other party other than Wecobi without Wecobi’s express written
    permission.
    All the information you give us, when you create an account at wecobi has to be complete and
    accurate. You must keep your account information up-to-date and accurate at all times, including
    a valid email address. To sell items on Wecobi you must provide and maintain valid payment
    information. If you do not comply with the aforementioned provisions, you assume all
    responsibility for losses.
    You may not transfer or sell your Wecobi merchant account and username to another party. If you
    are registering as a business entity, you should prove that you have the right to assign the
    agreement, and the validity should be vested in the entity.
    You agree to comply with all local laws regarding online conduct and acceptable content. You are
    responsible for all applicable taxes, including but not limited to VAT and commissions earned. In
    addition, you must abide by Wecobi's policies as stated in the Agreement, as well as all other
    operating rules, policies and procedures that may be published from time to time on the Site by
    Wecobi.
    Do not impersonate or pretend to be anyone else or choose names that violate anyone’s rights or
    are offensive. We may cancel your account if you don’t follow these rules. Wecobi reserves the
    right to refuse service to anyone, for any reason, at any time.
    Keeping your password confidential is you responsibility along with all the activity on your
    account. You should report to support@wecobi.com, if you find out that someone is or has used
    your account without your knowledge or permission.
    In order to sign up for an account, you need to be at least 16 years old, or old enough to form a
    binding contract where you live. We will If necessary, ask you to provide proof of age. Individuals
    under the age of 18 must at all times use Wecobi’s services only in conjunction with and under
    the supervision of a parent or legal guardian who is at least 18 years of age. In all cases, the
    adult is the merchant and is responsible for any and all activities.`,
  },
  {
    title: 'Prohibited',
    text: `In this section we list out the things that you should not do —, hack other people’s computers,
    abuse people, steal data, break laws, lie etc. We ask you to respect our rules and conduct
    yourself accordingly and do not do these things.
    You are solely responsible for your conduct and activities on and relating to Wecobi, and any and
    all data, text, information, usernames, graphics, images, photographs, profiles, audio, video,
    items, and links (together, "Content") that you submit, post, and display on Wecobi. If Wecobi
    makes any requirements at any time, seller shall provide the written proof of having obeyed the
    product requirement of Wecobi and related law as soon as possible. The seller assumes all
    responsibility for losses arising from delay.
    There are allot of people on Wecobi and who use our services. Our expectation from every single
    one of them is to act correctly and aid in maintaining Wecobi a pleasant and respectful space. Do
    not do any of the following on the Site:
    
    I. Do not break the law. Do not take any action that breaches any contract or legal duty you
    have toward anyone, violates the law or violates or infringes on the rights of other people.
    a. Do not offer any orders that violate any of Wecobi’s policies, guidelines, rules, or
    are illegal or violate any applicable regulation, law, ordinance or statute. Do not
    offer prohibited items.
    b. Do not post information that you know is inaccurate, false or misleading. Do not
    do anything deceptive or fraudulent. Do not lie to people.
    c. Do not abuse other users’ personal information. When you use Wecobi, you may
    receive information about other users, including things like their postal
    addresses, email addresses, and names. Do not abuse it. This information is
    provided for the purpose of participating in a Wecobi campaign: do not use it for
    other purposes.
    d. Do not do anything threatening, abusive, and harassing, victimize anyone, do not
    do anything libelous, obscene, defamatory, profane, tortious, or invasive of
    another person’s privacy. . Do not distribute unsolicited or unauthorized
    promotional or advertising material, any chain letters or junk mail, do not spam.
    Do not any run kind of auto-responder, mail lists or spam on or through the Site.
    e. Do not distribute anything (programs, code, films,) or software viruses designed
    to interfere with the proper function of any software, equipment or hardware, on
    the Site (whether it belongs to another party or Wecobi) . Do not harm anyone’s
    computer.
    
    II. We also need to make sure that the site is secure and our systems function properly. So
    don’t do any of these things — most of which comes down to “don’t mess with our
    system.”
    a. Do not deconstruct, reverse engineer or take apart any aspect or feature of
    Wecobi in an effort to get control or access to things like algorithms, source code
    or underlying ideas.
    b. Do not attempt to avoid, circumvent or bypass any measures we have put in
    place or implemented to secure the Services.
    c. Do not attempt to get unauthorized access or damage to any information,
    password, system, or other data, whether it belongs to another party or Wecobi.
    d. Do not use any kind of software or device (whether it’s automated or manual) to
    “spider” or “crawl” any part of the Site.
    e. Do not attempt to interfere with the proper workings of wecobi or the Services.
    f. Do not take any action that inflicts an unreasonable load on our infrastructure, or
    on our third-party providers. (We reserve the right to determine what’s
    reasonable.)`,
  },
  {
    title: 'Campaigns',
    text: `This section goes over the details of participating in and creating campaigns —How money gets
    collected, when the orders can be changed or canceled, and how sellers can contact buyers to
    deliver on orders.
    These are the terms that apply when you’re creating a campaign:
    a) You can refund individual orders if you want. After your campaign has been funded, you
    can cancel and refund a buyer’s order at any time. If you do, you have no further
    obligation to that specific buyer, and no agreement exists between you.
    b) By listing an item on Wecobi, you warrant that you and all aspects of the item comply with
    Wecobi 's published policies. You also warrant that you have the right to legally sell the
    item. You must accurately describe your item and all terms of sale in your campaign.
    Your listings may only include text descriptions, graphics, pictures and other contents
    relevant to the sale of that item. All items must be listed in an appropriate category with
    
    appropriate tags. Each listing must accurately and completely describe the item/items for
    sale in that campaign.
    c) All sales are binding once the campaign has reached its minimum goal. The seller is
    obligated to ship the order or otherwise complete the transaction with the buyer in a
    prompt manner, unless there is an exceptional circumstance, such as: the transaction
    cannot be completed, due to laws, regulations, policies, natural disasters, wars, strikes
    and other unforeseen factors. The costs arising from not completing orders in time, shall
    be undertaken by seller.
    d) All sellers are urged to outline their policies for their Wecobi campaign. These policies
    may include, for example, shipping, returns, payment and selling policies. Sellers must
    create reasonable policies in good faith and must abide by such policies. All seller
    policies must comply with Wecobi's site-wide policies. If there are any differences in
    campaign policies and Wecobi policies, Wecobi policies prevail. Sellers are responsible
    for enforcing their own reasonable campaign policies. Wecobi reserves the right to
    request that a seller modifies a campaign policy.
    e) We’ll charge our fees and any commissions earned by buyers, before putting funds in
    your account. Wecobi and its payment partners will subtract fees and commissions
    earned by buyers before transmitting the proceeds of a campaign.
    f) The price stated in each item listing description must be an accurate representation of the
    sale. Sellers may charge reasonable shipping and handling fees to cover the costs for
    packaging and mailing the items. Sellers may not charge excessive shipping fees or
    otherwise avoid fees. You may not alter the item's price after a sale for the purpose of
    avoiding transaction fees, misrepresent the item's location, or use another sellers's
    account without permission
    g) We'll help resolve payment-card disputes. If a buyer of your campaign disputes the
    charge with their card issuer, we'll handle re-presenting the charge to settle the dispute
    with the card issuer. You’ll be notified that a dispute has been filed, and you’ll be able to
    provide evidence to help us resolve it in your favor. If the cardholder’s dispute is found
    valid, you authorize us to charge the credit card number you provided when you started
    your campaign for the amount of the chargeback.
    
    These are the terms that apply when you’re participating in a campaign:
    
    a) You can increase, decrease, or cancel your order at any time during the campaign, with
    one exception. During the last 48 hours of the campaign, you can’t decrease or cancel
    your order without contacting customer support first — if that action would drop the
    campaign below its funding goal. Once the campaign has been successful, you can only
    cancel or change your order by making special arrangements directly with the seller.
    b) You’ll provide your payment information when you place an order, and your card will be
    charged. Your payment will only be collected by the seller if, at the time of the campaign’s
    funding deadline, the campaign has reached its minimum goal. The exact amount you
    pay is the amount Wecobi will collect. If the campaign hasn’t reached its minimum order
    quantity goal, funds will be refunded to buyer and the order is canceled.
    c) In some cases we’ll reserve the charge on your card. Wecobi and its payment partners
    may authorize or reserve a charge on your credit card (or whatever payment method you
    use) for any amount up to the full amount, at any time between the order and the
    collection of funds.
    d) The Estimated Delivery Date is the seller’s estimate. The date listed on each order is the
    seller’s estimate of when they will deliver the order — not a guarantee to fulfill by that
    date. The schedule may change.
    We ask sellers to think carefully, set a date they feel confident they can work toward, and
    communicate with buyers about any changes.
    e) The seller may need to send you questions about your order. To deliver orders, the seller
    might need information from you, like your mailing address or t-shirt size. They’ll request
    that information after the campaign has succeeded. To receive the order, you’ll need to
    provide the information in a reasonable amount of time.
    f) Wecobi doesn’t offer refunds. Responsibility for delivering on a order lies entirely with the
    seller. Wecobi cannot guarantee sellers’ work, and does not offer refunds.`,
  },
  {
    title: 'Responsibility',
    text: `We don’t oversee campaigns’ performance, and we don’t mediate disputes between users.
    Wecobi isn’t liable for any damages or losses related to your use of the Services. We don’t
    become involved in disputes between users, or between users and any third party relating to the
    use of the Services. We don’t oversee the performance or punctuality of campaigns, and we don’t
    endorse any content users submit to the Site. When you use the Services, you release Wecobi
    
    from claims, damages, and demands of every kind — known or unknown, suspected or
    unsuspected, disclosed or undisclosed — arising out of or in any way related to such disputes
    and the Services. All content you access through the Services is at your own risk. You’re solely
    responsible for any resulting damage or loss to any party.`,
  },
  {
    title: 'Fees',
    text: `Fees are only charged on successfully funded campaigns. We charge 10%, in addition to any
    fees from our payments partners.
    Creating an account on Wecobi is free. If you create a campaign that is successfully funded, we
    (and our payment partners) collect fees. Our partners’ fees may vary slightly based on your
    location.
    If our fees ever change, we’ll announce that on our Site. Payment providers collect some funds
    pledged by buyers. Each payment provider is its own company, and Wecobi isn’t responsible for
    its performance.
    You’re responsible for paying any additional fees or taxes associated with your use of Wecobi.`,
  },
  {
    title: 'Payments',
    text: `Payments account
    Upon completion of sign up for the service, Wecobi will automatically create a Payments Account
    on your behalf, using your email address and the URL(s) and business name associated with
    your Account.
    You acknowledge that Wecobi’s integrated payments service (“Payments Service”) will be your
    default payments gateway, and that it is your sole responsibility as the Account Owner to activate
    and maintain this account during the term of this Agreement. If you do not wish to keep the
    payment account active, it is your responsibility to deactivate it. For the avoidance of doubt, the
    Payments Service is a Third Party Service operated by Stripe Payments Europe, Ltd. (“Stripe”), a
    limited company incorporated under the laws of Ireland, which is a technical service provider.
    Stripe’s role is to accept and process payments with respect to sales of your products and
    services through Internet-based transactions. When creating an account on Wecobi.com, you
    automatically sign up for a Stripe Account (“Payments Account”), to enable payment transactions
    on your Campaign .
    
    You may only use the Payments Service for the sale of items listed in your Campaign , and agree
    to ship products once the payment transaction is complete and funds are available in the
    Payments Account.
    By using the Payments Service in your Campaign , you are agreeing to be bound by Stripe’s
    Connected Account Agreement, as they may be amended by Stripe from time to time. Wecobi
    is not a party to any of Stripe’s terms or conditions and is not liable to you in respect thereof. If
    you do not agree to be bound by Stripe’s terms and conditions, or any changes thereto, you must
    deactivate your Stripe Account and your Wecobi Account.
    The Payments Service allows you to accept payment by credit and debit cards, and additional
    alternative payment methods. Wecobi may add or remove one or more supported payment
    methods at any time without prior notice to you.
    The termination of this Agreement does not relieve you of your financial obligations as set out in
    this Agreement and Stripe, the Payment Networks, or our payment processor(s) may elect to
    continue to hold any funds deemed necessary pending resolution of any other terms or
    obligations outlined in this Agreement, including but not limited to Chargebacks, Fees, Refunds,
    or other investigations or proceedings.
    Transaction fees
    In connection with each transfer, Wecobi will charge a transaction fee depending on the
    transaction value. The transaction fee may be amended from time to time.
    These transaction fees are applicable to all transactions that occur through the Payment Service.
    When a customer purchases a Product from your successfully funded Campaign, the funds are
    transferred to your Payments Account, minus the commission and fees described above, which
    are transferred directly to the buyer and Wecobi.
    The Seller agrees to and allow that Wecobi may charge additional applicable Fees to the
    Payments Account instead of the payment method on file for services like Apps and commission
    (“Internal Billing”).
    Currency conversion
    
    If the Seller lists and subsequently sells a Product in a currency other than that of the Seller’s
    connected bank account, Wecobi will settle the associated transaction proceeds in the currency
    of the Seller’s bank account. When such a service is required, Wecobi or the receiving bank may
    apply an additional transaction charge in accordance with the price list.
    If a Buyer purchases from a Seller who lists Products in a currency other than the buyer’s
    browsing currency, Wecobi may automatically calculate the listing price in the buyer’s browsing
    currency. If such calculation is required, Wecobi will apply an additional transaction charge of
    2.5%.
    Holds, delays, and charges
    All funds resulting from a completed transaction will be reflected as a balance in a seller’s
    Payment Account. Transfer of payouts to your payout account will be made in accordance with
    Stripe’s terms and conditions. If Wecobi reasonably determines that Wecobi may incur losses
    resulting from credit, fraud, criminal behavior, or other legal risks associated with your use of the
    Service, Wecobi may communicate this to Stripe. You hereby agree that such communication
    from Wecobi may lead to your funds being withheld by Stripe for up to 90 days. The same shall
    apply in cases of chargebacks, disputes, reports from buyers to Wecobi, or when a Seller’s
    Account is terminated.
    Wecobi may impose transaction limits or refuse service to a Seller or Buyer at any time. Wecobi
    may cancel a transaction if Wecobi reasonably determines that the Seller is involved in criminal or
    fraudulent behavior; or that the quality, authenticity, or other features of the Products do not
    conform to the description thereof; or if there are any other legal risks associated with the
    transaction. In such cases, Wecobi will communicate this to Stripe, who may hold or repay the
    funds to the Buyer. Wecobi will communicate its decision to cancel the transaction to the Seller,
    who can choose not to deliver the Products until the situation has been resolved.
    If there are insufficient funds in the Payments Account to cover the amount of a refund, an
    Internal Bill, or a chargeback, the Seller agrees to and allows that Wecobi may charge the
    connected payment method or the connected bank account in order to adjust the balance on the
    Payments Account.
    Processing card transactions
    
    You will not impose any fee or surcharge on a Buyer that seeks to use an eligible payment card.
    You will provide a receipt to the Buyer at the conclusion of the purchase transaction that includes
    all information required under applicable law.
    Payouts and transaction history
    Funds available for payout will be deposited to the connected bank account on a weekly basis,
    unless otherwise agreed upon. There will be a delay on each transaction before funds are
    available for deposit. Deposits to a Seller’s bank account will be credited within 3-5 business
    days, but may in some cases take longer, depending on local bank rules, holidays, etc.
    Notwithstanding the foregoing, you acknowledge that all credits for funds provided to you are
    provisional and subject to reversal, including, without limitation, adjustments for inaccuracies and
    errors (including rejects), and chargebacks, reversals, or claims in accordance with this
    Agreement and the card network payment rules, whether or not a transaction is charged back by
    the issuer of the card or the cardholder.
    Full refunds must be for the exact amount of the original transaction, including tax, handling fees,
    and other charges. The refund amount may not exceed the original sale amount except by an
    amount equal to any reimbursements to the customer for postage costs incurred for product
    returns. Refunds processed though the Service must be submitted within 60 days of the original
    transaction but in all cases, within 3 days of approving the cardholder refund.
    Chargebacks
    When a chargeback is issued, you are immediately liable to Wecobi for the full amount of
    payment of the Chargeback plus any associated Fees, fines, expenses, or penalties (including
    those assessed by the Payment Networks or our payment processors). You agree that we may
    recover these amounts from the bank account associated with your Service Account, or setting off
    any amounts owed to you by us. If we are unable to recover funds related to a chargeback for
    which you are liable, you will pay us the full amount of the chargeback immediately upon demand.
    You agree to pay all costs and expenses, including, without limitation, attorneys' fees and other
    legal expenses incurred by or on behalf of us in connection with the collection of any chargebacks
    unpaid by you.
    
    You or Wecobi may elect to contest chargebacks connected to your account. Wecobi may
    provide you with assistance, including notifications and software, to help contest your
    chargebacks. We do not assume any liability for our role or assistance in contesting chargebacks.
    You agree to provide us with the necessary information, in a timely manner and at your expense,
    to investigate or help resolve any chargeback. You also grant us permission to share records or
    other information required with the cardholder, the cardholder's financial institution, and your
    financial institution to help resolve any disputes. You acknowledge that your failure to provide us
    with complete and accurate information in a timely manner may result in an irreversible
    chargeback.
    If the cardholder's issuing bank or the Payment Network do not resolve a dispute in your favor,
    we may recover the chargeback amount and any associated fees from you as described in this
    Agreement.
    We reserve the right, upon notice to you, to charge a fee for mediating or investigating
    chargeback disputes.
    Excessive chargebacks
    At any point, Wecobi, Stripe, , the Payment Networks, or our payment processor(s) may
    determine that you are incurring excessive chargebacks. Excessive chargebacks may result in
    additional fees, penalties, or fines. Excessive chargebacks may also result in additional controls
    and restrictions to your use of the Service, including, without limitation, (i) changes to the terms of
    your Account, (ii) increases to your applicable Fees, (iii) delays in your payout schedule, or (iv)
    possible suspension or termination of your Account and the Service. The Networks may also
    place additional controls or restrictions as part of their own monitoring programs for Sellers with
    excessive chargebacks.`,
  },
  {
    title: 'Other Websites',
    text: `If you follow a link to another website, what happens there is between you and them not us.
    Wecobi may contain links to other websites. (For instance, campaign pages, user profiles, and
    comments may link to other sites.) When you access third-party websites, you do so at your own
    risk. We don’t control or endorse those sites.
    
    Wecobi partners with other companies (such as Amazon Payments and Stripe) for payment
    processing. When you back or create a campaign, you’re also agreeing to the payment
    processor’s terms of service.`,
  },
  {
    title: 'Your Intellectual Property',
    text: `Wecobi does not claim ownership rights in your Content. You grant Wecobi a license solely to
    enable Wecobi to use any information or Content you supply Wecobi with, so that Wecobi is not
    violating any rights you might have in that Content. When you submit a campaign for review, or
    launch a campaign, you agree to these terms.
    You grant Wecobi a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sublicensing
    (through multiple tiers) right to exercise the copyright, publicity, and database rights (but no other
    rights) you have in the Content, in any media now known or not currently known, with respect to
    your Content. You agree to allow Wecobi to store or re-format your Content on Wecobi and
    display your content on Wecobi in any way as Wecobi chooses. Wecobi will not alter the form of
    your trademark (related ratios remain the same). Wecobi will only use personal information in
    accordance with Wecobi 's Privacy Policy.
    As part of a transaction, you may obtain personal information, including shipping information, from
    a Wecobi user. Without obtaining prior permission from the user, this personal information shall
    only be used for that transaction or for Wecobi -related communications. Wecobi has not granted
    you a license to use the information for unsolicited commercial messages. Without limiting the
    foregoing, expressing consent from the user, you are not licensed to add any Wecobi user to your
    email or physical mail list. For more information, see Wecobi 's Privacy Policy. Wecobi does not
    assume any responsibilities for disputes between you and your customers for using customer
    information without authorization.
    You grant us the right to edit, modify, reformat, excerpt, delete, or translate any of your Content.
    Your Content will not contain third-party copyrighted material, or material that is subject to other
    third-party proprietary rights, unless you have permission from the rightful owner of the material, or
    you are otherwise legally entitled to post the material (and to grant Wecobi all the license rights
    outlined here). You will pay all royalties and other amounts owed to any person or entity based on
    your Content, or on Wecobi’s hosting of that Content.
    If Wecobi or its users exploit or make use of your submission in the ways contemplated in this
    agreement, you promise that this will not infringe or violate the rights of any third party, including
    (without limitation) any privacy rights, publicity rights, copyrights, contract rights, or any other
    intellectual property or proprietary rights. All information submitted to the Site, whether publicly
    
    posted or privately transmitted, is the sole responsibility of the person from whom that content
    originated. Wecobi will not be liable for any errors or omissions in any content.
    
    Re-Posting Content: By posting Content on Wecobi, it is possible for an outside website or a third
    party to re-post that Content. You agree to hold Wecobi harmless from any dispute concerning
    this use.`,
  },
  {
    title: 'Wecobi’s Intellectual Property',
    text: `Wecobi, and other Wecobi graphics, logos, designs, page headers, button icons, scripts, and
    service names are registered trademarks, trademarks or trade dress of Wecobi AB. in Sweden
    and/or other countries. Wecobi 's trademarks and trade dress may not be used, including as part
    of trademarks and/or as part of domain names or email addresses, in connection with any
    product or service in any manner that is likely to cause confusion
    Wecobi’s Services are legally protected in various ways, including copyrights, trademarks, service
    marks, patents, trade secrets, and other rights and laws. You agree to respect all copyright and
    other legal notices, information, and restrictions contained in any content accessed through the
    Site. You also agree not to change, translate, or otherwise create derivative works of the Service.
    If you want to use, reproduce, modify, distribute, or store any of this content for a commercial
    purpose, you need prior written permission from Wecobi or the relevant copyright holder. A
    “commercial purpose” means you intend to use, sell, license, rent, or otherwise exploit content for
    commercial use, in any way.`,
  },
  {
    title: 'Deleting Your Account',
    text: `You can terminate your account at any time. All provisions of this agreement survive termination
    of an account, including our rights regarding any content you’ve already submitted to the Site. If
    you’ve launched a campaign, deleting your account will not remove the campaign from the Site.`,
  },
  {
    title: 'Rights',
    text: `Wecobi reserves these rights:
    We have the right to reject, cancel, interrupt, remove, or suspend any campaign at any time and
    for any reason. We can make changes to the Wecobi Site and Services without notice or liability.
    We have the right to decide who’s eligible to use Wecobi. We can cancel accounts or decline to
    offer our Services. (Especially if you’re abusing them.) We can change our eligibility criteria at any
    time. If these things are prohibited by law where you live, then we revoke your right to use Wecobi
    in that jurisdiction. We have the right to cancel any payment to any campaign, at any time and for
    any reason.
    Wecobi is not liable for any damages as a result of any of these actions, and it is our policy not to
    comment on the reasons for any such action.`,
  },
  {
    title: 'Warranty Disclaimer',
    text: `You use our services solely at your own risk. They are provided to you “as is” and “as
    available” and without warranty of any kind, express or implied.
    
    Wecobi specifically disclaims any and all warranties and conditions of merchantability, non-
    infringement, and fitness for a particular purpose, and any warranties implied by any course
    
    of dealing, course of performance, or usage of trade. no advice or information (oral or
    written) obtained by you from wecobi shall create any warranty.`,
  },
  {
    title: 'Indemnification',
    text: `You agree to indemnify and hold Wecobi and (as applicable) Wecobi's parent, subsidiaries,
    affiliates, officers, directors, agents, and employees, harmless from any claim or demand,
    including reasonable attorneys' fees, made by any third party due to or arising out of your breach
    of this agreement or the documents it incorporates by reference, or your violation of any law or
    the rights of a third party. If you do something that gets us sued, or break any of the promises you
    make in this agreement, you agree to defend, indemnify, and hold us harmless from all liabilities,
    claims, and expenses.`,
  },
  {
    title: 'Limitation of Liability',
    text: `To the fullest extent permitted by law, in no event will Wecobi, its directors, employees, partners,
    suppliers, or content providers be liable for any indirect, incidental, punitive, consequential,
    special, or exemplary damages of any kind, including but not limited to damages (i) resulting from
    your access to, use of, or inability to access or use the Services; (ii) for any lost profits, data loss,
    or cost of procurement or substitute goods or services; or (iii) for any conduct of content of any
    third party on the Site. In no event shall Wecobi’s liability for direct damages be in excess of (in
    the aggregate) (a) the amount of fees you pay to Wecobi in the 12 months prior to the action
    giving rise to liability, and (b) $100.`,
  },
  {
    title: 'Dispute Resolution and Governing Law',
    text: `You agree that any disputes arising out of or relating to this Agreement or the use of the Service
    shall be resolved in accordance with this section.
    This Agreement is governed by the laws of Sweden (without regard to its choice of law
    provisions). The exclusive venue for any actions or claims arising under or related to this
    Agreement shall, to the extent possible by applicable law, be the District Court of Stockholm.
    BINDING ARBITRATION AND CLASS ACTION WAIVER (FOR USERS BASED IN THE
    UNITED STATES)
    In the event of a Dispute, either party may elect to finally and exclusively resolve the dispute by
    binding arbitration. Any election to arbitrate, at any time, will be final and binding on the other
    party. IF EITHER PARTY CHOOSES ARBITRATION, NEITHER PARTY WILL HAVE THE RIGHT
    TO LITIGATE THE DISPUTE IN COURT OR TO HAVE A JURY TRIAL. DISCOVERY AND
    APPEAL RIGHTS MAY ALSO BE LIMITED IN ARBITRATION. All Disputes will be resolved
    before a neutral arbitrator, whose decision will be final except for a limited right of appeal under
    the FAA. Any court in Stockholm, Sweden may enforce the arbitrator’s award. The arbitration
    shall be administered by the Judicial Arbitration and Mediation Services (“JAMS”) pursuant to
    JAMS’ Streamlined Arbitration Rules and Procedures, if applicable, or otherwise pursuant to its
    Comprehensive Arbitration Rules and Procedures and in accordance with the Expedited
    Procedures in those Rules (the “JAMS Rules”). Such disputes will be resolved by the arbitrator as
    
    determined under the JAMS Rules. The arbitration may be conducted in person, through the
    submission of documents, by phone or online. If conducted in person, the arbitration will take
    place in Stockholm, Sweden. The parties may litigate in court to compel arbitration, to stay
    proceeding pending arbitration, or to confirm, modify, vacate or enter judgment on the award
    entered by the arbitrator. In addition, we may litigate in court to seek injunctive relief.
    You agree that any arbitration or proceeding will be limited to the Dispute between us and you
    individually. To the full extent permitted by law, (i) no arbitration or proceeding shall be joined with
    any other; (ii) there is no right or authority for any Dispute to be arbitrated or resolved on a class
    action-basis or to utilize class action procedures; and (iii) there is no right or authority for any
    Dispute to be brought in a purported representative capacity on behalf of the general public or
    any other persons. YOU AGREE THAT YOU MAY BRING CLAIMS AGAINST US ONLY IN
    YOUR INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
    PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.`,
  },
  {
    title: 'Taxes, Sales, Custom and Value-added taxes',
    text: `You are solely and exclusively responsible for determining what, if any, Taxes apply to the sale of
    your Products and services, and/or the payments you receive in connection to your use of the
    Service. It is solely your responsibility to assess, collect, report, or remit the correct tax to the
    proper tax authority. Wecobi is not obligated to determine whether Taxes apply, or calculate,
    collect, report, or remit any Taxes to any tax authority arising from any transaction. You
    acknowledge that we may make certain reports to tax authorities.
    In an effort to remain compliant with respective consumer legislations, we strongly encourage our
    merchants to maintain good standing with respect to custom and value-added taxes or VAT,
    where applicable.
    Due to separate and applicable tax jurisdictions, purchases may be subject to specific sales,
    custom or value-added taxes, and the shipping time and associated cost may increase.
    Merchants that sell regularly on the Wecobi platform may be required to reserve for VAT.
    Although Wecobi does not require sellers to have a VAT number to sell in our site, merchants
    may be required to pay for VAT for conducting such business in the applicable tax jurisdictions.
    As a result, we strongly encourage our merchants to consult with their own tax experts and
    register for a VAT identification number and reserve such VAT dues accordingly.
    
    You agree that you are responsible for all tax collection and payment among all parties of this
    agreement.`,
  },
  {
    title: 'Miscellaneous',
    text: `Force Majeure
    No party will be liable for delays in processing or other nonperformance caused by such events
    as fires, telecommunications failures, utility failures, power failures, equipment failures, labor
    strife, riots, war, terrorist attack, nonperformance of our vendors or suppliers, acts of God, or
    other causes over which the respective party has no reasonable control, except that nothing in
    this section will affect or excuse your liabilities and obligations for, including without limitation,
    reversals, chargebacks, claims, fines, Fees, refunds, or unfulfilled Products and services.
    Right to amend
    We have the right to change or add to the terms and conditions of this Agreement at any time,
    and to change, delete, discontinue, or impose conditions on any feature or aspect of the Service
    or software with notice that we in our sole discretion deem to be reasonable in the
    circumstances—including such notice on our Website or any other website maintained or owned
    by us for the purposes of providing services in terms of this Agreement. Any use of the Service
    after our publication of any such changes shall constitute your acceptance of this Agreement as
    modified.
    Headings
    Headings are included for convenience only, and shall not be considered in interpreting this
    Agreement. The Agreement does not limit any rights that we may have under trade secret,
    copyright, patent, or other laws. Our failure to assert any right or provision under this Agreement
    shall not constitute a waiver of such right or provision. No waiver of any term of this Agreement
    shall be deemed a further or continuing waiver of such term or any other term.
    Severability
    If any provision of this Agreement is held unenforceable, then such provision will be modified to
    reflect the parties' intention. All remaining provisions of this Agreement shall remain in full force
    and effect
    
    No Agency
    You and Wecobi are independent contractors, and no agency, partnership, joint venture,
    employee-employer or franchiser-franchisee relationship is intended or created by this
    Agreement. Merchants have no right to represent Wecobi to make or accept any offers or
    acceptance. Merchants cannot make any statements at any circumstance that may cause conflict
    with this term. This agreement does not establish an exclusive partnership between merchants
    and Wecobi.
    Notices
    You agree that Wecobi can provide notices to you by sending an email to the email address listed
    in the Wecobi Account profile, or by mailing them to the address listed therein. Such notices shall
    be considered to be received by you, if sent by email, at the time of dispatch unless we receive
    notice that the email was not delivered—and if sent by regular mail, on the second business day
    after such notice was posted.
    Services
    These Terms and the other material referenced in them are the entire agreement between you
    and Wecobi with respect to the Services. They supersede all other communications and
    proposals (whether oral, written, or electronic) between you and Wecobi with respect to the
    Services and govern our future relationship. If any provision of these Terms is found to be invalid
    under the law, that provision will be limited or eliminated to the minimum extent necessary so that
    the Terms otherwise will remain in full force and effect and enforceable. The failure of either you
    or Wecobi to exercise any right provided for in these Terms in any way won’t be deemed a waiver
    of any other rights.
    These Terms are personal to you. You can’t assign them, transfer them, or sublicense them
    unless you get Wecobi’s prior written consent. Wecobi has the right to assign, transfer, or
    delegate any of its rights and obligations under these Terms without your consent. Wecobi will
    provide you notice via email, written notice, or by conspicuously posting the notice on our Site.`,
  },
]

export const Terms = () => (
  <PageWrapper>
    <GuiderHeader>Terms of Use</GuiderHeader>
    {TERMS_DATA.map(({ title, text }, index) => (
      <GuideRow key={title} index={index + 1} title={title} data={[{ text }]} />
    ))}
  </PageWrapper>
)
