import React, { SVGProps, memo } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={props.width || 43} height={props.height || 43} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M43 21.5C43 9.625 33.375 0 21.5 0S0 9.625 0 21.5c0 10.733 7.86 19.627 18.14 21.24V27.715h-5.458V21.5h5.459v-4.737c0-5.387 3.208-8.365 8.12-8.365 2.352 0 4.813.42 4.813.42v5.291h-2.713c-2.67 0-3.502 1.66-3.502 3.36V21.5h5.963l-.953 6.215h-5.01V42.74C35.14 41.127 43 32.233 43 21.5Z"
      fill="#000"
    />
  </svg>
)

export const FacebookBlackIcon = memo(SvgComponent)
