import React from 'react'

import { AccountSliderWrapper, AccountSliderInfo, IconWrapper } from './AccountSlider.style'
import { Icon } from '../../components/Icon/Icon'
import { Text } from '../../components/Text'
import { COLORS } from '../../config/constants'
import { AccountSliderProps } from './AccountSlider.types'
import { DEFAULT_SLIDER_HEIGHT } from './AccountSlider.config'

export const AccountSlider = ({ icon, title, onLeftArrow, onRightArrow }: AccountSliderProps) => {
  const { COLOR_LIGHT_GRAY_1, COLOR_BLACK } = COLORS

  return (
    <AccountSliderWrapper height={DEFAULT_SLIDER_HEIGHT}>
      <IconWrapper onClick={onLeftArrow}>
        <Icon color={onLeftArrow ? COLOR_BLACK : COLOR_LIGHT_GRAY_1} name="arrow-left" width={12} height={21} />
      </IconWrapper>
      <AccountSliderInfo>
        <Icon className="icon" name={icon} width={28} height={27} color={COLORS.COLOR_ORANGE_1} />
        <Text color={COLOR_BLACK} className="title" weight={700} size={26} lineHeight="125.7%">
          {title}
        </Text>
      </AccountSliderInfo>
      <IconWrapper onClick={onRightArrow}>
        <Icon color={onRightArrow ? COLOR_BLACK : COLOR_LIGHT_GRAY_1} name="arrow-right" width={12} height={21} />
      </IconWrapper>
    </AccountSliderWrapper>
  )
}
