import { updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore'
import { FirestoreService } from '../core/firestore.service'
import { StorageService } from '../core/storage.service'

class CompanyManager {
  public getCampaigns = async (companyId: string) => {
    return await FirestoreService.getCampaignsByCompanyId(companyId)
  }

  public getFollowings = async (companyId: string) => {
    const { companyData } = await FirestoreService.getCompanyById(companyId)
    return companyData.followed
  }

  public followCompany = async (companyId: string, userId: string) => {
    const { companyRef } = await FirestoreService.getCompanyById(companyId)
    const { userRef } = await FirestoreService.getUserById(userId)
    if (companyRef && userRef) {
      await updateDoc(companyRef, {
        followed: arrayUnion(userId),
      })
      await updateDoc(userRef, {
        followingCompanies: arrayUnion(companyId),
      })
    }
  }

  public unfollowCompany = async (companyId: string, userId: string) => {
    const { companyRef } = await FirestoreService.getCompanyById(companyId)
    const { userRef } = await FirestoreService.getUserById(userId)
    if (companyRef && userRef) {
      await updateDoc(companyRef, {
        followed: arrayRemove(userId),
      })
      await updateDoc(userRef, {
        followingCompanies: arrayRemove(companyId),
      })
    }
  }

  public getLogo = async (uid: string) => StorageService.getData(`avatar/${uid}`) as Promise<string>

  public updateLogo = async (value: File, uid: string) => {
    await StorageService.addAvatar(uid, value)
    const logo = await this.getLogo(uid)
    await FirestoreService.updateCompany(uid, { logo })
    return logo
  }

  public removeLogo = async (uid: string) => {
    await StorageService.removeAvatar(uid)
    await FirestoreService.updateCompany(uid, { logo: '' })
  }
}

export const CompanyService = new CompanyManager()
