import styled from 'styled-components'
import { COLORS } from '../../../../config/constants'
import { Wrapper } from '../../../../components/reusable/Wrapper/Wrapper'

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 46px 0;
  background-color: ${COLORS.BACKGROUND_COLOR};
`

export const SettingsAvatar = styled(Wrapper)`
  height: 76px;

  & div {
    position: relative;
    height: 76px;
    width: 76px;

    & img {
      width: 100%;
      height: 100%;
      border-radius: 99px;
    }

    & input {
      position: absolute;
      width: 76px;
      height: 76px;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
`

export const InputWithAnchor = styled.div`
  display: flex;
  position: relative;

  & p {
    position: absolute;
    top: 15px;
    left: 10px;
  }
`
