import React from 'react'

import { Wrapper, Input, Circle } from './SearchInput.styles'
import { Icon } from '../../../../components/Icon/Icon'

interface SearchInputProps {
  value: string
  onChange: (value: string) => void
}

export const SearchInput = ({ value, onChange }: SearchInputProps) => {
  return (
    <Wrapper>
      <Input
        type="text"
        placeholder="I Search products"
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
      <Circle>
        <Icon width={18} height={17} name="search-input" />
      </Circle>
    </Wrapper>
  )
}
