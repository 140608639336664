import React from 'react'
import './Navigation.scss'
import { NavigationElement } from './NavigationElement'

interface NavigationProps {
  active: string
  onChange: (event: React.MouseEvent<HTMLDivElement>) => void
}

export const Navigation = ({ active, onChange }: NavigationProps) => {
  const navElements = ['basics', 'rewards', 'description', 'account']

  const renderNavLists = navElements.map((text) => (
    <NavigationElement key={text} id={text} active={active} onChange={onChange} text={text} />
  ))

  return (
    <div className="createProduct__navigation">
      <button className="back">Back</button>
      <div className="navigation">{renderNavLists}</div>
      <button className="preview">Preview</button>
    </div>
  )
}
