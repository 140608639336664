import React, { useState } from 'react'

import './Account.scss'
import { Block } from '../../../../containers/Block/Block'
import { AccountStateProps } from './Account.interfaces'
import { accountMaxLength } from './Account.config'

export const Account = () => {
  const [state, setState] = useState<AccountStateProps>({
    email: '',
    fullname: '',
    bankAccount: '',
    paymentSource: '',
  })

  const onChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const onSubmit = (e: any) => {
    e.preventDefault()

    //some logic
  }

  return (
    <div className="account__wrapper">
      <Block label="Contact details">
        <form onSubmit={(e) => onSubmit(e)} className="submit">
          <input name="email" onChange={(e) => onChange(e)} className="input" placeholder="email" type="email" />
          <button className="submit-button" type="submit">
            send verification email
          </button>
        </form>
      </Block>
      <Block label="Funds recipient">
        <input placeholder="first & last name of recipient" className="input" type="text" />
        <div className="input-description">we do not control the field content but it is required</div>
      </Block>
      <Block label="Bank account">
        <input
          name="bankAccount"
          onChange={(e) => onChange(e)}
          className="input"
          type="text"
          maxLength={accountMaxLength.bankAccount}
        />
        <div className="input-description">max length: {accountMaxLength.bankAccount}</div>
      </Block>
      <Block label="Payment source">
        <input
          className="input"
          type="text"
          name="paymentSource"
          onChange={(e) => onChange(e)}
          maxLength={accountMaxLength.paymentSource}
        />
        <div className="input-description">max length: {accountMaxLength.paymentSource}</div>
      </Block>
    </div>
  )
}
