import React from 'react'
import { observer } from 'mobx-react-lite'
import { AccountSlider } from '../components/AccountSlider/AccountSlider'
import { PageWrapper } from '../containers/PageWrapper/PageWrapper'
import { useHistory } from 'react-router-dom'

export const MyMessages = observer(() => {
  const history = useHistory()

  return (
    <PageWrapper>
      <AccountSlider
        icon="chat"
        title="My messages"
        onLeftArrow={() => history.push('/account/commissions')}
        onRightArrow={() => history.push('/account/notifications')}
      />
    </PageWrapper>
  )
})
