import styled from 'styled-components'
import { Text } from '../../components'
import { COLORS } from '../../config/constants'

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 98px;
  margin-top: 14px;
  padding: 0 12px;
`

export const EmptyTitle = styled(Text)`
  font-weight: 700;
  font-size: 19px;
  line-height: 119.2%;
  margin-bottom: 10px;
  color: ${COLORS.COLOR_MIDDLE_GRAY};
`

export const EmptyText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.08px;
  color: ${COLORS.COLOR_MIDDLE_GRAY};
`

export const EmptyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
`
