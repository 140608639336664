import React, { useState } from 'react'
import { MenuItem, Menu } from '@material-ui/core'

import './Location.scss'
import { Button } from '../../../../components/Button/Button'
import { COLORS } from '../../../../../../config/constants'

interface Props {
  country: string
  chooseCountry: (currentCountry: string) => void
  countryList: string[]
  chooseCity: (currentCity: string) => void
  cityList: Record<string, string[]>
  city: string
}

export const Location = ({ country, chooseCountry, countryList, chooseCity, cityList, city }: Props) => {
  const [ddCountry, setDdCountry] = useState<(EventTarget & HTMLButtonElement) | null>(null)
  const onClickCountry = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setDdCountry(event.currentTarget)
  const onCloseCountry = () => setDdCountry(null)

  const [ddCity, setDdCity] = useState<(EventTarget & HTMLButtonElement) | null>(null)
  const onClickCity = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setDdCity(event.currentTarget)
  const onCloseCity = () => setDdCity(null)

  const onChooseCountry = (choosenCountry: string) => {
    chooseCountry(choosenCountry)
    onCloseCountry()
  }
  const onChooseCity = (choosenCity: string) => {
    chooseCity(choosenCity)
    onCloseCity()
  }

  return (
    <div className="productDetails__location">
      <div className="title">Location</div>
      <div className="buttons">
        <Button type="arrowDown" title={country || 'Country'} onClick={onClickCountry} />
        <Menu
          style={{ width: '100%' }}
          anchorEl={ddCountry}
          getContentAnchorEl={null}
          keepMounted
          open={!!ddCountry}
          onClose={onCloseCountry}>
          {countryList.map((currentCountry, index) => (
            <MenuItem
              style={{ backgroundColor: country === currentCountry ? COLORS.COLOR_LIGHT_GRAY_1 : 'white' }}
              key={index}
              value={currentCountry}
              onClick={() => onChooseCountry(currentCountry)}>
              {currentCountry}
            </MenuItem>
          ))}
        </Menu>
        <Button type="arrowDown" title={city || 'City'} onClick={onClickCity} />
        <Menu
          style={{ width: '100%' }}
          anchorEl={ddCity}
          getContentAnchorEl={null}
          keepMounted
          open={!!ddCity}
          onClose={onCloseCity}>
          {country ? (
            cityList[country].map((currentCity, index: number) => (
              <MenuItem
                style={{ backgroundColor: city === currentCity ? COLORS.COLOR_LIGHT_GRAY_1 : 'white' }}
                key={index}
                value={currentCity}
                onClick={() => onChooseCity(currentCity)}>
                {currentCity}
              </MenuItem>
            ))
          ) : (
            <MenuItem>Please choose country first</MenuItem>
          )}
        </Menu>
      </div>
      <div className="description">
        If you focus on achieving more public engagements, we advice to set NO.
        <p>If you focus on sales, we advice to set YES.</p>
      </div>
    </div>
  )
}
