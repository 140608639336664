import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={32} height={28} viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.725 25.823a4.638 4.638 0 110-9.276 4.638 4.638 0 010 9.276zm0-11.263a6.625 6.625 0 100 13.25 6.625 6.625 0 000-13.25zm12.985-2.65h5.565V9.525h-4.24l-2.57-4.333a2.265 2.265 0 00-1.935-1.1 2.21 2.21 0 00-1.59.663l-4.903 4.89c-.41.41-.662.94-.662 1.603 0 .834.437 1.537 1.126 1.947l4.439 2.69v6.625h2.385v-8.613l-2.982-2.186 3.074-3.114 2.293 3.313zm5.565 13.913a4.638 4.638 0 110-9.276 4.638 4.638 0 010 9.276zm0-11.263a6.625 6.625 0 100 13.25 6.625 6.625 0 000-13.25zM21.3 5.02a2.375 2.375 0 002.385-2.385A2.375 2.375 0 0021.3.25a2.375 2.375 0 00-2.385 2.385A2.375 2.375 0 0021.3 5.02z"
        fill={props.color || '#FF6D0A'}
      />
    </svg>
  )
}

export const BikeIcon = React.memo(SvgComponent)
