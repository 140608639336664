import React, { useContext } from 'react'
import { useHistory } from 'react-router'

import { ActivityWrapper } from './Activity.style'
import { HEIGHT } from './Activity.config'
import { ActivityBlock } from './ActivityBlock'
import { ActivityProps } from './Activity.interface'
import { UserStore } from '../../../../../store/user.store'
import { UserService } from '../../../../../services/entities/user.service'

export const Activity = (props: ActivityProps) => {
  const history = useHistory()
  const { userData } = useContext(UserStore)
  const { followers, following, shares, username } = props
  const pureUsername = UserService.getPureUsername(username)
  const getLink = userData?.username === pureUsername ? '/account/' : `/users/${pureUsername}/`

  const data = [
    { title: 'Followers', value: followers, onClick: () => history.push(`${getLink}followers`) },
    { title: 'Following', value: following, onClick: () => history.push(`${getLink}following`) },
    { title: 'Shares', value: shares },
  ]

  return (
    <ActivityWrapper height={HEIGHT}>
      {data.map((item, index) => (
        <ActivityBlock title={item.title} key={index} count={item.value} onClick={item.onClick} />
      ))}
    </ActivityWrapper>
  )
}
