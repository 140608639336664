import React, { useState, useEffect, useCallback } from 'react'
import { Switch, useLocation } from 'react-router-dom'

import './CreateProduct.scss'
import { Navigation } from './components/Navigation/Navigation'
import { Basics } from './components/Basics/Basics'
import { Rewards } from './components/Rewards/Rewards'
import { Description } from './components/Description/Description'
import { Account } from './components/Account/Account'
import { PrivateRoute } from '../../components/Authorization'

export const CreateProduct = () => {
  const [active, setActive] = useState<string>('')
  const location = useLocation()

  useEffect(() => {
    const activeURL = location.pathname.split('/')[location.pathname.split('/').length - 1]
    setActive(activeURL)
  }, [location.pathname])

  const onChangeNavigation = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    const { id } = e.target as HTMLDivElement
    setActive(id)
  }, [])

  // there's no need to place route stack outside of app stack. Need to be refactored!
  return (
    <div className="createProduct__wrapper">
      <Navigation active={active} onChange={(e) => onChangeNavigation(e)} />
      <Switch>
        <PrivateRoute path="/product/basics" component={Basics} />
        <PrivateRoute path="/product/rewards" component={Rewards} />
        <PrivateRoute path="/product/description" component={Description} />
        <PrivateRoute path="/product/account" component={Account} />
      </Switch>
    </div>
  )
}
