import styled from 'styled-components'
import { WINDOW } from '../../config/constants'

interface NavigationProps {
  height: number
}

export const Wrapper = styled.nav`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  width: 100%;
  max-width: ${WINDOW.MAX_WIDTH}px;
  height: ${(p: NavigationProps) => p.height}px;
  background-color: #fff;
  color: #000;
  box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.25);
  z-index: 5;
  margin-bottom: 150px;
`

export const NavigationPlug = styled.div`
  height: ${(p: NavigationProps) => p.height}px;
`
