import React from 'react'

import { ActionsWrapper, BUTTON_GRAY_STYLE, BUTTON_GRADIENT_STYLE } from './Actions.style'
import { Button } from '../../../../../components/Button/Button'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'

const HEIGHT = 43

interface ActionsProps {
  isFollowed: boolean
  onFollow?: () => void
  onUnfollow?: () => void
}

export const Actions = observer(({ isFollowed, onFollow, onUnfollow }: ActionsProps) => {
  const history = useHistory()

  const buttons = [
    { title: 'Message' },
    { title: isFollowed ? 'Unfollow' : 'Follow', onClick: isFollowed ? onUnfollow : onFollow },
    { title: 'Invite to co-by', onClick: () => history.push('/account/orders') },
  ]

  const styles = [BUTTON_GRAY_STYLE, BUTTON_GRAY_STYLE, BUTTON_GRADIENT_STYLE]

  return (
    <ActionsWrapper height={HEIGHT}>
      {buttons.map(({ title, onClick }, index) => (
        <Button style={styles[index]} key={index} onClick={onClick}>
          {title}
        </Button>
      ))}
    </ActionsWrapper>
  )
})
