import styled from 'styled-components'

interface SwitcherWrapperProps {
  isHidden: boolean
}

export const SwitcherWrapper = styled.div`
  position: fixed;
  bottom: 0;
  display: ${(p: SwitcherWrapperProps) => (p.isHidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 152px;
  background-color: #fff;
  z-index: 5;

  box-shadow: -2px 2px 12px rgba(0, 0, 0, 0.13);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
  width: 283px;
`
