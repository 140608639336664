import { action, makeObservable, observable } from 'mobx'
import { FirestoreService } from '../../../../services/core/firestore.service'
import { CampaignService } from '../../../../services/entities/campaign.service'
import { UserStoreVm } from '../../../../store/user.store'
import { Campaign } from '../../../../types/company.types'
import { User } from '../../../../types/user.types'

export class CampaignBuyersVm {
  userStore?: UserStoreVm = undefined
  campaignId?: string = undefined
  campaign?: Campaign = undefined
  buyers?: User[] = []

  constructor(campaignId: string) {
    this.campaignId = campaignId

    makeObservable(this, {
      campaign: observable,
      buyers: observable,
      setProduct: action,
      setBuyers: action,
    })
  }

  public setProduct = (campaign: Campaign) => {
    this.campaign = campaign
  }

  public setBuyers = (buyers: User[]) => {
    this.buyers = buyers
  }

  private fetchProduct = async () => {
    if (this.campaignId) {
      const { campaignData } = await FirestoreService.getCampaignById(this.campaignId)
      this.setProduct(campaignData)
    }
  }

  private fetchBuyers = async () => {
    if (this.campaignId) {
      const buyers = await CampaignService.getBuyers(this.campaignId)
      this.setBuyers(buyers)
    }
  }

  public init = () => {
    this.fetchProduct()
    this.fetchBuyers()
  }
}
