import styled, { CSSObject } from 'styled-components'

export interface IconWrapperProps {
  containerStyles?: CSSObject
}

export const Wrapper = styled.div`
  width: auto;
  height: auto;
  ${(p: IconWrapperProps) => p.containerStyles}
`
